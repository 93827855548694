import React, { useEffect, useState, useRef } from "react";
import img3 from "../../../assets//image/profile.png";
import { MdOutlinePhone } from "react-icons/md";
import { HiMiniChevronDown } from "react-icons/hi2";
import * as yup from "yup";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  getProfile,
  getSpeakLanguages,
  updateProfile,
  uploadProfilePicture,
} from "../../../services/api/user-auth.api";
import Toaster from "../../../common/Toaster";
import moment from "moment/moment";
import Loading from "../../../common/Loading";
import { transformImageUrl } from "../../../util/dataTransform";
import { getCountries } from "../../../services/api/site-info.api";
import "../user.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function UpdateProfile() {
  const [profile, setProfile] = useState(img3);
  const [speakLanguages, setspeakLanguages] = useState([]);
  const [formattedDate, setformattedDate] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const profileUploadRef = useRef(null);
  const [countryList, setCountryList] = useState([]);

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const profileSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    birthDate: yup
      .date()
      .required("Date of Birth is required")
      .max(new Date(), "Date of Birth cannot be in the future"),
    phoneNumber: yup
      .string()
      .matches(phoneRegex, "Phone number is not valid")
      .required("Phone number is required"),
    city: yup.string().required("Phone number is required"),
    country: yup.string().required("Country is required"),
  });

  const aboutSchema = yup.object().shape({
    profession: yup.string().required("Profession is required"),
    speakLanguageId: yup.number().required("Select a Language"),
    aboutMe: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(profileSchema, aboutSchema) });

  const getUser = async () => {
    setIsLoading(true);
    try {
      const user = await getProfile();
      if (user.profileFilepath) {
        const profileUrl = await transformImageUrl(user.profileFilepath);
        setProfile(profileUrl);
      }
      setIsLoading(false);
      if (user.birthDate) {
        const parsedDate = moment(user.birthDate);
        var formattedDateForApi = parsedDate.format("YYYY-MM-DD");
        setformattedDate(formattedDateForApi);
      }
      const fields = {
        firstName: user.firstName || null,
        lastName: user.lastName || null,
        birthDate: formattedDate || null,
        gender: user.gender || 0,
        city: user.city || null,
        currency: user.currency || null,
        country: user.country || null,
        profession: user.profession || null,
        speakLanguageId: user.speakLanguageId || 0,
        aboutMe: user.aboutMe || null,
        guiltypleasures: user.guiltypleasures || null,
        favoriteCuisinesToEnjoyId: user.favoriteCuisinesToEnjoyId || 0,
        favoriteCuisinesToCookId: user.favoriteCuisinesToCookId || 0,
        allergicFoodId: user.allergicFoodId || 0,
        phoneNumber: user.phoneNumber || null,
      };
      Object.entries(fields).forEach(([key, value]) => {
        setValue(key, value);
      });
    } catch (error) {}
  };

  const getSpeakLangs = async () => {
    try {
      const data = await getSpeakLanguages();
      const langList = data.map((option) => ({
        label: option.languageName,
        value: option.languageId,
      }));
      setspeakLanguages(langList);
    } catch (err) {}
  };

  const updateDateHandler = (e) => {
    const dateString = e.target.value;
    const isoString = moment.utc(dateString);
    setUpdateDate(isoString);
  };

  const updateUser = async (formData) => {
    setIsLoading(true);
    const form = { ...formData, birthDate: updateDate };
    await updateProfile(form)
      .then((data) => {
        setIsLoading(false);
        Toaster("Saved successfully", "success");
      })
      .catch((error) => {
        Toaster("Saved failed", "error");
        setIsLoading(false);
      });
  };
  //image upload//
  const handleImageUpload = (event) => {
    event.preventDefault();
    profileUploadRef.current.click();
  };
  const uploadImageDisplay = async () => {
    if (profileUploadRef.current && profileUploadRef.current.files.length > 0) {
      const uploadedFile = profileUploadRef.current.files[0];
      const formData = new FormData();
      formData.append("profileImage", uploadedFile);
      try {
        await uploadProfilePicture(formData);
        const cachedURL = URL.createObjectURL(uploadedFile);
        setProfile(cachedURL);
        getUser();
        Toaster("Profile picture uploaded successfully", "success");
      } catch (error) {
        Toaster("Upload failed", "error");
      }
    }
  };
  // contry //
  const getCountryData = async () => {
    try {
      const res = await getCountries();
      const list = res.map((option) => ({
        label: option.name,
        value: option.name,
      }));
      setCountryList(list);
    } catch (err) {}
  };
  useEffect(() => {
    getUser();
    getSpeakLangs();
    getCountryData();
  }, [formattedDate]);

  return (
    <>
      {/* <!--tab content--> */}
      <div className="tab-content">
        <div id="all" className="tab-pane active">
          <div className="p-tb">
            {/* <!-- profile information --> */}
            <>
              <div className="profile-info">
                <div>
                  <p className="profile-title">Personal Information</p>
                </div>
                <div className="row g-3">
                  <div className="col-lg-3 col-md-4">
                    <div className="bx-shadow profile-sec h-100">
                      <div>
                        <h4>Your Profile Picture</h4>
                      </div>
                      <div className="profile-pic">
                        <img src={profile} alt="image" />
                      </div>
                      <div className="pic-upload-btn">
                        <button
                          className="btn"
                          onClick={handleImageUpload}
                          type="button"
                        >
                          Upload your photo
                        </button>
                        <input
                          type="file"
                          ref={profileUploadRef}
                          onChange={uploadImageDisplay}
                          hidden
                          id="profile-photo-input"
                          name="profile_picture"
                          accept="image/png, image/jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="sign-in-form contact-form becomehost-txt mt-0 h-100 bx-shadow">
                      <div>
                        <form onSubmit={handleSubmit(updateUser)}>
                          <div className="row gy-3">
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label input-label">
                                  First name
                                </label>
                                <sup className="req">*</sup>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First name"
                                  {...register("firstName")}
                                />
                                {errors?.firstName && (
                                  <span className="form-error">
                                    {errors.firstName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label input-label">
                                  Last name
                                </label>
                                <sup className="req">*</sup>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last name"
                                  {...register("lastName")}
                                />
                                {errors?.lastName && (
                                  <span className="form-error">
                                    {errors.lastName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="mb-3">
                                <label className="form-label input-label">
                                  Date of Birth
                                </label>
                                <sup className="req">*</sup>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Birth Date"
                                  {...register("birthDate")}
                                  onChange={updateDateHandler}
                                />
                                {errors?.birthDate && (
                                  <span className="form-error">
                                    {errors.birthDate.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label input-label">
                                  City
                                </label>
                                <sup className="req">*</sup>
                                <input
                                  type="text"
                                  placeholder="City"
                                  className="form-control"
                                  {...register("city")}
                                />
                                {errors?.city && (
                                  <span className="form-error">
                                    {errors.city.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label input-label">
                                  Country<sup className="req">*</sup>
                                </label>
                                <div className="selectbox-block">
                                  {countryList?.length && (
                                    <Controller
                                      className="form-control"
                                      name="country"
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          options={countryList}
                                          value={countryList.find(
                                            (option) =>
                                              option.value === field.value
                                          )}
                                          onChange={(selectedOption) =>
                                            field.onChange(
                                              selectedOption?.value
                                            )
                                          }
                                        />
                                      )}
                                    />
                                  )}
                                </div>
                                {errors?.country && (
                                  <span className="form-error">
                                    {errors.country.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <label className="form-label input-label">
                                Your Phone number
                              </label>
                              <sup className="req">*</sup>
                              {/* <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <MdOutlinePhone color="#bababa" />
                                    <HiMiniChevronDown color="#bababa" />
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    placeholder="+89 561 300 00"
                                    {...register("phoneNumber")}
                                  />
                                </div> */}
                              <InputGroup className="mb-3 phone-block">
                                <InputGroup.Text id="basic-addon1">
                                  <MdOutlinePhone />
                                  <Form.Select aria-label="Default select example">
                                    <option>+91</option>
                                    <option value="1">+93</option>
                                    <option value="2">+92</option>
                                    <option value="3">+95</option>
                                  </Form.Select>
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder=""
                                  aria-label=""
                                  aria-describedby="basic-addon1"
                                  type="number"
                                  {...register("phoneNumber")}
                                />
                              </InputGroup>
                              {errors?.phoneNumber && (
                                <span className="form-error">
                                  {errors.phoneNumber.message}
                                </span>
                              )}
                            </div>
                            <div className="col-12">
                              <div className="ok-btn text-end ">
                                <button className="btn mt-0" type="submit">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- about you --> */}
              <div className="profile-info">
                <div>
                  <p className="profile-title">About you</p>
                  <p>
                    Future guests would love to know more about you! Please
                    write all your information in English, you will have the
                    option to write in an additional language too.
                  </p>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="bx-shadow">
                      <div className="sign-in-form contact-form becomehost-txt">
                        <div>
                          <form onSubmit={handleSubmit(updateUser)}>
                            <div className="row gy-3">
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label input-label">
                                    Profession
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Doctor,Chef,Designer"
                                    {...register("profession")}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label input-label">
                                    languages you speak
                                  </label>
                                  <div className="selectbox-block">
                                    {speakLanguages?.length && (
                                      <Controller
                                        className="form-select form-control"
                                        name="speakLanguageId"
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            {...field}
                                            options={speakLanguages}
                                            value={speakLanguages.find(
                                              (option) =>
                                                option.value === field.value
                                            )}
                                            defaultValue={speakLanguages.find(
                                              (option) => option.value === 1
                                            )}
                                            onChange={(selectedOption) =>
                                              field.onChange(
                                                selectedOption?.value
                                              )
                                            }
                                          />
                                        )}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label input-label">
                                    Tell us about youself
                                  </label>
                                  <textarea
                                    className=" form-control"
                                    {...register("aboutMe")}
                                    rows="1"
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col my-auto">
                                <div className="ok-btn">
                                  <button className="btn" type="submit">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default UpdateProfile;
