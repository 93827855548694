import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function EditMenuModal(props) {
  return (
    <div>
      {/* <WishlistEvents/> */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex justify-content-between view-menu p-0 border-0">
              <h5 className="modal-title border-0">Edit Food Items</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label input-label">Item name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Flame and sea"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label input-label">Item description</label>
                <textarea
                  className="form-control textarea-ch"
                  placeholder="Flambéed lobster tail with rum Sautéed jasmine rice linguine Oyster sauce and grilled truffle slices with semi-salted butter from Bretagne Garlic and lemongrass crumble"
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center submit-btn w-100 d-flex justify-content-center create-event-modal-btn">
            <Button onClick={props.onHide} className="btn">
              Update
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditMenuModal;
