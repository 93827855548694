import {axiosInstance} from '../../axios';
import {AUTH, USER, COMMON, HOST } from '../../const/api-url.const';

export const login = async (data) => {
    return await axiosInstance.post(`${AUTH.LOG_IN}`, data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const registerForm = async (data) => {
    return await axiosInstance.post(`${AUTH.REGISTER}`, data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const validPassword = async(email) =>{
    return await axiosInstance.post(`${AUTH.VALID_PASSWORD}?email=${email}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const resetPassword = async(email) =>{
    return await axiosInstance.post(`${AUTH.RESET_PASSWORD}?email=${email}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const updatePassword = async(data) =>{
    return await axiosInstance.post(`${AUTH.UPDATE_PASSWORD}?newPassword=${data.newPassword}&confirmPassword=${data.confirmPassword}&id=${data.id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const changePassword = async (data) => {
    return await axiosInstance
      .post(
        `${AUTH.CHANGE_PASSWORD}?currentPassword=${data.currentPassword}&newPassword=${data.newPassword}&confirmPassword=${data.confirmPassword}`
      )
      .then(
        ({ data }) => {
          return data;
        },
        (error) => {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
      );
  };

export const getProfile = async() =>{
    return await axiosInstance.get(`${USER.GET_PROFILE}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const updateProfile = async(data) =>{
    return await axiosInstance.put(`${USER.UPDATE_PROFILE}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
 
export const getSpeakLanguages = async() =>{
    return await axiosInstance.get(`${COMMON.GET_SPEACK_LANGUAGE}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const createHost= async(formData,stepId) =>{
    return await axiosInstance.patch(`${HOST.PATCH}?stepId=${stepId}`, formData).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const patchHost= async(formData,stepId,hostId) =>{
    return await axiosInstance.patch(`${HOST.PATCH}?id=${hostId}&stepId=${stepId}`, formData).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const becomeHost= async(formData,stepId) =>{
    return await axiosInstance.post(`${HOST.BECOME_HOST}?stepId=${stepId}`,formData).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getHostDetails = async() =>{
    return await axiosInstance.get(`${HOST.GET_HOST}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const uploadHostDoc = async(data,stepId) =>{
    return await axiosInstance.put(`${HOST.HOST_UPLOAD}?stepId=${stepId}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const uploadProfilePicture = async(data) =>{
    return await axiosInstance.put(`${USER.PROFILE_PICTURE_UPLOAD}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getAllPlans = async(data) =>{
    return await axiosInstance.get(`${USER.MEMBERSHIP_PLANS}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const updateHostPlan= async(formData,stepId,hostId) =>{
    return await axiosInstance.patch(`${HOST.HOST_PLAN}?id=${hostId}&stepId=${stepId}`, formData).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const deleteHostDoc= async(docId) =>{
    return await axiosInstance.delete(`${HOST.DELETE_HOST_DOC}?id=${docId}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}