import React, { useState, useEffect } from 'react'
import img2 from "../../assets/image/becmehost5-img.png";
import { FaArrowLeft } from "react-icons/fa";
import { getAmbiance, getWowFactor } from '../../services/api/site-info.api';
import '../host/host.scss'

function HostingPlace({ prevHook, InputEventHook, hostData}) {
    const [places,setPlaces] = useState([]);
    const [wow,setWow] = useState([]);
    const [error,setError] = useState(false);
    const [formValues, setFormValues] = useState({
        ambiance: 1,
        wowFactorId: 1,
        hostingPlaceDescription: null
    });
    const prev = () => {
        prevHook();
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: name === "hostingPlaceDescription" ? value : parseInt(value)
        }));
        formValues.hostingPlaceDescription ? setError(false) : setError(true);
    };
    const handleSubmit = () => {
        if (formValues.hostingPlaceDescription === null || formValues.hostingPlaceDescription === '') {
            setError(true)
            return; 
        }
        const stepId = 5;
        InputEventHook(formValues, stepId);
    };
    const getPlace = async () =>{
        try {
            const res= await getAmbiance();
            setPlaces(res);
            
        }catch (error){
        }
    }
    const getWow = async () =>{
        try {
            const res_wow= await getWowFactor();
            setWow(res_wow)
        }catch (error){
        }
    }
    useEffect(()=>{
        const fetchData = async () => {
              try {
                  await Promise.all([getPlace(), getWow()]);
              } catch (err) {
                  console.error("Error fetching data:", err);
              } finally {
                if(hostData){
                    setFormValues(prev => ({
                        ...prev,
                        ambiance : hostData.ambiance || 1,
                        wowFactorId : hostData.wowFactorId || 1,
                        hostingPlaceDescription: hostData.hostingPlaceDescription || null
                    }))
                }
              }
          };
          fetchData();
    },[])
    return (
        <div className="host-wrapper">
        <div className="become-host-sec">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6 col-md-12 my-auto">
                            <div className="becomehost-img">
                                <img src={img2} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="sign-in-form becomehost-txt shadow">
                                <div>
                                    <h3>Where will you host in Indianapolis?</h3>
                                    <h4>Where are you hosting?</h4>
                                </div>
                                <div className="bcm-radio-btn-sec d-flex">
                                    {
                                        places?.length && places.map((list,index) => 
                                        (
                                            <div className="form-check radio-btn" key={index}>
                                                <input className="form-check-input" 
                                                        type="radio" 
                                                        name="ambiance"
                                                        id={`place${list.id}`}
                                                        checked={formValues.ambiance === list.id}
                                                        onChange={handleInputChange}
                                                        value={list.id} 
                                                />
                                                <label className="form-check-label" htmlFor={`place${list.id}`}>
                                                    {list.ambianceName}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="mt-3">
                                    <h4>WOW Factor</h4>
                                    <p>Don't worry, you will have full control over your availability!</p>
                                    <div className="bcm-radio-btn-sec d-flex flex-wrap">
                                        {
                                            wow.length && wow.map((list,index)=> (
                                                <div className="form-check radio-btn" key={index}>
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                        id="flexRadioDefault1" checked />
                                                    <input className="form-check-input" 
                                                        type="radio" 
                                                        name="wowFactorId"
                                                        id={`wow${list.id}`}
                                                        checked={formValues.wowFactorId === list.id}
                                                        onChange={handleInputChange}
                                                        value={list.id} 
                                                    />
                                                    <label className="form-check-label" htmlFor={`wow${list.id}`}>
                                                        {list.factors}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="bcm-host-text-border">
                                    <h4>Anything we should know about the place you are hosting at or your neighborhood?<sup className='req'>*</sup></h4>
                                    <div className="form-floating">
                                        <textarea className="form-control" 
                                                    id="floatingTextarea2" 
                                                    style={{ height: "100px", padding: "10px" }}
                                                    name="hostingPlaceDescription"
                                                    value={formValues.hostingPlaceDescription}
                                                    onChange={handleInputChange}
                                                    placeholder="If you love nature, you'll appreciate our proximity to the nearby lake, where you can enjoy kayaking, fishing, or simply taking in the stunning views."
                                                    >
                                        </textarea>
                                        {error && (<span className="form-error">Field is required</span>)}
                                    </div>
                                </div>
                                <div>
                                    <div className="submit-btn next-btn text-end">
                                        <div className='next-btn1' onClick={prev}>
                                                <FaArrowLeft />
                                        </div>
                                        <div><button className="btn" type="submit" onClick={handleSubmit}>Next</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HostingPlace