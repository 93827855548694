import React from 'react'
import ReactLoading from 'react-loading';

function Loading() {
  return (
    <div className="loading">
        <ReactLoading type="spinningBubbles" color="#FF7903" height={80} width={80} />
    </div>
  )
}

export default Loading