import React, { useState } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import '../autocomplete-search/autocomplete-search.scss'
import { GOOGLE_MAPS_API_KEY } from '../../const/api-url.const';

function AutocompleteSearch({onSelectAddress}) {
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const libraries = ["places"];
  const options = { componentRestrictions: { country: ['in', 'pl'] } };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handlePlaceSelect = () => {
    if (autocomplete) { 
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        const address=place.formatted_address.replace(/, /g, ',');
        setSelectedPlace({
          name: place.name,
          address: address,
        });
        onSelectAddress(address);
      }
    } else {
      console.warn("Autocomplete instance is not initialized yet.");
    }
  };

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={handlePlaceSelect}
        options={options}
      >
        <input
          type="text"
          placeholder="Enter a location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `32px`,
            padding: `0px`,
            borderRadius: `3px`,
            boxShadow: `none`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </Autocomplete>

      {selectedPlace && (
        <div style={{ marginTop: "0px", boxShadow: "none" }}>
          <h2>Selected Place: {selectedPlace.name}</h2>
          <p>Address: {selectedPlace.address}</p>
        </div>
      )}
    </>
  );
}

export default AutocompleteSearch;
