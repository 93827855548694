import React from 'react'
import { Outlet } from 'react-router-dom'
import './all-events.scss';

function AllEvents() {
  return (
    <div className='all-event-block'>
      <Outlet />
    </div>
  )
}

export default AllEvents