import React, { useEffect, useState } from 'react';
import { FaArrowLeftLong } from "react-icons/fa6";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup"; 

function BookingInfo({ prevHook, InputEventHook, eventInfoDetails }) {
  const prev = () => {
    prevHook();
  };
  const schema = yup.object().shape({
    numberOfGuests: yup.number().nullable().required("Number of guest is required").typeError('Number of guest is required'),
    gustPerCost: yup.number().nullable().when('postPay', {
      is: true,
      then: yup.number().required("Cost is required").typeError('Cost is required'),
      otherwise: yup.number().nullable(),
    }),
    postPay: yup.boolean()
  });
  const [payment] = useState([
    {label: 'Prepaid', value: true},
    {label: 'Pay at the event', value: false},
  ]) 
  const { register, setValue, handleSubmit, watch,formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      postPay: eventInfoDetails?.postPay || false,
    },
  });
 
  const nextSubmit = (formData) => {
    const stepId = 2;
    InputEventHook(formData,stepId);
  }

  useEffect(() => {
    if (eventInfoDetails) {
      const fields = ["numberOfGuests", "gustPerCost", "postPay"];
      fields.forEach((field) => setValue(field, eventInfoDetails[field]));
    }
  }, [eventInfoDetails]);
  const postPay = watch("postPay");
  return (
    <>
      <form onSubmit={handleSubmit(nextSubmit)}>
        <div id="pills-tab2">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="create-event-tab">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label input-label">Number Of Guest
                        <sup className="req">*</sup>
                      </label>
                      <input 
                        type="number" 
                        className="form-control"
                        {...register('numberOfGuests')}
                        placeholder="Enter number of guests"
                      />
                      {errors?.numberOfGuests && (
                        <span className="form-error">
                          {errors.numberOfGuests.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                      <div className="mb-3">
                          <label className="form-label input-label">Pay As You Go With</label>
                          <div className="radio-btn">
                              {
                                  payment.map((item,index)=> (
                                      <div className="form-check" key={index}>
                                          <input className="form-check-input" 
                                              type="radio"
                                              name="postPay"
                                              {...register("postPay")} 
                                              checked={watch("postPay") === item.value} 
                                              onChange={() => setValue("postPay", item.value)}
                                              value={item.value}
                                              id={`flexRadio${index}`} />
                                          <label className="form-check-label"
                                              htmlFor={`flexRadio${index}`}>
                                              {item.label}
                                          </label>
                                      </div>
                                  ))
                              }
                          </div>
                      </div>
                  </div>
                  {
                    postPay ? (
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label input-label">Cost Per Guest (PLN)
                          <sup className="req">*</sup>
                        </label>
                        <input 
                          type="number" 
                          className="form-control"
                          {...register('gustPerCost')}
                          placeholder="Enter cost"
                        />
                        {errors?.gustPerCost && (
                          <span className="form-error">
                            {errors.gustPerCost.message}
                          </span>
                        )}
                      </div>
                    </div>
                    )
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="submit-btn next-btn justify-content-end">
                <div className="submit-btn next-btn text-end">
                  <div>
                    <button className="prev-arrow" onClick={prev}>
                      <FaArrowLeftLong />
                    </button>
                  </div>
                  <div>
                    <button className="btn" type="submit">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default BookingInfo;
