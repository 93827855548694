import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../events.scss";
import { IoClose } from "react-icons/io5";
import Chat from '../../chatHost/chat/Chat'

function ChatModal(props) {
  return (
    <div>
      {/* <WishlistEvents/> */}
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vright"
        centered className="events-wrapper-main view-menu-modal chat-popup-wrapper"
      >
        <Modal.Body>{}
        <div className="view-menu-modal-body">
        <button onClick={props.onHide} className="close-icon"><IoClose/></button>
            <div className="view-menu-txt">
            <Chat />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChatModal;
