import React from "react";
import banner from "../../../assets/image/banner-video.mp4";
import '../banner/banner.scss'
import Search from "../../../shared/search/Search";
function Banner() {
  return (
    <>
      {/*banner video */}
      <div className="banner-wrapper h-100">
      <div className="responsive-video position-relative  h-100">
        <div className="h-100">
          <video autoPlay muted loop className=" h-100">
            <source src={banner} type="video/mp4" />
          </video>
          <div className="video-overlay h-100"></div>
        </div>

        <div className="banner-text-sec h-100">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-md-12 mt-auto">
                <div className="banner-flex-col d-flex flex-column flex-wrap">
                <div className="bnr-left mt-auto">
                  <h5>Welcome to</h5>
                  <h3>
                    Meet@Table <br />
                    Let’s Explore the <br /> Food World
                  </h3>
                  <p>
                    Savor local flavours, forge global <br /> connections,
                    discover a world of food
                  </p>
                </div>
                <Search />
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Banner;
