import React from "react";
import img1 from "../../assets/image/sign-in-bg.png";
import img3 from "../../assets/image/facebook1.png";
import img4 from "../../assets/image/google.png";
import img5 from "../../assets/image/instagram-icon.png";
import { useState } from "react";
import { resetPassword } from "../../services/api/user-auth.api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CiMail } from "react-icons/ci";
import Loading from "../../common/Loading";
import Toaster from "../../common/Toaster";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import "../forgot-password/forgotPassword.scss";
import logo from "../../assets/image/logo-dark.svg";

function ForgotPassword() {
  let schema = yup.object().shape({
    email: yup.string().required("Email is required").email("Invalid email"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [isLoading, setIsLoading] = useState(false);

  const validate = async (data) => {
    setIsLoading(true);
    try {
      const val = await resetPassword(data.email);
      reset();
      setIsLoading(false);
      Toaster("Email sent successfully", "success");
    } catch (error) {
      setIsLoading(false);
      Toaster("Invalid email", "error");
      console.error("Error validating email:", error);
    }
  };

  return (
    <>
      <div className="forgotPassword-wrapper">
        <form onSubmit={handleSubmit(validate)}>
          <div className="container-fluid sign-in">
            <div className="sign-in-bg">
              <img src={img1} alt="image" />
            </div>
            <div className="container h-100">
              <div className="row sign-in-row h-100">
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-lg-9 me-auto">
                      <div>
                        <div>
                          <div className="logo-image text-center">
                          <img src={logo} alt="image" />
                          </div>
                          <p className="sign-in-logo-txt text-center">
                            Login using social media to get quick access
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-8 mx-auto">
                            <div className="fb">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img3} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with facebook
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img4} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with google
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img5} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with instagram
                                  </p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="sign-in-form">
                        <div className="my-3 form-title d-flex align-items-center justify-content-between">
                          <p className="fw-bold  login-title">
                            Forgot your password?
                          </p>
                          <Link to="/">
                            <AiOutlineHome className="home-icon" />
                          </Link>
                        </div>
                        <div className="forgot-txt-msg">
                          <p>
                            Enter your email address below and we'll send you a
                            link to create a new one
                          </p>
                        </div>
                        <div>
                          <div className="mb-3">
                            <div className="position-relative">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                {...register("email")}
                              />
                              <div className="email-icon">
                                <CiMail />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="forgot-txt-msg">
                          <p>
                            By resetting your password, please note that you are
                            also accepting the meet@table Terms & Conditions
                          </p>
                        </div>
                        <div>
                          <button className="btn sign-in-btn" type="submit">
                            Reset Password
                          </button>
                        </div>
                        <div className="d-flex click-signup align-items-center justify-content-center">
                          <p className="mb-0">Back to</p>
                          <p className="mb-0">
                            <Link to="/login">Sign in now!</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {isLoading && <Loading />}
    </>
  );
}

export default ForgotPassword;
