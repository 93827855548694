import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ACCOUNT_SETTINGS,
  CHAT,
  MY_WISHLISTS,
  NOTIFICATIONS,
  UPDATE_PROFILE,
} from "../../const/route-url.const";
import img1 from "../../assets/image/breadcrumb-image.png";
import img2 from "../../assets/image/login-pattern2.png";
import { Nav } from "react-bootstrap";
import Loading from "../../common/Loading";
import { getProfile } from "../../services/api/user-auth.api";
import "../user/user.scss";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import {useSelector} from 'react-redux'
import {getEmail} from '../../util/auth'
import Toaster from "../../common/Toaster";

function User() {
  const [url, setUrl] = useState();
  const [userName, setUserName] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userEmail = useSelector((selector)=>selector?.userEmail?.value);
  const getUser = async () => {
    setIsLoading(true);
    try {
      const user = await getProfile();
      setUserName(user.firstName + " " + user.lastName);
      setUserDetails(user);
      setIsLoading(false);
    } catch (error) {}
  };
  useEffect(() => {}, []);
  useEffect(() => {
    getUser();
    let path = location.pathname.replace("/user/", "");
    setUrl(path);
  }, [location]);



  return (
    <>
      {userDetails ? (
        <>
          <div className="user-wrapper">
            {/* <!--breadcrumb image--> */}
            <div className="breadcrumb-image">
              <img src={img1} alt="image" />
              <div className="breadcrumb-overlay"></div>
              <div className="breadcrumb-title">
                <div className="breadcrumb-header">
                  <h4>Hi, {userName}</h4>
                </div>
              </div>
            </div>

            {/* <!--banner background--> */}
            <div className="pattern-row">
              <div className="col-12">
                <div className="pattern-2">
                  <img src={img2} alt="image" />
                </div>
              </div>
            </div>

            <main>
              <section>
                <div className="en position-relative">
                  <div className="en-ab">
                    <div className="shape-1"></div>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-tab">
                            {/* <!--tab--> */}
                            <Nav
                              variant="underline"
                              defaultActiveKey="experiences"
                              className="nav nav-tabs justify-content-center"
                            >
                              <Nav.Item className="nav-item">
                                <Link
                                  className={`${
                                    url == UPDATE_PROFILE ? "active" : ""
                                  } nav-link`}
                                  to={`${UPDATE_PROFILE}`}
                                >
                                  Profile
                                </Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Link
                                    className={`${
                                      url == CHAT ? "active" : ""
                                    } nav-link`}
                                   to={`${CHAT}`}
                                >
                                  Chat
                                </Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Link
                                  className={`${
                                    url == MY_WISHLISTS ? "active" : ""
                                  } nav-link`}
                                  to={`${MY_WISHLISTS}`}
                                >
                                  My Wishlists
                                </Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Link
                                  className={`${
                                    url == ACCOUNT_SETTINGS ? "active" : ""
                                  } nav-link`}
                                  to={`${ACCOUNT_SETTINGS}`}
                                >
                                  Account Settings
                                </Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Link
                                  className={`${
                                    url == NOTIFICATIONS ? "active" : ""
                                  } nav-link`}
                                  to={`${NOTIFICATIONS}`}
                                >
                                  Notifications
                                </Link>
                              </Nav.Item>
                            </Nav>
                            <Outlet />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </>
      ) : (
        isLoading && <Loading />
      )}
    </>
  );
}

export default User;
