import React, { useState } from "react";
import img1 from "../../assets/image/sign-in-bg.png";
import img3 from "../../assets/image/facebook1.png";
import img4 from "../../assets/image/google.png";
import img5 from "../../assets/image/instagram-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { PiEyeClosedLight } from "react-icons/pi";
import { PiEyeThin } from "react-icons/pi";
import Loading from "../../common/Loading";
import { updatePassword } from "../../services/api/user-auth.api";
import Toaster from "../../common/Toaster";
import { AiOutlineHome } from "react-icons/ai";
import "../change-password/changePassword.scss";
import logo from "../../assets/image/logo-dark.svg";

function ChangePassword() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let schema = yup.object().shape({
    newPassword: yup.string().required("New password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newPassword"), null], "Password must match"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const update = async (data) => {
    setIsLoading(true);
    try {
      data.id = id;
      const update = await updatePassword(data);
      setIsLoading(false);
      if (update) {
        Toaster("Password updated successfully", "success");
        navigate("/login");
      }
    } catch (error) {
      Toaster("Password updated failed, Try again later!", "error");
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="changePassword-wrapper">
        <form onSubmit={handleSubmit(update)}>
          <div className="container-fluid sign-in">
            <div className="sign-in-bg">
              <img src={img1} alt="image" />
            </div>
            <div className="container h-100">
              <div className="row sign-in-row h-100">
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-lg-9 me-auto">
                      <div>
                        <div>
                          <div className="logo-image text-center">
                          <img src={logo} alt="image" />
                          </div>
                          <p className="sign-in-logo-txt text-center">
                            Login using social media to get quick access
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-8 mx-auto">
                            <div className="fb">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img3} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with facebook
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img4} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with google
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img5} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with instagram
                                  </p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="sign-in-form">
                        <div className="my-3 form-title d-flex align-items-center justify-content-between">
                          <p className="fw-bold  login-title">Reset Password</p>
                          <Link to="/">
                            <AiOutlineHome className="home-icon" />
                          </Link>
                        </div>
                        <div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              New Password<sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={showNew ? "text" : "password"}
                                className="form-control"
                                placeholder="New password"
                                {...register("newPassword")}
                              />
                              {errors?.newPassword && (
                                <span className="form-error">
                                  {errors.newPassword.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setShowNew(!showNew)}
                              >
                                {!showNew && <PiEyeClosedLight />}
                                {showNew && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Confirm Password<sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={showConfirm ? "text" : "password"}
                                className="form-control"
                                placeholder="Confirm password"
                                {...register("confirmPassword")}
                              />
                              {errors?.confirmPassword && (
                                <span className="form-error">
                                  {errors.confirmPassword.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setShowConfirm(!showConfirm)}
                              >
                                {!showConfirm && <PiEyeClosedLight />}
                                {showConfirm && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button className="btn sign-in-btn" type="submit">
                            Submit
                          </button>
                        </div>
                        <div className="d-flex click-signup align-items-center justify-content-center">
                          <p className="mb-0">Back to</p>
                          <p className="mb-0">
                            <Link to="/login">Sign in!</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default ChangePassword;
