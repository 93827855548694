import React, { useState, useEffect } from 'react'
import WelcomeHost from './WelcomeHost';
import HostInfo from './HostInfo'
import TableHost from './TableHost';
import HostingExperience from './HostingExperience';
import HostingType from './HostingType';
import HostingPlace from './HostingPlace';
import HostDoc from './HostDoc';
import HostPlan from './HostPlan';
import { getHostId } from "../../util/auth";
import {patchHost, getHostDetails} from '../../services/api/user-auth.api';
import HostStatus from './HostStatus';
import Toaster from '../../common/Toaster';
import '../host/host.scss'

function Host() {
  const [hostId, setHostId] = useState(getHostId());
  const [hostDetails, setHostDetails] = useState(null);
  const [step, setStep] = useState(0);
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);
  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map(key => ({
        operationType: "Add",
        path: `/${key}`,
        op: "replace",
        value: formValues[key] 
    }));
  };
  const ChangeEvent = async (data,stepId) => {
      const transformedData = transformFormValues(data);
      try {
        const hostId = await getHostId();
        await patchHost(transformedData,stepId,hostId);
        getHost('update');
        nextStep();
      } catch (error) {
        Toaster('Error occured while saving the data', 'error');
      }
  };
  const getHost = async (type) =>{
    try{
      const host = await getHostDetails();
      if(host){
          setHostDetails(host);
          if(type === 'initial'){
            const id = host.stepId === 7 ? host.stepId + 1 : host.stepId
            setStep(id);
          }
      }
    }catch (error){
    }
  }
  useEffect(()=>{
    if(hostId){
      getHost('initial');
    }
  },[])
  return (
    <>
    {(() => {
        switch (step) {
          case 0:
            return (
              <WelcomeHost
                nextHook={nextStep}
              />
            );
          case 1:
            return (
              <HostInfo
                nextHook={nextStep}
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
              />
            );
          case 2:
            return (
              <TableHost
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
          );
          case 3:
            return (
              <HostingExperience
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
          );
          case 4:
            return (
              <HostingType
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
          );
          case 5:
            return (
              <HostingPlace
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
          );
          case 6:
            return (
              <HostDoc
                nextHook={nextStep}
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
              />
          );
          case 7:
            return (
              <HostPlan
                nextHook={nextStep}
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
              />
          );
          case 8:
            return (
              <HostStatus
                nextHook={nextStep}
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
              />
          );
          default:
            return <h1>No project match</h1>;
        }
      })()}
    </>
  )
}

export default Host