import React from "react";
import img2 from "../../../assets/image/breadcrumb-image.png";
import img3 from "../../../assets/image/login-pattern2.png";
import img4 from "../../../assets/image/contact1-img.png";
import img5 from "../../../assets/image/faq-bg.png";
import img6 from "../../../assets/image/contact2-img.png";
import { MdOutlinePhone } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../contactUs/contactUs.scss';
import banner from "../../../assets/image/new-banner-video.mp4";

function ContactUs() {
  return (
    <>
    <div className="contactUs-wrapper">
    <div className="breadcrumb-image">
            {/* <img src={img2} alt="image"/> */}
            <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>Contact Us</h4>
          </div>
          <div>
            <p>
              Our team will helpful experience for the user <br /> especially if
              they are expectiong a quick response.
            </p>
          </div>
        </div>
      </div>
      <div className="pattern-row">
        <div className="col-12">
          <div className="pattern-2">
            <img src={img3} alt="image" />
          </div>
        </div>
      </div>

      <div className="position-relative overflow-hidden p-s contactus-bg">
        <div className="contactus-bg-overlay"></div>
<div className="contactus-bg-content">
<div className="shape-1"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 my-auto">
              <div className="row">
                <div className="col-lg-7">
                  <div className="faq-bg h-100">
                    <div className="faq-img">
                      <div className="faq2-img">
                        <img src={img4} alt="image" />
                        <div className="faq-bg-img">
                          <img src={img5} alt="image" />
                        </div>
                      </div>
                      <div className="faq1-img">
                        <img src={img6} alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="sign-in-form contact-form">
                <div className="row gy-3">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="form-label input-label">
                      Phone number
                    </label>
                    <InputGroup className="mb-3 phone-block">
                        <InputGroup.Text id="basic-addon1">
                          <MdOutlinePhone />
                          <Form.Select aria-label="Default select example">
                            <option>+91</option>
                            <option value="1">+93</option>
                            <option value="2">+92</option>
                            <option value="3">+95</option>
                          </Form.Select>
                        </InputGroup.Text>
                        <Form.Control
                          placeholder=""
                          aria-label=""
                          aria-describedby="basic-addon1" type="number"
                        />
                      </InputGroup>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        What can we do for you?
                      </label>
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-btn d-flex justify-content-center">
                      <button className="btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shapefaq"></div>
</div>
     
      </div>
    </div>
      
    </>
  );
}

export default ContactUs;
