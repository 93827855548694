import { createSlice } from '@reduxjs/toolkit';

const tokenSlice = createSlice({
  name: 'tokenRefresh',
  initialState: {
    value: false, 
  },
  reducers: {
    setTokenRefresh: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setTokenRefresh } = tokenSlice.actions;

export default tokenSlice.reducer;
