import React from "react";
import img1 from "../../assets/image/logo-light.svg";
import { ImCompass } from "react-icons/im";
import { Link } from "react-router-dom";
import {
  ABOUT_US,
  CONTACT_US,
  EVENTS,
  FAQ,
  HANDPICKED_EVENTS,
  HOW_IT_WORKS,
  PRIVACY_POLICY,
  TERMS_AND_CONDITION,
} from "../../const/route-url.const";
import "../footer/footer.scss";
import "../../index.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
function Footer() {
  return (
    <>
      <div className="footer-wrapper">
        <div className="footer mt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto">
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <div className="footer-logo">
                        <img src={img1} alt="image" />
                      </div>
                      <p>
                        We are the first global community for shared food
                        experiences, bringing people together around dining
                        tables and food activities wherever they go.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="footer-menu">
                      <h5>Quick Links</h5>
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to={ABOUT_US}>Meet Us</Link>
                        </li>
                        <li>
                          <Link to={HOW_IT_WORKS}>How It Works</Link>
                        </li>
                        <li>
                          <Link to={HANDPICKED_EVENTS}>Events</Link>
                        </li>
                        <li>
                          <Link to={FAQ}>FAQ</Link>
                        </li>
                        <li>
                          <Link to={CONTACT_US}>Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
              
                    <div className="footer-form">
                      <h5>Join our table!</h5>
                      <form>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control join-input"
                            placeholder="your email address"
                            aria-label="your email address"
                            aria-describedby="basic-addon1"
                          />
                          <span className="input-group-text" id="basic-addon1">
                            <ImCompass className="text-white" />
                          </span>
                        </div>
                      </form>

                      <div className="social-block">
                        <Link to='https://play.google.com/store/games?hl=en' target="_blank" className="btn me-3 p-0" >
                        <FaGooglePlay />
                        </Link>
                        <Link to='https://www.apple.com/in/app-store/' target="_blank" className="btn p-0">
                        <FaApple />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                 
                    <div className="footer-form">
                      <h5>Language/Currency</h5>
                      <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="tab-loc-btn lang-btn"
                            >
                              EN/PLN
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="lang-dropdown">
                              <div className="row">
                                <div className="col-lg-5 col-md-5 col-5">
                                  <div className="lang-check">
                                    <p>Language</p>
                                    <div>
                                      <div className="form-check">
                                        <input className="form-check-input"
                                          type="checkbox" value=""
                                          id="flexCheckDefault1" checked />
                                        <label className="form-check-label"
                                          htmlFor="flexCheckDefault1">
                                          English
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input"
                                          type="checkbox" value=""
                                          id="flexCheckDefault2" />
                                        <label className="form-check-label"
                                          htmlFor="flexCheckDefault2">
                                          Polish
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-7">
                                  <div className="lang-check">
                                    <p>Currency</p>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div>
                                          <div className="form-check">
                                            <input className="form-check-input"
                                              type="checkbox" value=""
                                              id="flexCheckChecked3" checked />
                                            <label className="form-check-label"
                                              htmlFor="flexCheckChecked3">
                                              PLN
                                            </label>
                                          </div>
                                          <div className="form-check">
                                            <input className="form-check-input"
                                              type="checkbox" value=""
                                              id="flexCheckChecked4" />
                                            <label className="form-check-label"
                                              htmlFor="flexCheckChecked4">
                                              EUR € </label>
                                          </div>
                                          <div className="form-check">
                                            <input className="form-check-input"
                                              type="checkbox" value=""
                                              id="flexCheckChecked5" />
                                            <label className="form-check-label"
                                              htmlFor="flexCheckChecked5">
                                              USD $</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="cpy-ryt">
                      <p>
                        &#169; 2024{" "}
                       {" "}
                        All Rights Reserved. <span className="text-white">
                          <a href="#">Meet@Table</a>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="footer-tc">
                      <Link to={TERMS_AND_CONDITION}>Terms and Conditions</Link>
                      <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
