import React, { useEffect, useState } from 'react';
import { transformImageUrl } from '../../../util/dataTransform';
import LightGallery from 'lightgallery/react';
import fjGallery from 'flickr-justified-gallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';


function Gallery({ event }) {
    const [data, setData] = useState([]);
    useEffect(() => {
        if(event?.eventDocuments.length > 0){
                const imageData = event.eventDocuments.map((img)=> transformImageUrl(img.eventDocumentPath))
                setData(imageData);
        }
    }, [event]);
    useEffect(() => {
        if (data.length > 0) {
            fjGallery(document.querySelectorAll('.gallery'), {
                itemSelector: '.gallery__item',
                rowHeight: 250,
                lastRow: 'start',
                gutter: 8,
                rowHeightTolerance: 0.1,
                calculateItemsHeight: false,
            });
        }
    }, [data]); 

    return (
            <LightGallery
                plugins={[lgZoom,lgThumbnail]}
                mode="lg-fade"
                pager={false}
                thumbnail={true}
                elementClassNames={'gallery'}
                mobileSettings={{
                    controls: false,
                    showCloseIcon: false,
                    download: false,
                    rotate: false,
                }}
            >
                {data.map((image, index) => (
                    <a
                        key={index}
                        className="gallery__item"
                        data-src={image}
                    >
                        <img
                            className="img-responsive"
                            src={image}
                            alt={`Gallery item ${index + 1}`}
                        />
                    </a>
                ))}
            </LightGallery>
    );
}

export default Gallery;
