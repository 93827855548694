import React, { useState,useEffect } from "react";
import img2 from "../../assets/image/location.png";
import img3 from "../../assets/image/calender.png";
import img4 from "../../assets/image/guest.png";
import "../search/search.scss";
import "../../index.scss";
import DatePicker, { DateObject } from "react-multi-date-picker";
import {  useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import {  getSearchEvents } from "../../services/api/events-auth.api";
import AutocompleteSearch from "../../shared/autocomplete-search/autocomplete-search";
import { FiSearch } from "react-icons/fi";
import {useDispatch} from 'react-redux';
import {sendEvents, sendEventValues} from '../../redux/slice/searchSlice';
import {
  HANDPICKED_EVENTS,
} from "../../const/route-url.const";
function Search() {
  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [guests, setGuests] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
    const incrementGuests = () => {
      setGuests(prevGuests => prevGuests + 1);
    };
  
    const decrementGuests = () => {
      setGuests(prevGuests => (prevGuests > 1 ? prevGuests - 1 : 1));
    };

    const [searchEvents,setSearchEvents]=useState({
      Address:null,
      PageId: 1,
      PageSize: 10,
    })
    
    const selectDate = (dates) => {
      const [start, end] = dates; 
      const formattedStart = start ? format(new Date(start), 'MM/dd/yyyy') : 'Not selected';
      const formattedEnd = end ? format(new Date(end), 'MM/dd/yyyy') : 'Not selected';
      setSearchEvents((prev) => ({
        ...prev,
      FromDate:formattedStart,
      ToDate:formattedEnd,
      }));
    }

  const handleAddressSelect = (address) => {
    setSearchEvents(prev => ({
      ...prev,
      Address: address,
    }));
  };

    const handleSearch = async () => {
      if(searchEvents.Address===null) return
      const payload = { ...searchEvents };
      if (guests > 1) {
        setSearchEvents((prev) => ({
          ...prev,
          NoOfGuest:guests
        })); 
      } else {
        delete payload.NoOfGuest; 
      }
      setIsLoading(true);
      try {
        const data = await getSearchEvents(payload);
        dispatch(sendEvents(payload))
        dispatch(sendEventValues(searchEvents))
        navigate(`/${HANDPICKED_EVENTS}`);
        // setFilteredEvents(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    useEffect(() => {
      setSearchEvents((prev) => ({
        ...prev,
        NoOfGuest: guests,
      }));
    }, [guests]);
  return (
    <>
      {/* search bar */}
      <div className="search-wrapper ad-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
            <div className="row">
                  <div className="col ad-col my-auto">
                    <div className="d-flex align-items-center">
                      <div className="ad-icon">
                        <img src={img2} alt="image" />
                      </div>
                      <div className="autocomplete-search">
                      <AutocompleteSearch  onSelectAddress={handleAddressSelect} />
                    </div>
                    </div>
                  </div>
                  <div className="col ad-col my-auto">
                    <div className="d-flex align-items-center">
                      <div className="ad-icon">
                        <img src={img3} alt="image" />
                      </div>
                      <div className="rmdp-container-main">
                        <DatePicker
                            value={values}
                            selected={startDate}
                            onChange={selectDate} 
                            startDate={startDate}
                            endDate={endDate}
                            range 
                            inline 
                            minDate={new Date()} 
                        />
                        {/* <ul>
                        <li>
                          19 July <br />
                          <span className="hgt">To</span> <br />
                          19 Aug
                        </li>
                      </ul> */}
                      </div>
                    </div>
                  </div>
                  <div className="col my-auto">
                    <div className="d-flex align-items-center guest-count-block-main">
                      <div className="ad-icon">
                        <img src={img4} alt="image" />
                      </div>
                      <div className='guest-count-block'>
                      <ul>
                        <li>
                          <div id="guestCount" className="d-flex align-items-cente guest-count">
                            <span className='me-3'>{guests}</span>
                            <button className='btn me-2 ' onClick={decrementGuests} disabled={guests === 1}>
                              -
                            </button>
                            <button className='btn btn-minus' onClick={incrementGuests}>+</button>
                          </div>
                          <span className="hgt">Guest</span>
                        </li>
                      </ul>
                      </div>       
                    </div>
                  </div>
                  <div className="w-auto my-auto">
                    <div className="text-end">
                      <button className="btn"  onClick={()=>handleSearch()}>
                      <FiSearch />
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
