import React from 'react'
import img1 from "../../assets/image/becomehost-pattern.png";
import img2 from "../../assets/image/becmehost-img.png";
import img3 from "../../assets/image/tick.png";
import '../host/host.scss'


function WelcomeHost({ nextHook }) {
    const next = () => {
        nextHook();
    };
    return (
        <div className="host-wrapper">
                <div className="become-host-sec">
                    <div className="container h-100">
                        {/* <div className="d-flex justify-content-end">
                            <div className="bcm-host-btn">
                                <button>Save & Exit</button>
                            </div>
                            <div className="bcm-host-btn">
                                <button>Delete my Application</button>
                            </div>
                        </div> */}
                        <div className="row h-100 align-items-center gy-3 mt-2">
                            <div className="col-lg-6 col-md-12 my-auto">
                                <div className="becomehost-img">
                                    <img src={img2} alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="becomehost-txt">
                                    <div>
                                        <div>
                                            <h3>10 steps to become an meet@table host</h3>
                                            <h4>Welcome! Your meet@table journey is about to start</h4>
                                            <p>On meet@table, you will find a range of culinary experiences such
                                                as dinners, cooking classes, food tours, tasting workshops and
                                                much more. They are led by friendly and open-minded hosts who
                                                want to share their story and create a memorable and unique
                                                experience for their guests.</p>
                                            <p>The application to become a host takes less than 10 minutes to
                                                complete. You will be asked to give some information about
                                                yourself, outline the experience you would like to offer and
                                                upload some photos. Your application will then be reviewed by
                                                our Community Team and, if it meets our standard, it will
                                                quickly be approved. If not, a member of the team will send you
                                                a message with some suggestions for how to improve it and once
                                                you have made any necessary changes, your application will be
                                                reviewed again. As soon as your application is approved, you
                                                will be able to create your first experience and start hosting!
                                            </p>
                                        </div>
                                        <div className="bcm-list">
                                            <p>Before starting, please ensure you have the following ready:</p>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div className="d-flex">
                                                            <div className="tick-img">
                                                                <img src={img3} alt="image" />
                                                            </div>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing
                                                                    elit. </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex">
                                                            <div className="tick-img">
                                                                <img src={img3} alt="image" />
                                                            </div>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing
                                                                    elit.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="next-btn d-flex justify-content-center">
                                            <a type="button" onClick={next}>Let's Start</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default WelcomeHost