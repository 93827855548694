import React, { useMemo, useState, useEffect } from "react";
import img2 from "../../../assets/image/breadcrumb-image.png";
import "../all-events.scss";
import { Link } from "react-router-dom";
import {ALL_EVENTS,CREATE_EVENT,EDIT_EVENT} from "../../../const/route-url.const";
import Pagination from "../../../common/Pagination";
import data from "../events/data.json";
import { getHostDetails} from "../../../services/api/user-auth.api";
import { getHostEvents } from "../../../services/api/events-auth.api";
import Loading from "../../../common/Loading";
import { removeEventId } from '../../../util/auth';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EventContent from './EventContent'

function ViewEvents() {
  const [currentPage, setCurrentPage] = useState(1);
  const [events,setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('pending');
  const [searchParams,setSearchParams] = useState({
    pageId: 1,
    pageSize: 10,
    status : 0
  });
  const [hostId,setHostId] = useState(null);
  let PageSize = 2;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  useEffect(()=>{
    removeEventId();
    getHost();
  },[])

  const getAllHostEvents = async() =>{
    setIsLoading(true);
    try{
      const res = await getHostEvents(searchParams);
      setEvents(res);
    }catch(error){
    }finally{
      setIsLoading(false);
    }
  }
  const getHost = async () => {
      setIsLoading(true);
      try {
        const host = await getHostDetails();
        if(host.id){
          setSearchParams(prev => ({
            ...prev,
            HostId : host.id
          }))
        }
      } catch (error) { }finally {
        setIsLoading(false);
      }
  };
 
  const handleSelect = (key) => {
    setCurrentTab(key);
    setSearchParams(prev => ({
      ...prev,
      currentDate : '',
    }))
    switch (key) {
      case "past":
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        setSearchParams(prev => ({
          ...prev,
          status : 1,
          currentDate : today.toISOString()
        }))
        break;
      case "pending":
        setSearchParams(prev => ({
          ...prev,
          status : 0,
        }))
        break;
      case "approved":
        setSearchParams(prev => ({
          ...prev,
          status : 1
        }))
        break;
      case "default":
        break;
    }
  }
  const refresh = () =>{
    getAllHostEvents();
  }
  useEffect(()=>{
      getAllHostEvents();
  },[searchParams])
  
  return (
    <>
      <div className="breadcrumb-image">
        <img src={img2} alt="image" />
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>Events</h4>
          </div>
        </div>
      </div>
      <main>
        <section>
          <div className="position-relative overflow-hidden p-s menu-tab">
            <div className="shape-1"></div>
            <div className="container">
              <div className="en-center d-flex justify-content-between mb-2">
                <h3></h3>
                <div className="next-btn mt-0">
                  <Link to={`${CREATE_EVENT}`} type="button">
                    Create Event
                  </Link>
                </div>
              </div>
              <div className="row mt-3">
              <div className="col-md-12">
                <div className="menu-tab">
                  {/* tab */}
                  <Tabs
                    defaultActiveKey="pending"
                    id="controlled-tab-example"
                    className="nav-underline"
                    onSelect={handleSelect}
                  >
                     <Tab eventKey="pending" className="nav-item " title="Pending Events">
                      <EventContent eventList={events} refreshHook={refresh} tab={currentTab}/>
                     </Tab>
                     <Tab eventKey="approved" className="nav-item" title="Upcoming Events">
                      <EventContent eventList={events} refreshHook={refresh} tab={currentTab}/>
                    </Tab>
                    <Tab eventKey="past" className="nav-item" title="Past Events">
                          <EventContent eventList={events} refreshHook={refresh} tab={currentTab}/>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            </div>
            <div className="shapefaq"></div>
          </div>
        </section>
      </main>
      {/* {events.length > 0 && (
        <>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={events.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      )} */}
        {isLoading && <Loading />}
    </>
  );
}

export default ViewEvents;
