import React, { useEffect,useState } from 'react'
import exp1 from "../../../assets/image/exp1.png";
import exp2 from "../../../assets/image/exp2.png";
import exp3 from "../../../assets/image/exp3.png";
import exp4 from "../../../assets/image/exp4.png";
import tick from "../../../assets/image/tick.png";
import "../events.scss";
import { getEventCategory } from '../../../services/api/events-auth.api';

function AllExperiences({event}) {
    const [hours,setHours] = useState();
    const [startTime,setStartTime] = useState();

  useEffect(()=>{
    if(event){
        const startDate = new Date(event.fromDate);
        let hours = startDate.getHours();
        const minutes = startDate.getMinutes(); 
        const seconds = startDate.getSeconds(); 
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; 
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
        setStartTime(formattedTime);
        if(event?.toDate){
            const endDate = new Date(event?.toDate);
            const diffInHours = (endDate - startDate) / (1000 * 60 * 60);
            setHours(diffInHours.toFixed(0));
        }
    }
  })

  return (
    <>
     <div className='events-wrapper-main'>
        {/* <!--tab content--> */}
        <div className="tab-content">
        <div id="all" className="tab-pane active evnt-exp-tab">
        <div className="row g-3">
            <div className="col-lg-3 col-md-6">
            <div className="evnt-feat d-flex">
                <div className="evnt-feat-img">
                <img src={exp1} alt="image" />
                </div>
                <div>
                <p className="mb-0">
                    {event?.eventCategory}, {event?.eventCuisines}
                </p>
                </div>
            </div>
            </div>
            <div className="col-lg-3 col-md-6">
            <div className="evnt-feat d-flex">
                <div className="evnt-feat-img">
                <img src={exp2} alt="image" />
                </div>
                <div>
                <p className="mb-0">{event?.numberOfGuests} guests maximum</p>
                </div>
            </div>
            </div>
            <div className="col-lg-3 col-md-6">
            <div className="evnt-feat d-flex">
                <div className="evnt-feat-img">
                <img src={exp3} alt="image" />
                </div>
                <div>
                <p className="mb-0">{startTime} 
                    {
                        event?.toDate && (
                            <span>({hours} hours)</span>
                        )
                    }
                </p>
                </div>
            </div>
            </div>
            {
                event?.eventSubCategory && (
                  <div className="col-lg-3 col-md-6">
                        <div className="evnt-feat d-flex">
                            <div className="evnt-feat-img">
                            <img src={exp4} alt="image"/>
                            </div>
                            <div>
                            <p className="mb-0">
                                {event?.eventSubCategory}
                            </p>
                            </div>
                        </div>
                  </div>
                )
            }
        </div>
        {
            event?.foodCertified && (
                <div className="row mt-2">
                    <div className="col-lg-4">
                    <div className="fs-cer">
                        <span>Food safety certified</span>
                    </div>
                    </div>
                </div>
            )
        }
        <div className="row mt-2">
            <div className="col-md-12">
            <div className="evnt-exp-des">
                <p>{event?.eventDescription}</p>
            </div>
            </div>
        </div>
        </div>
        </div>
	</div>
    </>
  )
}

export default AllExperiences;