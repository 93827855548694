import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDvjRZDAuutgVuU3unLc9taitVGSrwdeZE",
  authDomain: "meet-poc-60872.firebaseapp.com",
  projectId: "meet-poc-60872",
  storageBucket: "meet-poc-60872.appspot.com",
  messagingSenderId: "517337541888",
  appId: "1:517337541888:web:0c42758f2e40bf28b1d7e5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
export const messaging = getMessaging(app)