import React, { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { doc, getDoc, getDocs, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where, collection } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import Input from "../input/Input"; // Import the Input component
import Message from "../message/Message";
import './chat.scss';
import {useSelector} from 'react-redux'

const Chat = () => {
  const [user, setUser] = useState(null);
  const [err, setErr] = useState(false);
  const [currentUser] = useAuthState(auth);
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
//   const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const hostEmail = useSelector(selector => selector?.HostMailReducer?.hostEmail)
 

  useEffect(() => {
    if (chatId) {
      const unSub = onSnapshot(doc(db, "chats", chatId), (doc) => {
        if (doc.exists()) {
          setMessages(doc?.data().messages || []);
        }
      });

      return () => unSub();
    }
  }, [chatId]);

  const handleChat = async (email) => {
    email = email.toLowerCase();
    // setIsActive((prev)=> prev = !prev)
    const q = query(
      collection(db, "users"),
      where("email", "==", email)
    );

    try {
      const querySnapshot = await getDocs(q);
      const userDoc = querySnapshot.docs[0];

      if (!userDoc) {
        setErr(true);
        return;
      }

      const userData = userDoc.data();
      setUser(userData);
      setSelectedUser(userData);

      const combinedId =
        currentUser.uid > userData.uid
          ? currentUser.uid + userData.uid
          : userData.uid + currentUser.uid;

      setChatId(combinedId);

      const chatDocRef = doc(db, "chats", combinedId);
      const chatSnapshot = await getDoc(chatDocRef);

      if (!chatSnapshot.exists()) {
        await setDoc(chatDocRef, { messages: [] });

        await updateDoc(doc(db, "userChats", currentUser?.uid), {
          [`${combinedId}.userInfo`]: {
            uid: userData.uid,
            displayName: userData.displayName,
            photoURL: userData.photoURL,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", userData.uid), {
          [`${combinedId}.userInfo`]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Error finding or creating chat:", error);
      setErr(true);
    }
  };



  useEffect(()=>{
    handleChat(hostEmail);
  },[hostEmail])

  return (
    <div>

      <div className="search">
        {err && <span>User not found or an error occurred!</span>}
        {user && (
          <div className="userChat">
            {/* <img src={user.photoURL} alt="" /> */}
            <div className="userChatInfo">
             <h2> <span>{user.displayName}</span> </h2>
            </div>
          </div>
        )}
      </div>
      {chatId && selectedUser && (
        <div className="chat">
          <div className="col-12 chat-message">
            {messages.map((msg) => (
              <Message 
                key={msg.id}
                message={msg}
                currentUser={currentUser}
              />
            ))}
          </div>
          <Input chatId={chatId} currentUser={currentUser} />
        </div>
      )}
    </div>
  );
};

export default Chat;
