import React from 'react'
import './activation.scss';
import { FiUserX } from "react-icons/fi";
import { Link } from 'react-router-dom';

function ActivationFailed() {
  return (
   <>
    <main>
        <section>
            <div className="verify-sec">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-9 mx-auto">
                            <div className="verify-section">
                                <FiUserX className='failed'/>
                                <h3>Activation Link Verification <br />Failed</h3>
                                <Link to="/"><button className="btn">Back to Home</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
   </>
  )
}

export default ActivationFailed