import { Navigate } from 'react-router-dom';
import {HOST} from '../const/route-url.const';
import App from '../App'
import Host from '../components/host/Host';
 const hostRoutes = [
   {
    path: '',
    element : <App />,
    children: [
      {
        path: HOST,
        element : <Host />
       }
    ]
   }
  ];

  export default hostRoutes;