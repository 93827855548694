import React, { useState, useEffect } from 'react';
import img2 from "../../assets/image/becmehost4-img.png";
import { FaArrowLeft } from "react-icons/fa";
import { getExperienceType } from '../../services/api/site-info.api';
import '../host/host.scss'

function HostingType({ prevHook, InputEventHook, hostData }) {
    const [availaList, setAvailList] = useState([
        { id: 2, availability: "Weekdays" },
        { id: 1, availability: "Weekends" },
        { id: 0, availability: "I don't know yet" }
    ]);
    const [type, setType] = useState([]);
    const [error,setError] = useState(false);
    const [formValues, setFormValues] = useState({
        typeOfHostExperienceId: 1,
        experienceDescription: null,
        hostAvailability: 0
    });
    const prev = () => {
        prevHook();
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: name === "experienceDescription" ? value : parseInt(value)
        }));
        formValues.experienceDescription ? setError(false) : setError(true);
    };
    const handleSubmit = () => {
        if (formValues.experienceDescription === null || formValues.experienceDescription === '') {
            setError(true)
            return; 
        }
        const stepId = 4;
        InputEventHook(formValues, stepId);
    };
    useEffect(() => {
        const getType = async () => {
            try {
                const response = await getExperienceType();
                setType(response);
                if(hostData){
                    setFormValues(prev =>({
                        ...prev,
                        typeOfHostExperienceId : hostData.typeOfHostExperienceId || 1,
                        experienceDescription: hostData.experienceDescription || null,
                        hostAvailability : hostData.hostAvailability || 0,
                    }))
                }
               
            } catch (error) {
                console.error("Failed to fetch experience types:", error);
            }
        };
        getType();
    }, []);
    
    return (
        <div className="host-wrapper">
            <div className="become-host-sec">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6 col-md-12 my-auto">
                            <div className="becomehost-img">
                                <img src={img2} alt="host" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="sign-in-form becomehost-txt shadow">
                                <div>
                                    <h3>What type of experience will you host first?</h3>
                                    <p>Once your host application has been approved, you will be able to create multiple different experiences.</p>
                                    <h4>What type of experiences would you like to host?</h4>
                                </div>
                                <div className="bcm-radio-btn-sec d-flex">
                                    {type?.length && type.map((list) => (
                                        <div className="form-check radio-btn" key={list.id}>
                                            <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="typeOfHostExperienceId"
                                                id={`type${list.id}`}
                                                checked={formValues.typeOfHostExperienceId === list.id}
                                                onChange={handleInputChange}
                                                value={list.id}
                                            />
                                            <label className="form-check-label" htmlFor={`type${list.id}`}>
                                                {list.experiences}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="bcm-host-text-border">
                                    <h4>Describe the first experience you would like to host<sup className='req'>*</sup></h4>
                                    <div className="form-floating">
                                        <textarea 
                                            className="form-control" 
                                            id="floatingTextarea2" 
                                            style={{ height: "100px", padding: "10px" }}
                                            name="experienceDescription"
                                            value={formValues.experienceDescription}
                                            onChange={handleInputChange}
                                            placeholder="Describe your first hosting experience"
                                        />
                                        {error && (<span className="form-error">Field is required</span>)}
                                    </div>
                                </div>
                                <div>
                                    <h4>How often are you available to host?</h4>
                                    <p>Don't worry, you will have full control over your availability!</p>
                                    <div className="bcm-radio-btn-sec d-flex">
                                        {availaList.map((list) => (
                                            <div className="form-check radio-btn" key={list.id}>
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name="hostAvailability"
                                                    id={`avail${list.id}`}
                                                    checked={formValues.hostAvailability === list.id}
                                                    onChange={handleInputChange}
                                                    value={list.id}
                                                />
                                                <label className="form-check-label" htmlFor={`avail${list.id}`}>
                                                    {list.availability}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <div className="submit-btn next-btn text-end">
                                        <div className='next-btn1' onClick={prev}>
                                            <FaArrowLeft />
                                        </div>
                                        <div><button className="btn" type="submit" onClick={handleSubmit}>Next</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HostingType;
