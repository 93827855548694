import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import allRoute from './commonRoute';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleOAuthProvider } from "@react-oauth/google"
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './redux/store/store';
import "react-datetime/css/react-datetime.css";
import { AuthContextProvider } from "./context/AuthContext";
import { ChatContextProvider } from "./context/ChatContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
const routes = createBrowserRouter(allRoute);

root.render(
  <Provider store={store}>
  <GoogleOAuthProvider clientId="949613497758-1121ss7i8a2brae4cmvgr74c5lu1ab1r.apps.googleusercontent.com">
    <AuthContextProvider>
      <ChatContextProvider>
        <React.StrictMode>
          <RouterProvider router={routes}>
            <App />
          </RouterProvider>
        </React.StrictMode>
      </ChatContextProvider>
    </AuthContextProvider>
  </GoogleOAuthProvider>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
