import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import fjGallery from 'flickr-justified-gallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

function GalleryChat({ event }) {
    const [data, setData] = useState([event]);

    useEffect(() => {
        if (data.length > 0) {
            fjGallery(document.querySelectorAll('.gallery'), {
                itemSelector: '.gallery__item',
                rowHeight: 250,
                lastRow: 'start',
                gutter: 8,
                rowHeightTolerance: 0.1,
                calculateItemsHeight: false,
            });
        }
    }, [data]); 

    return (
            <LightGallery
                plugins={[lgZoom,lgThumbnail]}
                mode="lg-fade"
                pager={false}
                thumbnail={true}
                elementClassNames={'gallery'}
                mobileSettings={{
                    controls: false,
                    showCloseIcon: false,
                    download: false,
                    rotate: false,
                }}
            >
                {data.map((image, index) => (
                    <a
                        key={image?.id}
                        className="gallery__item position-relative"
                        data-src={image?.img}
                    >
                        <img
                            className="img-responsive"
                            src={image?.img}
                            alt={`Gallery item ${index + 1}`}
                        />
                    </a>
                ))}
            </LightGallery>
    );
}

export default GalleryChat;
