import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { getfaqs } from "../../../services/api/site-info.api";
import '../faq/home-faq.scss'
function Faq() {
  const [faqs, setFaqs] = useState();
  useEffect(() => {
    getFaqData();
  }, []);

  const getFaqData = async () => {
    try {
      const faqData = await getfaqs();
      setFaqs(faqData);
    } catch (error) {}
  };
  return (
    <>
        <div className="home-faq-wrapper">
        <div className="en">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="en-center faq-txt text-center">
                  <h3>FAQ</h3>
                  <p>Search our knowledge base for answers to common</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
              <Accordion className="faq-accordion h-100">
                  {faqs?.length &&
                    faqs?.map((faq) => {
                      return (
                        <Accordion.Item eventKey={faq?.id} key={faq?.id}>
                          <Accordion.Header>{faq?.question}</Accordion.Header>
                          <Accordion.Body className="accordion-body">
                            <p dangerouslySetInnerHTML={{ __html: faq?.answer }} className="mb-0"></p>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
