import React, { useEffect, useState } from "react";
import img1 from "../../../assets/image/bg4-img.png";
import { getUpcomingEvents } from "../../../services/api/events-auth.api";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loading from "../../../common/Loading";
import { getEventsByFilter } from "../../../services/api/events-auth.api";
import "../popular-events/popularEvents.scss";
import TabContent from "./TabContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PopularEvents() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 
  const handleSelect = (key) => {
    const searchParams = {};
    switch (key) {
      case "experiences":
        getAllEvents();
        break;
      case "tomorrow":
        const tomorrow = new Date();
        tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
        tomorrow.setUTCHours(0, 0, 0, 0);
        searchParams.DateOfEvent = tomorrow.toISOString();
        filterEvents(searchParams);
        break;
      case "week":
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        searchParams.FromDate = today.toISOString();
        const week = new Date();
        week.setUTCDate(week.getUTCDate() + 7); 
        week.setUTCHours(0, 0, 0, 0);
        searchParams.ToDate = week.toISOString();
        filterEvents(searchParams);
        break;
      case "dinner":
        const today1 = new Date();
        today1.setUTCHours(0, 0, 0, 0);
        searchParams.FromDate = today1.toISOString();
        const catArr = [4]
        searchParams.eventCategory = catArr
        filterEvents(searchParams);
        break;
      case "cooking":
        const today2 = new Date();
        today2.setUTCHours(0, 0, 0, 0);
        searchParams.FromDate = today2.toISOString();
        const catArr1 = [18]
        searchParams.eventCategory = catArr1
        filterEvents(searchParams);
        break;
      case "food-tours":
        const today3 = new Date();
        today3.setUTCHours(0, 0, 0, 0);
        searchParams.FromDate = today3.toISOString();
        const catArr2 = [19]
        searchParams.eventCategory = catArr2
        filterEvents(searchParams);
        break;
      case "default":
        break;
    }
  };
  const filterEvents = async (searchParams) => {
    try {
      const res = await getEventsByFilter(searchParams);
      setEvents(res);
    } catch (error) {}
  };

  const getAllEvents = async () => {
    try {
      const data = await getUpcomingEvents();
      setEvents(data);
    } catch (error) {}
  };
  
  useEffect(() => {
    getAllEvents();
  }, []);
  return (
    <>
      <div className="popularEvents-wrapper">
        <div className="en position-relative">
          <div className="bg4-img">
            <img src={img1} alt="image" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="en-center text-center">
                  <h5>Happy to share their stories and insights</h5>
                  <h3>Popular Events</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi sint natus ratione! Ab cum ex et. Repudiandae fugiat
                    error praesentium porro atque, blanditiis.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="menu-tab">
                  {/* tab */}
                  <Tabs
                    defaultActiveKey="experiences"
                    id="controlled-tab-example"
                    className="nav-underline"
                    onSelect={handleSelect}
                  >
                    <Tab eventKey="experiences" className="nav-item" title="All Experiences">
                      {/* tab content */}
                          <TabContent eventList={events}/>
                    </Tab>
                    <Tab eventKey="tomorrow" className="nav-item " title="Tomorrow">
                      <TabContent eventList={events}/>
                    </Tab>
                    <Tab eventKey="week" className="nav-item" title="This week">
                      <TabContent eventList={events}/>
                    </Tab>
                    <Tab eventKey="dinner" className="nav-item " title="Dinners">
                      <TabContent eventList={events}/>
                    </Tab>
                    <Tab eventKey="cooking" className="nav-item" title="Cooking Classess">
                      <TabContent eventList={events}/>
                    </Tab>
                    <Tab eventKey="food-tours" className="nav-item" title="Food tours">
                      <TabContent eventList={events}/>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default PopularEvents;
