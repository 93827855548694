export const LOGIN = 'login';
export const REGISTER = 'register';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const ACTIVATION_SUCCESS = 'success-activation-request'
export const ACTIVATION_FAILED = 'failed-activation-request'
 
// Info
export const FAQ = 'faq';
export const CONTACT_US = 'contact-us';
export const ABOUT_US = 'about-us'
export const PRIVACY_POLICY = 'privacy-policy'
export const TERMS_AND_CONDITION = 'terms-and-conditions'
export const HOW_IT_WORKS='how-it-works'
 
// Host
export const HOST = 'host';
 
// User
export const USER = 'user'
export const UPDATE_PROFILE = 'edit'
export const CHAT = 'chat'
export const MY_WISHLISTS = 'my-wishlists'
export const ACCOUNT_SETTINGS = 'account-settings'
export const NOTIFICATIONS = 'notifications'
 
 
//Events
export const ALL_EVENTS = 'all-events'
export const CREATE_EVENT = 'create'
export const EDIT_EVENT ='edit'
 
//popular events
export const EVENTS = 'event'
export const HANDPICKED_EVENTS='events'
 