import React, { useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Loading from "../../../common/Loading";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { changePassword } from "../../../services/api/user-auth.api";
import Toaster from "../../../common/Toaster";
import { useNavigate } from "react-router-dom";

function AccountSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  let schema = yup.object().shape({
    currentPassword: yup.string().required("New password is required"),
    newPassword: yup.string().required("New password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newPassword"), null], "Password must match"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const update = async (data) => {
    setIsLoading(true);
    try {
      const change = await changePassword(data);
      setIsLoading(false);
      if (change && change.succeeded===true) {
        Toaster("Password updated successfully", "success");
        navigate("/login");
      }
      else{
        Toaster("Current password is Mismatched", "error");
      }
    } catch (error) {
      Toaster("Password Changing failed, Try again later!", "error");
      setIsLoading(false);
    }
  };
  return (
    <>
      {/* <!-- change password --> */}
      <div className="profile-info">
        <div>
          <p className="profile-title">Change my password</p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bx-shadow">
              <div className="sign-in-form contact-form becomehost-txt">
                <div>
                  <form onSubmit={handleSubmit(update)}>
                    <div className="row gy-3">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label input-label">
                            Current password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            {...register("currentPassword")}
                          />
                           {errors?.currentPassword && (
                          <span className="form-error">
                            {errors.currentPassword.message}
                          </span>
                        )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label input-label">
                            New Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            {...register("newPassword")}
                          />
                           {errors?.newPassword && (
                          <span className="form-error">
                            {errors.newPassword.message}
                          </span>
                        )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label input-label">
                            Confirm new Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            {...register("confirmPassword")}
                          />
                           {errors?.confirmPassword && (
                          <span className="form-error">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                        </div>
                      </div>
                      <div className="col-12 mt-0">
                        <div className="ok-btn text-end ">
                          <button className="btn ">Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- delete account --> */}
      <div className="profile-info">
        <div>
          <p className="profile-title">Delete my account</p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bx-shadow">
              <div className="sign-in-form contact-form becomehost-txt">
                <div className="ac-delete-alert">
                  <p>
                    {" "}
                    <IoMdInformationCircleOutline className="me-2" />
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nostrum sed reiciendis nesciunt quas odit esse libero eius
                    ratione impedit nemo laboriosam voluptatem ipsum ipsam
                    voluptatum illum, veniam illo, hic inventore?
                  </p>
                </div>
                <div>
                  <div className="row gy-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label input-label">
                          password
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-12 mt-0">
                      <div className="ok-btn text-end">
                        <button className="btn">Delete account</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountSettings;
