import React, { useEffect,useState } from "react";
import img2 from "../../../assets/image/login-pattern2.png";
import '../privacyPolicy/privacyPolicy.scss';
import { Link } from "react-router-dom";
import banner from "../../../assets/image/new-banner-video.mp4";
import { getPrivacyPolicy } from "../../../services/api/site-info.api";

function PrivacyPolicy() {
  const [privacyData,setPrivacyData] = useState('');
  const getData = async()=>{
    try{
      const res = await getPrivacyPolicy();
      setPrivacyData(res);
    }catch (error){}
  }
  useEffect(()=> {
    getData();
  },[])
  return (
    <>
    <div className="privacyPolicy-wrapper">
<     div className="breadcrumb-image">
            <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>Privacy Policy</h4>
          </div>
        </div>
      </div>

      {/* <!--banner background--> */}
      <div className="pattern-row">
        <div className="col-12">
          <div className="pattern-2">
            <img src={img2} alt="image" />
          </div>
        </div>
      </div>

      {/* <!--main section--> */}
      <main>
        {/* <!--faq--> */}
        <section>
          <div className="position-relative overflow-hidden p-s privacypolicy-bg">
            <div className="privacypolicy-bg-overlay"></div>
            <div className="privacypolicy-bg-content">
            <div className="shape-1"></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 my-auto">
                  <div className="abt-text">
                    <div dangerouslySetInnerHTML={{ __html: privacyData[0]?.text }} className="mb-0"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
            </div>
          
          </div>
        </section>
      </main>
    </div>
    </>
  );
}

export default PrivacyPolicy;
