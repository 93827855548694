import React, {useState,useEffect} from 'react'
import { FaArrowLeftLong } from "react-icons/fa6";

function ChefHost({prevHook, InputEventHook, eventInfoDetails}) {
    const prev = () => {
        prevHook();
    };
    const [isCertified,setIsCertified]  = useState(false);
    const [isHostSame,setIsHostSame]  = useState(false);
    const [hostExp,setHostExp]= useState('');
    const [formValues, setFormValues] = useState({
        cookExperience: null,
        foodCertified: false,
        isChefHost: false
    })
    const [error, setError] = useState(false);
    const [certify] = useState([
        {label: 'Yes', value: true},
        {label: 'No', value: false},
    ])
    const nextSubmit = () => {
        if (!isHostSame && (formValues.cookExperience === null || formValues.cookExperience === '')) {
            setError(true)
            return;
        }
        const stepId = 4;
        console.log(formValues)
        InputEventHook(formValues,stepId);
    }
    const handleInputChange = (name,val) => {
        if(name === 'foodCertified'){
            setIsCertified(val);
        }
        if(name === 'isChefHost'){
            setIsHostSame(val);
        }
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: val
        }));
    };
    const handleChefExp = (e) => {
        setFormValues(prevValues => ({
            ...prevValues,
            cookExperience: e.target.value
        }));
        formValues.cookExperience ? setError(false) : setError(true);
    };
    useEffect(()=>{
        if(eventInfoDetails){
            setIsCertified(eventInfoDetails?.foodCertified || false);
            setIsHostSame(eventInfoDetails?.isChefHost || false);
            setHostExp(eventInfoDetails?.hostingExperience);
            setFormValues(prev => ({
                ...prev,
                cookExperience: eventInfoDetails?.cookExperience || null,
                foodCertified: eventInfoDetails?.foodCertified || false,
                isChefHost: eventInfoDetails?.isChefHost || false,
            }))
        }
    },[eventInfoDetails])
    return (
    <div>
         
            <div id="pills-tab4">
                {/* <!-- tab4 -->  */}
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="create-event-tab">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="mb-3">
                                        <label className="form-label input-label">Is the chef officially certified ?</label>
                                        <div className="radio-btn">
                                            {
                                                certify.map((item,index)=> (
                                                    <div className="form-check" key={index}>
                                                        <input className="form-check-input" 
                                                            type="radio"
                                                            name="foodCertified"
                                                            onChange={()=> handleInputChange('foodCertified',item.value)}
                                                            value={item.value}
                                                            checked={isCertified === item.value}
                                                            id={`flexRadio${item.value}`} />
                                                        <label className="form-check-label"
                                                            htmlFor={`flexRadio${item.value}`}>
                                                            {item.label}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="mb-3">
                                        <label className="form-label input-label">Is The Host Is Same As The Chef ?</label>
                                        <div className="radio-btn">
                                            {
                                                certify.map((item,index)=> (
                                                    <div className="form-check" key={index}>
                                                        <input className="form-check-input" 
                                                            type="radio"
                                                            name="isChefHost"
                                                            onChange={()=> handleInputChange('isChefHost',item.value)}
                                                            value={item.value}
                                                            checked={isHostSame === item.value}
                                                            id={`flexRadio${item.value}`} />
                                                        <label className="form-check-label"
                                                            htmlFor={`flexRadio${item.value}`}>
                                                            {item.label}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    !isHostSame && (
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label className="form-label input-label">Chef Experience
                                                    <sup className="req">*</sup>
                                                </label>
                                                <textarea className="form-control textarea-ht"
                                                        name="cookExperience"
                                                        onChange={handleChefExp}
                                                        value={formValues.cookExperience}
                                                        placeholder="Healthy and delicious French dishes made from high quality, seasonal, and fresh ingredients"
                                                        id="floatingTextarea2">
                                                </textarea>
                                                {error && (<span className="form-error">Description is required</span>)}
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label className="form-label input-label">Host Experience</label>
                                        <textarea className="form-control textarea-ht"
                                                  placeholder="I’m a professional host. I have hosted a similar experience before and I have received professional training."
                                                  id="floatingTextarea2"
                                                  readOnly
                                                  value={hostExp}>
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mx-auto">
                    <div className="submit-btn next-btn justify-content-end">
                    <div className="submit-btn next-btn text-end">
                        <div>
                        <button  className="prev-arrow" onClick={prev}>
                            <FaArrowLeftLong />
                        </button>
                        </div>
                        <div>
                        <button className="btn" type="submit" onClick={nextSubmit}>
                            Next
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
         
    </div>
    )
}

export default ChefHost