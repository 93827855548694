import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../handpicked-events.scss";

import MultiRangeSlider from "multi-range-slider-react";
import { getAllFacilities } from "../../../services/api/events-auth.api";
function ApplyFilterModal(props) {
    const [minValue, set_minValue] = useState(25);
    const [accomodations,setAccomodations]=useState([])
const [maxValue, set_maxValue] = useState(75);
const handleInput = (e) => {
	set_minValue(e.minValue);
	set_maxValue(e.maxValue);
};

const getAccomodations = async()=>{
    const data = await getAllFacilities();
    setAccomodations(data);
}
useEffect(()=>{
    getAccomodations()
},[])
  return (
    <div>
      {/* <WishlistEvents/> */}
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="handpicked-main-block"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex justify-content-between view-menu p-0 border-0">
              <h5 className="modal-title border-0">Advance Search</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="view-menu-modal-body border-0">
            <div className="filter-popup-body-sec p-0">
              <div className="p-evnt-sec">
                <div className="title-radio-btn">
                  <p>Price $</p>
                  <MultiRangeSlider
                    min={0}
                    max={100}
                    step={5}
                    minValue={minValue}
                    maxValue={maxValue}
                    onInput={(e) => {
                      handleInput(e);
                    }}
                  />
                </div>
              </div>
              <div className="p-evnt-sec">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    Instant Booking
                  </label>
                </div>
              </div>
              <div className="p-evnt-sec evnt-type-radio-btn">
                <div className="title-radio-btn">
                  <p>Type of Event</p>
                </div>
                <div className="bcm-radio-btn-sec d-flex">
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="dinner"
                    />
                    <label className="form-check-label" htmlFor="dinner">
                      Public event
                    </label>
                  </div>
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="cookingclass"
                    />
                    <label className="form-check-label" htmlFor="cookingclass">
                      Private event
                    </label>
                  </div>
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="others"
                    />
                    <label className="form-check-label" htmlFor="others">
                      Online experiences
                    </label>
                  </div>
                </div>
              </div>
              <div className="p-evnt-sec evnt-type-radio-btn">
                <div className="title-radio-btn">
                  <p>Host Language</p>
                </div>
                <div className="bcm-radio-btn-sec d-flex">
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault2"
                      id="dinner"
                    />
                    <label className="form-check-label" htmlFor="dinner">
                      English
                    </label>
                  </div>
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault2"
                      id="cookingclass"
                    />
                    <label className="form-check-label" htmlFor="cookingclass">
                      French
                    </label>
                  </div>
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault2"
                      id="others"
                    />
                    <label className="form-check-label" htmlFor="others">
                      Italian
                    </label>
                  </div>
                </div>
              </div>
              <div className="p-evnt-sec evnt-type-radio-btn">
                <div className="title-radio-btn">
                  <p>Food Type</p>
                </div>
                <div className="bcm-radio-btn-sec d-flex">
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault3"
                      id="dinner"
                    />
                    <label className="form-check-label" htmlFor="dinner">
                      Italian
                    </label>
                  </div>
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault3"
                      id="cookingclass"
                    />
                    <label className="form-check-label" htmlFor="cookingclass">
                      French
                    </label>
                  </div>
                  <div className="form-check radio-btn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault3"
                      id="others"
                    />
                    <label className="form-check-label" htmlFor="others">
                      Turkish
                    </label>
                  </div>
                </div>
              </div>
              <div className="p-evnt-sec mb-0">
                <div className="title-radio-btn">
                  <p>Accomodation</p>
                </div>
                <div className="acm">
                  {accomodations.map((data) => (
                    <>
                      <div className="acm-sec" key={data.id}>
                        <a href="#" className="btn ">
                          <p>{data.name}</p>
                        </a>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="text-center se-re-btn-ch w-100 d-flex justify-content-center my-3">
              <Button onClick={props.onHide} className="btn search-btn">
                Search
              </Button>
              <Button onClick={props.onHide} className="btn reset-btn">
                Reset
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ApplyFilterModal;
