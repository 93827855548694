import React ,{useState,useEffect} from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup"; 
import { getEventCategory, getEventSubCategory,createHostEventById } from '../../../services/api/events-auth.api';
import Select from "react-select";
import Datetime from 'react-datetime';
import moment from 'moment';
import { FaArrowLeftLong } from "react-icons/fa6";
import Toaster from '../../../common/Toaster'

function EventInfoTab({event, refreshDetails, inActive}) {
  const [selectedFromDate, setSelectedFromDate] = useState(null); 
  const [selectedToDate, setSelectedToDate] = useState(null); 
  const [catList, setCatList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [approval] = useState([
    {label: 'Manual', value: false},
    {label: 'Auto', value: true},
  ]) 
  
  const schema = yup.object().shape({
    eventTitle: yup.string().required("Event title is required").typeError("Event title is required"),
    fromDate: yup.string().required("From date is required").typeError("From date is required"),
    toDate: yup.string().nullable(),
    eventCategoryId: yup.number().required("Category is required").typeError("Category is required"),
    eventSubCategoryId:  yup.number().nullable(),
    eventDescription: yup.string().required("Event description is required").typeError("Event description is required"),
    isAuto: yup.boolean().nullable()
  });

  const { register, handleSubmit, setValue, watch, control, clearErrors, trigger, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isAuto: event?.isAuto || false
    },
  });

  const getCategoryList = async () => {
    try {
      const data = await getEventCategory();
      const list = data.map(option => ({
        label: option.name,
        value: option.id
      }));
      setCatList(list);
    } catch (error) {
      console.error(error);
    }
  };

  const getSubCatList = async (id) => {
    try {
      const subData = await getEventSubCategory(id);
      if(subData){
        const list = subData.map(option => ({
          label: option.name,
          value: option.id
        }));
        setSubCatList(list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategory = async(val) =>{
    setValue('eventCategoryId',val);
    clearErrors('eventCategoryId');  
    await getSubCatList(val);
  }

  const handleFromDateChange = (date) => {
    if (date && moment(date).isValid()) {
      const formattedDate = date.format('YYYY-MM-DDTHH:mm:ss');
      setValue('fromDate', formattedDate);
      setSelectedFromDate(date);
      clearErrors('fromDate');  
      trigger('fromDate');     
    }
  };

  const handleToDateChange = (date) => {
    if (date && moment(date).isValid()) {
      const formattedDate = date.format('YYYY-MM-DDTHH:mm:ss');
      setValue('toDate', formattedDate);
      setSelectedToDate(date);
      clearErrors('toDate');   
      trigger('toDate');        
    }
  };

  const nextSubmit = (formData) => {
    const stepId = 1;
    createEvent(formData,stepId);
  };

  const createEvent = async(formData,stepId) => {
    const transformedData = transformFormValues(formData)
    try{
      if(event?.id){
        await createHostEventById(transformedData,event?.id,stepId);
        Toaster('Updated successfully', 'success');
        refreshDetails();
      }
    }
    catch(error){
      console.error("Error creating event", error);
    }
  }

  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
      operationType: "Add",
      path: `/${key}`,
      op: "replace",
      value: formValues[key],
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
          await Promise.all([getCategoryList()]);
      } catch (err) {
          console.error("Error fetching data:", err);
      } finally {
        if (event) {
          const from = moment(event.fromDate);
          const to = moment(event.toDate);
          setSelectedFromDate(from);
          setSelectedToDate(to);
          getSubCatList(event.eventCategoryId);
          const fields = ["eventTitle", "fromDate", "toDate","eventCategoryId","eventSubCategoryId", "eventDescription", "isAuto"];
          fields.forEach((field) => {
            if(event[field] != null){
              setValue(field, event[field])
            }
          });
        }
      }
    };
    fetchData();
  }, [event, setValue]);
  return (
    <form onSubmit={handleSubmit(nextSubmit)}>
      <div className="col-md-12 mx-auto">
        <div className="create-event-tab">
        <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label input-label">
                  Event Title
                  <sup className="req">*</sup>
                </label>
                <input
                  type="text"
                  readOnly={inActive}
                  {...register('eventTitle')}
                  className="form-control"
                  placeholder="French Cup Cake Workshop with a Masterchef in his Private Atelier"
                />
                {errors?.eventTitle && (
                  <span className="form-error">
                    {errors.eventTitle.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label input-label">
                  From Date
                  <sup className="req">*</sup>
                </label>
                <Datetime onChange={handleFromDateChange} value={selectedFromDate}  inputProps={{ disabled: inActive }}/>
                <input type="hidden" {...register('fromDate')} />
                {errors?.fromDate && (
                  <span className="form-error">{errors.fromDate.message}</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label input-label">
                  To Date
                </label>
                <Datetime onChange={handleToDateChange} value={selectedToDate} inputProps={{ disabled: inActive }}/>
                <input type="hidden" {...register('toDate')} />
                {errors?.toDate && (
                  <span className="form-error">{errors.toDate.message}</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label input-label">Is Guest Booking Approval Manual or Automatic?</label>
                <div className="radio-btn">
                  {
                    approval.map((item, index) => (
                      <div className="form-check" key={index}>
                        <input 
                          disabled={inActive}
                          className="form-check-input" 
                          type="radio"
                          name="isAuto"
                          value={item.value}
                          {...register("isAuto")} 
                          checked={watch("isAuto") === item.value} 
                          onChange={() => setValue("isAuto", item.value)} 
                          id={`flexRadio${index}`} 
                        />
                        <label className="form-check-label" htmlFor={`flexRadio${index}`}>
                          {item.label}
                        </label>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label input-label">
                  Food Categories
                  <sup className="req">*</sup>
                </label>
                <div className="">
                  {catList?.length > 0 && (
                    <Controller
                      name="eventCategoryId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          isDisabled={inActive}
                          {...field}
                          options={catList}
                          value={catList.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(selectedOption) => handleCategory(selectedOption.value)}
                        />
                      )}
                    />
                  )}
                </div>
                {errors?.eventCategoryId && (
                  <span className="form-error">
                    {errors.eventCategoryId.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label input-label">
                  Food Sub Categories
                  <sup className="req">*</sup>
                </label>
                <div>
                    <Controller
                      name="eventSubCategoryId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          isDisabled={inActive}
                          {...field}
                          options={subCatList}
                          value={subCatList.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption?.value)
                          }
                        />
                      )}
                    />
                </div>
                {errors?.eventSubCategoryId && (
                  <span className="form-error">
                    {errors.eventSubCategoryId.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label input-label">
                  Event Description
                  <sup className="req">*</sup>
                </label>
                <textarea
                  readOnly={inActive}
                  className="form-control textarea-ht"
                  {...register('eventDescription')}
                  placeholder="Jean-Yves is a professional chef..."
                  id="floatingTextarea2"
                ></textarea>
                {errors?.eventDescription && (
                  <span className="form-error">
                    {errors.eventDescription.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="submit-btn next-btn justify-content-end">
              <div className="submit-btn next-btn text-end">
                <div>
                  <button className="btn" type="submit" disabled={inActive}>Save</button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </form>
  )
}

export default EventInfoTab