import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {createEventMenu} from '../../../../services/api/events-auth.api';
import Toaster from '../../../../common/Toaster';
import {getEventId} from '../../../../util/auth';

function CreateMenuModal(props) {
  const [menuPayload,setMenuPayload] = useState({
    eventId: getEventId(),
    itemName: "",
    itemDescription: "",
    isActive: true
  });
  const handleChange = (e) => {
    const {name,value} = e.target;
    setMenuPayload(prev => ({
      ...prev,
      [name]: value
    }))
  }
  const handleCreate = async() => {
    if(menuPayload.itemName === '' && menuPayload.itemDescription === ''){
      Toaster("Menu name and description is required", "error");
      return ;
    }
    try{
      const res = await createEventMenu(menuPayload);
      props.onHide();
      Toaster("Menu added successfully", "success");
      setMenuPayload(prev => ({
        ...prev,
        itemName: "",
        itemDescription: "",
      }))
    }catch (error){
      Toaster("Failed", "error");
    }
  }
  return (
    <div>
     
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="create-event-block-main"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex justify-content-between view-menu p-0 border-0">
              <h5 className="modal-title border-0">Add Food Menus</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label input-label">Menu name</label>
                <input
                  type="text"
                  name="itemName"
                  className="form-control"
                  placeholder="Flame and sea"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label input-label">Menu description</label>
                <textarea
                  className="form-control textarea-ch"
                   name="itemDescription"
                  onChange={handleChange}
                  placeholder="Flambéed lobster tail with rum Sautéed jasmine rice linguine Oyster sauce and grilled truffle slices with semi-salted butter from Bretagne Garlic and lemongrass crumble"
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center submit-btn w-100 d-flex justify-content-center create-event-modal-btn mt-0">
            <Button onClick={handleCreate} className="btn" type="submit">
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateMenuModal;
