import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import GalleryChat from '../../galleryChat/GalleryChat'

const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const formatDate = (date) => {
    if (!date || !date.seconds) return "Unknown";
    return new Date(date.seconds * 1000).toLocaleTimeString();
  };

  return (
    <div
      ref={ref}
      className={`mb-2 d-flex justify-content-end message ${message.senderId === currentUser.uid && "owner"}`}
    >
      
      <div className="messageInfo cht-body-txt text-right">
      <div className="cht-body-txt text-right">
      <div className="messageContent chat-list">
        <p><span>{message.text}</span></p>
        <div className="msg-img">
        {/* {message.img && <img src={message.img} alt="" />} */}
        {message.img && <GalleryChat event={message}/>}
        </div>
      </div>
      <p className="cht-time">{formatDate(message.date)}</p>
      </div>
  
      </div>
      <div className="ad-profile">
        <img
          src={
            message.senderId === currentUser.uid
              ? currentUser.photoURL
              : data.user.photoURL
          }
          alt=""
        />
        </div>
 
    </div>
  );
};

export default Message;
