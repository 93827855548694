import { Navigate } from 'react-router-dom';
import {FAQ,ABOUT_US,CONTACT_US,EVENTS,PRIVACY_POLICY,TERMS_AND_CONDITION, HANDPICKED_EVENTS, HOW_IT_WORKS} from '../const/route-url.const';
import App from '../App'
import Faq from '../components/siteInfo/faq/FAQ';
import AboutUs from '../components/siteInfo/aboutUs/AboutUs';
import ContactUs from '../components/siteInfo/contactUs/ContactUs';
import Events from '../components/events/Events';
import PrivacyPolicy from '../components/siteInfo/privacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../components/siteInfo/termsAndConditions/TermsAndConditions';
import HandPickedEvents from '../components/handPickedEvents/HandPickedEvents';
import MapSearch from '../components/MapSearch';
import HowItWorks from '../components/siteInfo/howItWorks/HowItWorks';
 
 const infoRoutes = [
   {
    path: '',
    element : <App />,
    children: [
      {
        path: FAQ,
        element : <Faq/>
       },
       {
        path: ABOUT_US,
        element : <AboutUs/>
       },
       {
        path: HOW_IT_WORKS,
        element : <HowItWorks/>
       },
       {
        path: CONTACT_US,
        element : <ContactUs/>
       },
       {
        path: HANDPICKED_EVENTS,
        element : <HandPickedEvents />
       },
       {
        path: PRIVACY_POLICY,
        element : <PrivacyPolicy/>
       },
       {
        path: TERMS_AND_CONDITION,
        element : <TermsAndConditions/>
       },
       {
        path: 'map',
        element : <MapSearch />
       },
    ]
   },
   {
    path: '*',
    element: <Navigate to="/" replace />
  }
  ];
 
  export default infoRoutes;