import React, { useEffect, useState } from "react";
import { getfaqs } from "../../../services/api/site-info.api";
import banner from "../../../assets/image/new-banner-video.mp4";
import img1 from "../../../assets/image/logo.png";
import img2 from "../../../assets/image/breadcrumb-image.png";
import img3 from "../../../assets/image/login-pattern2.png";
import img4 from "../../../assets/image/faq2-img.png";
import img5 from "../../../assets/image/faq-bg.png";
import img6 from "../../../assets/image/faq1-img.png";
import img7 from "../../../assets/image/footer-logo.png";
import Accordion from 'react-bootstrap/Accordion';
import '../faq/FAQ.scss'
function FAQ() {
  const [faqs, setFaqs] = useState();
  useEffect(() => {
    getFaqData();
  }, []);

  const getFaqData = async () => {
    try {
      const faqData = await getfaqs();
      setFaqs(faqData);
    } catch (error) {

    }
  };

  return (
    <>
     <div className="FAQ-wrapper">
     <div className="breadcrumb-image">
            {/* <img src={img2} alt="image"/> */}
            <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>FAQ</h4>
          </div>
          <div>
            <p>Search our knowledge base for answers to common</p>
          </div>
        </div>
      </div>
      <div className="pattern-row">
        <div className="col-12">
          <div className="pattern-2">
            <img src={img3} alt="image" />
          </div>
        </div>
      </div>
      <div className="position-relative overflow-hidden p-s faq-bg">
        <div className="faq-bg-overlay"></div>
        <div className="faq-bg-content">
        <div className="shape-1"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 my-auto">
              <div className="row">
                <div className="col-lg-9">
                  <div className="faq-bg h-100">
                    <div className="faq-img">
                      <div className="faq2-img">
                        <img src={img4} alt="image" />
                        <div className="faq-bg-img">
                          <img src={img5} alt="image" />
                        </div>
                      </div>
                      <div className="faq1-img">
                        <img src={img6} alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <Accordion className='faq-accordion h-100'>
                {
                    faqs?.length && faqs?.map((faq) =>{
                        return (
                        <Accordion.Item eventKey={faq?.id}  key={faq?.id}>
                            <Accordion.Header>{faq?.question}</Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                              <p dangerouslySetInnerHTML={{ __html: faq?.answer }} className="mb-0"></p>
                            </Accordion.Body>
                        </Accordion.Item>
                        )
                    })
                }
                </Accordion>
            </div>
          </div>
        </div>
        <div className="shapefaq"></div>
        </div>
       
      </div>
     </div>
     
    </>
  );
}

export default FAQ;
