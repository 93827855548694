import {axiosInstance} from '../../axios';
import { EVENTS } from '../../const/api-url.const';

export const getEvents = async() =>{
    return await axiosInstance.get(`${EVENTS.GET_EVENTS}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getFilterEvents = async(searchParams) =>{
    const params = new URLSearchParams();
    if(searchParams.EventCategory){
        searchParams.EventCategory.forEach(category => {
            params.append('EventCategory', category);
          });
    }
    params.append('sort', searchParams.Sort);
    params.append('pageId', searchParams.PageId);
    params.append('pageSize', searchParams.PageSize);
 
    return await axiosInstance.get(`${EVENTS.GET_EVENTS}?${params}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getEventById = async(id) =>{
    return await axiosInstance.get(`${EVENTS.GET_EVENT_BY_ID}?eventId=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getEventsByFilter = async(params) =>{
    const queryString = new URLSearchParams(params).toString();
    return await axiosInstance.get(`${EVENTS.GET_EVENTS}?${queryString}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getMenuEvent = async(eventId) =>{
    return await axiosInstance.get(`${EVENTS.GET_MENU_EVENTS}?eventId=${eventId}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getFacilityEvent = async(eventId) =>{
    return await axiosInstance.get(`${EVENTS.GET_FACILITY_EVENTS}?eventId=${eventId}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getEventCategory = async(id) =>{
    return await axiosInstance.get(`${EVENTS.GET_EVENT_CATEGORY}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getEventSubCategory = async(id) =>{
    return await axiosInstance.get(`${EVENTS.GET_EVENT_SUB_CATEGORY}?id=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getEventReviews = async(data) =>{
    return await axiosInstance.post(`${EVENTS.GET_EVENT_REVIEWS}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getRelatedEvents = async(params) =>{
    const queryString = new URLSearchParams(params).toString();
    return await axiosInstance.get(`${EVENTS.GET_RELATED_EVENTS}?${queryString}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getHostEvents = async(params) =>{
    const queryString = new URLSearchParams(params).toString();
    return await axiosInstance.get(`${EVENTS.GET_HOST_EVENTS}?${queryString}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getUpcomingEvents = async() =>{
    return await axiosInstance.get(`${EVENTS.GET_UPCOMING_EVENTS}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const createHostEvent = async(data,stepId) =>{
    return await axiosInstance.patch(`${EVENTS.CREATE_EVENT}?stepId=${stepId}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const createHostEventById = async(data,eventId,stepId) =>{
    return await axiosInstance.patch(`${EVENTS.CREATE_EVENT}?eventId=${eventId}&stepId=${stepId}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const createEventMenu = async(data) =>{
    return await axiosInstance.post(`${EVENTS.CREATE_MENU}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const deleteEventMenu = async(id) =>{
    return await axiosInstance.delete(`${EVENTS.DELETE_MENU}?id=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getAllFacilities = async() =>{
    return await axiosInstance.get(`${EVENTS.GET_ALL_FACILITIES}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const createEventFacility = async(data,eventId) =>{
    return await axiosInstance.post(`${EVENTS.CREATE_EVENT_FACILITY}?eventId=${eventId}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const uploadEventDoc = async(data,eventId) =>{
    return await axiosInstance.put(`${EVENTS.DOC_UPLOAD}?eventId=${eventId}`,data).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const deleteEvent = async(id) =>{
    return await axiosInstance.delete(`${EVENTS.DELETE_EVENT}?eventId=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const deleteEventDoc = async(id) =>{
    return await axiosInstance.delete(`${EVENTS.DELETE_EVENT_DOC}?id=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getSearchEvents = async(params) =>{
    const queryString = new URLSearchParams(params).toString();
    return await axiosInstance.get(`${EVENTS.SEARCH_EVENTS}?${queryString}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getBookingEvents = async(id) =>{
    return await axiosInstance.get(`${EVENTS.BOOKING_EVENTS}?eventId=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const approveUserByHost = async(id) =>{
    return await axiosInstance.post(`${EVENTS.APPROVE_EVENTS_USER}?id=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const rejectUserByHost = async(id) =>{
    return await axiosInstance.post(`${EVENTS.REJECT_EVENTS_USER}?id=${id}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

