import React from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Toaster from '../../../common/Toaster';
import { deleteEvent } from "../../../services/api/events-auth.api";
import "../deleteEventModel/delete-event.scss";

function DeleteEventModel(props) {
  const deleteEventData = async() => {
    try{
      const res = await deleteEvent(props.eventDelete);
      Toaster("Event deleted Successfully","success");
      props.onConfirm();
    }catch (error){
      Toaster("Failed", "error");
    }
  }
  return (
    <>
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="row delete-section">
              <div className="col-lg-12">
                  <div className="delete-text">
                    <span>
                    <FaCheck />
                    </span>
                      <p>Are you sure want to delete !</p>
                  </div>
              </div>
              <div className="col-12">
              <div className="text-center submit-btn w-100 d-flex mt-2 justify-content-center create-event-modal-btn">
                <Button onClick={()=> props.onHide()} className="delete-btn">
                      cancel
                </Button>
                <Button onClick={deleteEventData} className="btn">
                      Delete
                </Button>
              </div>
              </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default DeleteEventModel;
