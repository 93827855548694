import React,{useEffect,useState} from 'react'
import { MdStar,MdStarBorder } from "react-icons/md";

import ratingProfile from "../../../assets/image/rating-profile.png";
import calender from "../../../assets/image/calender.png";
import Select from 'react-select';
import {getEventReviews} from '../../../services/api/events-auth.api';
import { useParams } from "react-router-dom";

function Reviews() {
    const { id } = useParams();
    const ReviewItems =[
        {label: 'Oldest first',value: 'oldest'},
        {label: 'Highest rating',value: 'highest'},
        {label: 'Lowest rating',value: 'lowest'}
    ]
    const [reviewPayload,setReviewPayload] = useState(
        {
            eventId: id,
            sort: "",
            pageId: 1,
            pageSize: 10
          }
    );
    const maxRating = 5;
    const [reviews,setReviews] = useState([]);
    const transformDate = (dateString) =>{
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {month: 'long',year: 'numeric',});
        return formattedDate;
    }
    const handleFilter = (option) =>{
        setReviewPayload(prev=> ({
            ...prev,
            sort: option.value
        }))
    }
    const getReviews = async() =>{
        try{
            const res = await getEventReviews(reviewPayload);
            setReviews(res);
        }catch (error){
        }
    }
    const capitalize = (str) => {
        if (!str || str.length < 2) return str.toUpperCase(); // handle cases where the string is too short
        return str.substring(0, 2).toUpperCase();
      };
    useEffect(()=>{
        getReviews();
    },[id, reviewPayload.sort])
  return (
    <>
     <div className="position-relative">
        <div className="container">
            <div className="row g-3">
                <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center wht-expt flex-wrap">
                        <div>
                        <h4>What to expect</h4>
                        </div>
                        {
                            reviews.length > 0 && (
                                <div className="d-flex flex-wrap">
                                    <div>
                                        <div className="d-flex justify-content-between expt-revw align-items-center">
                                        <div className="expt-revw-num mt-auto">
                                            <p className="mb-0">5</p>
                                        </div>
                                        <div className="mt-auto revw-rat">
                                            <p className="mb-0">Out of 5</p>
                                            <p className="mb-0">(94 reviews)</p>
                                        </div>
                                        <div className="mt-auto">
                                            <MdStar color="white" />
                                            <MdStar color="white" />
                                            <MdStar color="white" />
                                            <MdStar color="white" />
                                            <MdStar color="white" />
                                        </div>
                                        </div>
                                    </div>
                                    <div className="review-dropdown">
                                        <Select
                                            options={ReviewItems} 
                                            placeholder="Newest"
                                            onChange={(selectedOption) => handleFilter(selectedOption)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                    {
                        reviews.length > 0 
                        ? reviews.map((item,index)=> (
                            <div className="col-md-12" key={index}>
                            <div className="see-menu-sec">
                                <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <div className="rating-profile rating2-profile">
                                    {/* <img src={ratingProfile} alt="image" /> */}
                                    <div className="blank-image-text">
                                        <span>{capitalize(item.userName)}</span>
                                    </div>
                                    </div>
                                    <div className="test-ratng ms-3">
                                    <p className="mb-0">{item.userName}</p>
                                    <div className="rating-stars">
                                        {[...Array(maxRating)].map((_, index) => (
                                            <span key={index}>
                                            {index < item.totalReviews ? (
                                                <MdStar color="orange" size={24} /> 
                                            ) : (
                                                <MdStarBorder color="orange" size={24} /> 
                                            )}
                                            </span>
                                        ))}
                                    </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-auto ratng-date align-items-center">
                                    <div className="evnt-feat-img">
                                    <img src={calender} alt="image" />
                                    </div>
                                    <div className="mt-auto">
                                    <p>{transformDate(item.createdDate)}</p>
                                    </div>
                                </div>
                                </div>
                                <hr />
                                <div>
                                <p>{item.reiviewsDescripition}</p>
                                </div>
                            </div>
                            </div>
                        ))
                        : (
                            <p className='not-found'>No Reviews found</p>
                        )
                    }
            </div>
        </div>
     </div>
    </>
  )
}

export default Reviews