import React, { useState, useEffect } from 'react'
import img1 from "../../assets/image/becmehost2-img.png";
import { FaArrowLeft } from "react-icons/fa";
import { getHostPreference } from '../../services/api/site-info.api';
import '../host/host.scss'

function TableHost({ prevHook, InputEventHook, hostData }) {
    const [preferenceId,setPreferenceId] = useState(1);
    const [preference,setPreference] = useState(null);
    
    const prev = () => {
        prevHook();
    };
    const handleSubmit = () => {
        const stepId = 2;
        const selectedOption = {preferenceTypeId : preferenceId}
        InputEventHook(selectedOption,stepId);
    }
    useEffect(()=>{
     const getData = async () => {
        try {
         const response = await getHostPreference();
         setPreference(response);
         if(hostData){
            setPreferenceId(hostData.preferenceTypeId || 1);
         }
        }catch (error){
        }
     }
     getData();
    },[])
    return (
        <div className="host-wrapper">
            <div className="become-host-sec">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6 col-md-12 my-auto">
                            <div className="becomehost-img">
                                <img src={img1} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="sign-in-form becomehost-txt host-2 shadow">
                                <div>
                                    <h3>Why would you like to become an meet@table host?</h3>
                                    <p>It is very important for us to get to know you better, tell us a bit more about your motivations for becoming a host.</p>
                                    <h4>Please read carefully every option and choose the one that represents you best.</h4>
                                </div>
                                <div className="bcm-radio-btn-sec">
                                    {
                                        preference?.length && preference.map((list) => 
                                            (
                                            <div className="form-check radio-btn" key={list.id}>
                                                <input className="form-check-input" 
                                                        type="radio" name="flexRadioDefault"
                                                        id={`flexRadio${list.id}`}
                                                        checked={preferenceId === list.id}
                                                        onChange={() => setPreferenceId(list.id)}
                                                        value={list.id}/>
                                                <label className="form-check-label" htmlFor={`flexRadio${list.id}`}>
                                                    {list.preference}
                                                </label>
                                            </div>
                                        ))
                                    }
                                    <div className="submit-btn next-btn text-end">
                                        <div className='next-btn1' onClick={prev}>
                                                <FaArrowLeft />
                                        </div>
                                        <div><button className="btn" type="submit" onClick={handleSubmit}>Next</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableHost