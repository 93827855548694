import React from 'react'
import { useEffect, useState } from "react";
import { getaboutus } from "../../../services/api/site-info.api";
import banner from "../../../assets/image/about-video.mp4";
import img3 from '../../../assets/image/login-pattern2.png'
import '../aboutUs/about.scss';

function AboutUs() {
const [aboutUs,setAboutUs]=useState('')
const [activeIndex, setActiveIndex] = useState(0);
const [isFirstActive, setIsFirstActive] = useState(true);
const slides = [
  {
    quote: "What is the city, but the people?",
    author: "Shakespeare",
  },
  {
    quote: "Me mesa es tu mesa!",
    author: "Anonymous",
  },
  {
    quote: "People who love to eat are always the best people.",
    author: "Julia Child",
  },
];
const slides2 = [
  {
    quote: "Bye-bye awkward networking and swipe-right dating…",
  },
  {
    quote: "Hello real connections and meaningful hangouts!",
  }
];
useEffect(() => {
  const interval = setInterval(() => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  }, 4000); 
  return () => clearInterval(interval); 
}, [slides.length]);

useEffect(() => {
    getAboutData();
}, []);

const getAboutData = async () => {
    try {
        const aboutData = await getaboutus();
        setAboutUs(aboutData);
    } 
    catch (error) {}
};

useEffect(() => {
  const interval = setInterval(() => {
    setIsFirstActive((prev) => !prev); 
  }, 2000);
  return () => clearInterval(interval); 
}, [aboutUs]);

useEffect(() => {
  const firstText = document.querySelector(".meet-us-first-text");
  const secondText = document.querySelector(".meet-us-second-text");
     if(firstText && secondText){
      if (isFirstActive) {
        firstText.classList.add("active");
        secondText.classList.remove("active");
      } else {
        firstText.classList.remove("active");
        secondText.classList.add("active");
      }
     }
}, [isFirstActive]);

  return (
      <>
       <div className="about-wrapper">
       <div className="breadcrumb-image">
            <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
            <div className="breadcrumb-overlay"></div>
            <div className="breadcrumb-title">
                <div className="breadcrumb-header">
                    <h4>Meet Us</h4>
                </div>
                <div className="slider">
                  {slides.map((slide, index) => (
                    <div
                      key={index}
                      className={`slide ${index === activeIndex ? "active" : "inactive"}`}
                    >
                      <div className="blockquote">
                        <h3>
                          <span>{slide.quote}</span>
                        </h3>
                        <h4><em>- {slide.author}</em></h4>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
        </div>
        <div className="pattern-row">
            <div className="col-12">
                <div className="pattern-2">
                    <img src={img3} alt="image"/>
                </div>
            </div>
        </div>
        <div className="position-relative overflow-hidden py-5 about-bg">
          <div className='about-bg-overlay'></div>
          <div className='about-bg-content'>
          <div className="shape-1"></div>
            <div dangerouslySetInnerHTML={{ __html: aboutUs[0]?.text }} className="mb-0">
            </div>
            <div className="shapefaq"></div>
          </div>

        </div>
       </div>
        
     </>
  )
}

export default AboutUs