import React, { useEffect, useState } from "react";
import img5 from "../../../assets/image/evnt1-hm.png";
import img6 from "../../../assets/image/faq-bg.png";
import img7 from "../../../assets/image/evnt2-hm.png";
import { useNavigate } from "react-router-dom";
import { getUpcomingEvents } from "../../../services/api/events-auth.api";
import "../events-near/eventsNear.scss";
import EventsSlider from "./EventsSlider";

function EventsNear() {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};
  const getAllEvents =  async() => {
    try {
      const res = await getUpcomingEvents();
      const formattedRes = chunkArray(res, 3);
       setEvents(formattedRes);
    } catch (error) {
      
    }
  };
  useEffect(() => {
    getAllEvents();
  }, []);
  return (
    <>
      <div className="eventsNear-wrapper">
        <div className="en position-relative evnt-hm">
          <div className="shape-2"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="en-center text-center">
                  <h5>Great way to experience the authentic flavors</h5>
                  <h3>Events near you</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi sint natus ratione! Ab cum ex et. Repudiandae fugiat
                    error praesentium porro atque, blanditiis.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-md-12 my-auto">
                <div>
                    <EventsSlider  eventItem={events}/>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-lg-9 ms-auto">
                    <div className="faq-bg h-100">
                      <div className="faq-img">
                        <div className="faq2-img">
                          <img src={img5} alt="image" />
                          <div className="faq-bg-img">
                            <img src={img6} alt="image" />
                          </div>
                        </div>
                        <div className="faq1-img">
                          <img src={img7} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventsNear;
