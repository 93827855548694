// Input.js
import { arrayUnion, doc, Timestamp, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { db, storage } from "../../../firebase";
import './input.scss'
import { FaPaperPlane } from "react-icons/fa6";
const Input = ({ chatId, currentUser }) => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);

  const handleSend = async () => {
    if (text.trim() === "" && !img) return;

    if (img) {
      const storageRef = ref(storage, uuid());
      const uploadTask = uploadBytesResumable(storageRef, img);

      uploadTask.on(
        (error) => {
          // Handle Error
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await updateDoc(doc(db, "chats", chatId), {
            messages: arrayUnion({
              id: uuid(),
              text,
              senderId: currentUser.uid,
              date: Timestamp.now(),
              img: downloadURL,
            }),
          });
        }
      );
    } else {
      await updateDoc(doc(db, "chats", chatId), {
        messages: arrayUnion({
          id: uuid(),
          text,
          senderId: currentUser.uid,
          date: Timestamp.now(),
        }),
      });
    }

    setText("");
    setImg(null);
  };

  return (
    <div className="input send-msg p-0">
      <input
        className="input-field"
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
       <button className="btn send send-icon send-icon-ch" onClick={handleSend}>  <span><FaPaperPlane/></span></button>
    </div>


  );
};

export default Input;
