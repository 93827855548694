import React, { useState, useEffect } from "react";
import logo from "../../assets/image/logo-dark.svg";
import img1 from "../../assets/image/login-pattern2.png";
import img2 from "../../assets/image/profile.png";
import img3 from "../../assets/image/edit-profile.png";
import img4 from "../../assets/image/chat-icon-new.png";
import img5 from "../../assets/image/account.png";
import img6 from "../../assets/image/logout.png";
import img7 from "../../assets/image/calender.png";
import logowhite from '../../assets/image/logo-light.svg';

import { IoMenuSharp } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { removeAccessToken, removeHostId, getEmail } from "../../util/auth";
import {
  FAQ,
  ABOUT_US,
  LOGIN,
  REGISTER,
  UPDATE_PROFILE,
  USER,
  MY_WISHLISTS,
  ACCOUNT_SETTINGS,
  ALL_EVENTS,
  HANDPICKED_EVENTS,
  HOW_IT_WORKS,
  CHAT
} from "../../const/route-url.const";
import { getAccessToken, getHostId,removeEmail } from "../../util/auth";
import { FaRegBell } from "react-icons/fa";
import { getHostDetails, getProfile } from "../../services/api/user-auth.api";
import { useSelector } from 'react-redux';
import '../../index.scss'
import '../header/header.scss';
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import Toaster from "../../common/Toaster";


function Header() {
  const booleanValue = useSelector((state) => state.tokenRefresh.value);
  const navigate = useNavigate();
  const [userName, setUserName] = useState([]);
  const [url, setUrl] = useState();
  const [hostData,setHostData] = useState(null);
  const location = useLocation();
  const [token, setToken] = useState("");
  const [hostId, setHostId] = useState(getHostId());
  const [notify,setNotify] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const userEmail = useSelector((selector)=>selector?.userEmail?.value);
  const showStyle=['about-us','contact-us','how-it-works','faq','privacy-policy','terms-and-conditions']
  const handleClick = () => {
    setIsActive(!isActive); 
  };

  useEffect(() => {
    authSetUp();
    let path = location.pathname.replace(/^\//, "");
    console.log(path,'path')
    setUrl(path);
    if (hostId) {
      getHost();
    }
    if (booleanValue) {
      authSetUp();
      const hostIdVal = getHostId();
      if(hostIdVal){
        setHostId(hostIdVal);
      }
    }
    
  }, [location, booleanValue]);

  useEffect(()=>{
    const handleScroll = () => {
      if(window.scrollY > 100){
        document.body.classList.add("header-scroll")
      }else{
        document.body.classList.remove('header-scroll')
      }
    };
 
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[])
  
  const authSetUp = async() => {
    const tokVal = await getAccessToken();
    if (tokVal) {
      setToken(tokVal);
      getUser();
    }
  }
  const logout = async () => {
    await removeAccessToken();
    await removeHostId();
    await setToken("");
    await removeEmail()
    navigate("/");
  };

  const getUser = async () => {
    const user = await getProfile();
    setUserName(user.firstName + ' ' + user.lastName);
  };
  const getHost = async () => {
    try {
      const host = await getHostDetails();
      setHostData(host)
    } catch (error) { }
  };

  const loginFirebaseHandler = async () =>{
    try {
      let mail = getEmail();
      mail = userEmail && userEmail !== "" ? userEmail : mail;
      await signInWithEmailAndPassword(auth, mail, mail);
      navigate(`${USER}/${CHAT}`)
    } catch (error) {
        Toaster('Please login with your profile','error');
    }
  }
  return (
    <>
      <div className="position-relative  header-wrapper">
        <div className={`nav-menu ${showStyle.includes(url) || url === '' ? 'hm-nav-menu' : ''}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 header-wrapper-content">
                <nav className="navbar navbar-expand-lg p-0">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={handleClick}
                  >
                    <IoMenuSharp />
                  </button>
                  <div
                    className={isActive ? 'navbar-collapse' : 'collapse navbar-collapse'}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mb-2 mb-lg-0 navlist">
                      <li className="nav-item">
                        <Link
                          className={`${url == ABOUT_US ? "active" : ""
                            } nav-link`}
                          to={ABOUT_US}
                        >
                          Meet Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${url == HOW_IT_WORKS ? "active" : ""
                            } nav-link`}
                          to={HOW_IT_WORKS}
                        >
                          How It Works
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${url == HANDPICKED_EVENTS ? "active" : ""
                            } nav-link`}
                          to={HANDPICKED_EVENTS}
                        >
                          Events
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${url == FAQ ? "active" : ""} nav-link`}
                          to={FAQ}
                        >
                          FAQ
                        </Link>
                      </li>
                    </ul>
                    <div className="logo-image mx-auto">
                    <Link className="navbar-brand" to="/">
                      {showStyle.includes(url) ||  url === ''
                          ? <img src={logowhite} alt="image" />
                          : <img src={logo} alt="image" />
                      }
                    </Link>
                  </div>
                    <form className="d-flex nav-btn flex-wrap align-items-center">

                      {token ? (
                        <>
                         {hostId && hostData?.verificationStatus == 3 && (
                               <button className="btn host-btn" type="submit">
                               <Link to="/host">Resume a application</Link>
                              </button>
                            )}
                            {hostData?.verificationStatus == 0 && (
                               <button className="btn host-btn" type="submit">
                               <Link to="/host">Become a host</Link>
                               </button>
                            )}
                          <div className="position-relative">
                            <div className="notify-icon position-relative" onClick={()=> setNotify(!notify)}>
                              <FaRegBell />
                              <div className="notify">
                                <p>3</p>
                              </div>
                            </div>
                            <div className="notify-content">
                            {
                              notify && (
                                <ul className="notify-dropdown">
                                <li>
                                    <div>
                                        <p className="notify-dropdown-title">Notifications</p>
                                    </div>
                                </li>
                               <li>
                                <div className="notify-list">
                                    <p>We received your application and we can’t wait to review it!</p>
                                    <span>6 days ago</span>
                                </div>
                               </li>
                               <li>
                                <div className="notify-list">
                                    <p>We received your application and we can’t wait to review it!</p>
                                    <span>10 days ago</span>
                                </div>
                               </li>
                               <li>
                                <div className="notify-list">
                                    <p>We received your application and we can’t wait to review it!</p>
                                    <span>12 days ago</span>
                                </div>
                               </li>
                               </ul>
                              )
                            }
                            </div>  
                          </div>
                          <Dropdown className="profile-dropdown">
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="tab-loc-btn"
                            >
                              <div className="profile">
                                <img src={img2} alt="image" />
                              </div>
                              {userName ? <span>{userName}</span> : <span>John</span>}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {
                                hostData?.id && (
                                  <Dropdown.Item
                                  className="profile-dropdown profile-list"
                                  onClick={() => navigate(`${ALL_EVENTS}`)}
                                >
                                  <span className="profile-img">
                                    <img src={img7} alt="image" />
                                  </span>
                                  <span className="ms-1">Events</span>
                                </Dropdown.Item>
                                )
                              }
                              <Dropdown.Item
                                className="profile-dropdown profile-list"
                                onClick={() => navigate(`${USER}/${UPDATE_PROFILE}`)}
                              >
                                <span className="profile-img">
                                  <img src={img3} alt="image" />
                                </span>
                                <span className="ms-1">Edit my profile</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="profile-dropdown profile-list"
                                onClick={loginFirebaseHandler}
                              >
                                <span className="profile-img">
                                  <img src={img4} alt="image" />
                                </span>
                                <span className="ms-1">Chat</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="profile-dropdown profile-list"
                                onClick={() => navigate(`${`${USER}/${ACCOUNT_SETTINGS}`}`)}
                              >
                                <span className="profile-img">
                                  <img src={img5} alt="image" />
                                </span>
                                <span className="ms-1">My Account</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="profile-dropdown profile-list"
                                onClick={logout}
                              >
                                <span className="profile-img">
                                  <img src={img6} alt="image" />
                                </span>
                                <span className="ms-1">Logout</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="tab-loc-btn lang-btn"
                            >
                              EN/USD
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="lang-dropdown">
                              <div className="row">
                                <div className="col-lg-5 col-md-5">
                                  <div className="lang-check">
                                    <p>Language</p>
                                    <div>
                                      <div className="form-check">
                                        <input className="form-check-input"
                                          type="checkbox" value=""
                                          id="flexCheckDefault" checked />
                                        <label className="form-check-label"
                                          htmlFor="flexCheckDefault">
                                          English
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input"
                                          type="checkbox" value=""
                                          id="flexCheckDefault" />
                                        <label className="form-check-label"
                                          htmlFor="flexCheckDefault">
                                          Polish
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-7 col-md-7">
                                  <div className="lang-check">
                                    <p>Currency</p>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div>
                                          <div className="form-check">
                                            <input className="form-check-input"
                                              type="checkbox" value=""
                                              id="flexCheckChecked" checked />
                                            <label className="form-check-label"
                                              htmlFor="flexCheckChecked">
                                              INR
                                            </label>
                                          </div>
                                          <div className="form-check">
                                            <input className="form-check-input"
                                              type="checkbox" value=""
                                              id="flexCheckChecked" />
                                            <label className="form-check-label"
                                              htmlFor="flexCheckChecked">
                                              EUR € </label>
                                          </div>
                                          <div className="form-check">
                                            <input className="form-check-input"
                                              type="checkbox" value=""
                                              id="flexCheckChecked" />
                                            <label className="form-check-label"
                                              htmlFor="flexCheckChecked">
                                              USD $</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown> */}
                        </>
                      ) : (
                        <>
                          <Link to="/host" className="btn host-btn" type="button">Become a host</Link>
                          <Link to={LOGIN} className="btn me-2 host-btn" type="button">Login</Link>
                          <Link to={REGISTER} className="btn active" type="button">Signup</Link>
                        </>
                      )}
                    </form>
                  </div>
                </nav>
                <div className="logo-image logo-res">
                    <Link className="navbar-brand logo-white" to="/">
                    <img src={logowhite} alt="image" />
                    </Link>
                    <Link className="navbar-brand logo-dark" to="/">
                      <img src={logo} alt="image" />
                    </Link>
                  </div>
              </div>
            </div>
          </div>
        </div>

        {/* banner background */}
        <div className={`pattern-row ${url === '' || url === 'host' || url === 'event' ?  'd-block' : 'd-none'}`}>
          <div className="col-12">
            <div className="pattern-2">
              <img src={img1} alt="image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;