import { Navigate } from "react-router-dom";
import { UPDATE_PROFILE, USER,MY_WISHLISTS, ACCOUNT_SETTINGS, WISHLISTS_EVENTS,CHAT, NOTIFICATIONS } from "../const/route-url.const";
import App from "../App";
import User from "../components/user/User";
import UpdateProfile from "../components/user/updateProfile/UpdateProfile";
import MyWishlists from "../components/user/myWishlists/MyWishlists";
import AccountSettings from "../components/user/accountSettings/AccountSettings";
import ChatHome from "../components/user/chatApp/ChatHome";
import Notifications from "../components/user/notifications/Notifications";
import WishlistEvents from "../components/user/myWishlists/WishlistEvents";

const userRoutes = [
  {
    path: "",
    element: <App />,
    children: [
      {
        path: USER,
        element: <User />,
        children: [
          {
            path: UPDATE_PROFILE,
            element: <UpdateProfile />,
          },
          {
            path: MY_WISHLISTS,
            element: <WishlistEvents />,
          },
          {
            path: ACCOUNT_SETTINGS,
            element: <AccountSettings/>,
          },
          {
            path: CHAT,
            element: <ChatHome />,
          },
          {
            path: NOTIFICATIONS,
            element: <Notifications />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export default userRoutes;
