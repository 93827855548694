import Sidebar from './Sidebar'
import Chat from './Chat'
import './ChatHome.css'


const ChatHome = () => {
  return (
   
    <div className="profile-tab bx-shadow my-5 mx-0 p-0 border-0 chat-tab">
      <div className="profile-info bx-shadow inbox-tab mt-0">
        <div className="chat-box-tab">
          <div className="cht-left">
            <Sidebar />
          </div>
          <div className="cht-right">
          <Chat />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatHome