import React, { useState, useRef } from 'react';
import { GoogleMap, LoadScript, Autocomplete, Marker } from '@react-google-maps/api';
 
const mapContainerStyle = {
  height: "400px",
  width: "800px"
};
 
const center = {
  lat: 37.7749, // Set your initial center coordinates
  lng: -122.4194
};
 
function MapSearch() {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(center);
  const inputRef = useRef(null);
 
  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };
 
  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        setMarkerPosition({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
        setSelectedPlace(place);
      }
    }
  };
 
  return (
<LoadScript googleMapsApiKey="AIzaSyBZw1zL4TH3Kzt0eEbt5YQ1wLY0THVk4pc" libraries={["places"]}>
<div>
<Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
>
<input
            ref={inputRef}
            type="text"
            placeholder="Enter a location"
            style={{ width: '400px', height: '40px', marginBottom: '10px' }}
          />
</Autocomplete>
<GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={markerPosition}
          zoom={12}
>
          {selectedPlace && (
<Marker position={markerPosition} />
          )}
</GoogleMap>
</div>
</LoadScript>
  );
}
 
export default MapSearch;
