import Cookies from 'js-cookie'

export const getAccessToken = () => {
  return Cookies.get('authToken')
}
export const getEventId = () => {
  return Cookies.get('eventId')
}
export const getHostId = () => {
  return Cookies.get('hostId')
}
export const getStep = () => {
  return Cookies.get('step')
}

export const saveAccessToken = (value) => {
  Cookies.set('authToken', value)
}
export const saveEventId = (value) => {
  Cookies.set('eventId', value)
}
export const saveHostId = (value) => {
  Cookies.set('hostId', value)
}
export const saveSteps = (value) => {
  Cookies.set('step', value)
}

export const removeAccessToken = () => {
  Cookies.remove('authToken')
}
export const removeHostId = () => {
  Cookies.remove('hostId')
}
export const removeEventId = () => {
  Cookies.remove('eventId')
}
export const getRefreshToken = () => {
  return Cookies.get('refreshToken')
}

export const saveRefreshToken = (value) => {
  Cookies.set('refreshToken', value)
}

export const removeRefreshToken = (value) => {
  Cookies.remove('refreshToken')
}

export const addEmail = (mail) =>{
  Cookies.set('email', mail)
}

export const getEmail = () =>{
  return Cookies.get('email')
}

export const removeEmail = () =>{
  Cookies.remove('email')
}