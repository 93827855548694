import React from "react";
import { toast } from "react-toastify";

const Toaster = (message, type) => {
  switch (type) {
    case "success":
      return toast.success(
          <p className="mb-0">{message}</p>
      );
    case "error":
      return toast.error(
        <p className="mb-0">{message}</p>
      );
    case "warning":
      return toast.warning(
        <p className="mb-0">{message}</p>
      );
    default:
      return toast.warning(
        <p className="mb-0">{message}</p>
      );
  }
};
export default Toaster;