import React, { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup"; 
import { createHostEventById } from '../../../services/api/events-auth.api';
import Toaster from '../../../common/Toaster'

function BookingInfoTab({event,refreshDetails, inActive}) {
  const schema = yup.object().shape({
    numberOfGuests: yup.number().nullable().required("Number of guest is required").typeError('Number of guest is required'),
    gustPerCost: yup.number().nullable().when('postPay', {
      is: true,
      then: yup.number().required("Cost is required").typeError('Cost is required'),
      otherwise: yup.number().nullable(),
    }),
    postPay: yup.boolean()
  });
  const [payment] = useState([
    {label: 'Prepaid', value: true},
    {label: 'Pay at the event', value: false},
  ]) 
  const { register, setValue, handleSubmit, watch,formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      postPay: event?.postPay || false,
    },
  });
  const nextSubmit = (formData) => {
    const stepId = 2;
    updateEvent(formData,stepId);
  };
  const updateEvent = async(formData,stepId) => {
    const transformedData = transformFormValues(formData)
    try{
      if(event?.id){
        const res = await createHostEventById(transformedData,event?.id,stepId);
      }
        Toaster('Updated successfully', 'success');
        refreshDetails();
      }
    catch(error){
    }
  }
  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
      operationType: "Add",
      path: `/${key}`,
      op: "replace",
      value: formValues[key],
    }));
  }
  useEffect(() => {
    if (event) {
      const fields = ["numberOfGuests", "gustPerCost", "postPay"];
      fields.forEach((field) => {
        if(field !== null){
          setValue(field, event[field])
        }
      });
    }
  }, [event]);
  const postPay = watch("postPay");
  return (
    <form onSubmit={handleSubmit(nextSubmit)}>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="create-event-tab">
            <div className="row">
              <div className="col-lg-4">
                <div className="mb-3">
                  <label className="form-label input-label">Number Of Guest
                    <sup className="req">*</sup>
                  </label>
                  <input 
                    type="number" 
                    readOnly={inActive}
                    className="form-control"
                    {...register('numberOfGuests')}
                    placeholder="Enter number of guests"
                  />
                  {errors?.numberOfGuests && (
                    <span className="form-error">
                      {errors.numberOfGuests.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                  <div className="mb-3">
                      <label className="form-label input-label">Pay As You Go With</label>
                      <div className="radio-btn">
                          {
                              payment.map((item,index)=> (
                                  <div className="form-check" key={index}>
                                      <input className="form-check-input" 
                                          type="radio"
                                          name="postPay"
                                          disabled={inActive}
                                          {...register("postPay")} 
                                          checked={watch("postPay") === item.value} 
                                          onChange={() => setValue("postPay", item.value)}
                                          value={item.value}
                                          id={`flexRadio${index}`} />
                                      <label className="form-check-label"
                                          htmlFor={`flexRadio${index}`}>
                                          {item.label}
                                      </label>
                                  </div>
                              ))
                          }
                      </div>
                  </div>
              </div>
              {
                postPay ? (
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label input-label">Cost Per Guest (PLN)
                      <sup className="req">*</sup>
                    </label>
                    <input 
                      type="number" 
                      readOnly={inActive}
                      className="form-control"
                      {...register('gustPerCost')}
                      placeholder="Enter cost"
                    />
                    {errors?.gustPerCost && (
                      <span className="form-error">
                        {errors.gustPerCost.message}
                      </span>
                    )}
                  </div>
                </div>
                )
                : null
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="submit-btn next-btn justify-content-end">
                <div className="submit-btn next-btn text-end">
                  <div>
                    <button className="btn" type="submit" disabled={inActive}>Save</button>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </form>
  )
}

export default BookingInfoTab