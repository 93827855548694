import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import img1 from "../../assets/image/Razorpay-logo.svg";
import img2 from "../../assets/image/stripe-logo.png";
import { updateHostPlan} from '../../services/api/user-auth.api';
import { getHostId } from "../../util/auth";
import Toaster from '../../common/Toaster';
import '../host/host.scss'

function HostPayment(props) {
    const transformFormValues = (formValues) => {
        return Object.keys(formValues).map(key => ({
            operationType: "Add",
            path: `/${key}`,
            op: "replace",
            value: formValues[key] 
        }));
      };
    const handlePlan = async () => {
        const stepId = 7;
        const formData = {MembershipId: props.selectedPlan.id,ValidDays: props.selectedPlan.validDays};
        const transformedData = transformFormValues(formData);
        try {
          const hostId = await getHostId();
          await updateHostPlan(transformedData,stepId,hostId);
          Toaster('Your payment has been successfully processed. Thank you for doing business with us.','success')
          props.nextPage();
        } catch (error) {
            Toaster('Your payment has been failed! Try again later','error')
        }
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className='select-payment-popup host-wrapper'>
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="select-payment-label">You're almost there...</h5>
                </Modal.Header>
                <Modal.Body className='select-payment-body-sec'>
                    <div>
                        <p>We just need to confirm a few details so your host can accept your request.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                                <label className="form-label input-label">Your phone number</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text"> <i className="fe fe-phone"></i>
                                        <select aria-label="Default select example">
                                            <option>+91</option>
                                        </select>
                                    </span>
                                    <input type="number" className="form-control" placeholder="+89 561 300 00" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                                <label className="form-label input-label">Confirm the number of guests</label>
                                <select className="form-control form-select">
                                    <option></option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5>Payment details</h5>
                        <div className="click-payment">
                            <a href="#" className="click-payment-img active"><img src={img1} /></a>
                            <a href="#" className="click-payment-img"><img src={img2} /></a>
                        </div>
                        <div className="book-now-btn text-center my-3" onClick={handlePlan} >
                            <a type="button">Book Now</a>
                        </div>
                    </div>
                    <div className="payment-text-alert">
                        <div className="alert-box">
                            <p className="alert-title"><i className="fe fe-alert-circle"></i> Free cancellation up to 2 days before the event</p>
                        </div>
                        <div className="alert-box">
                            <p className="alert-title"><i className="fe fe-alert-circle"></i> Your booking will be instantly confirmed</p>
                            <p>We'll send you a confirmation email with a recap of your upcoming experience. Check your inbox!</p>
                            <p>If you have any dietary requirements or comments, you can send the host a message on the next page.</p>
                        </div>
                        <div className="alert-box">
                            <p className="alert-title"><i className="fe fe-alert-circle"></i> Trust & Safety</p>
                            <p>Meet@table is committed to caring for the health and well-being of our host and guest community. Learn more about the measures we’ve taken to ensure safety at Meet@table events.</p>                      
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default HostPayment