import { createSlice } from '@reduxjs/toolkit';

const userMailSlice = createSlice({
    name: 'userMail',
    initialState: {
      value: '', 
    },
    reducers: {
      setEmail: (state, action) => {
        state.value = action.payload;  
      },
    },
  });
  
  
  export const { setEmail } = userMailSlice.actions;
  
  export default userMailSlice.reducer;
  