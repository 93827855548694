import React from 'react'
import '../user.scss'
function Notifications() {
    return (
        <div>
            <div className="profile-info">
                <div className="row">
                    <div className="col-12">
                        <div className="bx-shadow">
                            <div className="sign-in-form contact-form becomehost-txt">
                                <table className="table notify-table">
                                    <thead>
                                        <tr>
                                            <th className="notify-table-header">Date</th>
                                            <th className="notify-table-header">Notifications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th className="user-date">10.08.2024</th>
                                            <td className="user-notify">We received your application and we can’t wait to review it!</td>
                                        </tr>
                                        <tr>
                                            <th className="user-date">12.08.2024</th>
                                            <td className="user-notify">We received your application and we can’t wait to review it!</td>
                                        </tr>
                                        <tr>
                                            <th className="user-date">15.08.2024</th>
                                            <td className="user-notify">We received your application and we can’t wait to review it!</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications