import React, { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import default_profile from '../../../assets/image/default_profile.jpg'

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  const handlingErrorImage = (e) =>{
    e.target.src = default_profile;
  }
  return (
    <div className="chat-bx-profile">
      {currentUser ? (
        <div className="cht-bx">
          <div className="ad-profile online">
            <img src={currentUser.photoURL} alt="Profile" onError={handlingErrorImage} />
          </div>
          <div>
            <p className="fw-bold">{currentUser.displayName}</p>
            <p className="cht">Online</p>
          </div>
        </div>
      ) : (
        <p>No user logged in</p> 
      )}
    </div>
  );
};

export default Navbar;
