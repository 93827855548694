import React, { useRef, useEffect } from "react";
import {getFirestore , getDocs, collection } from "firebase/firestore";
import GalleryChat from '../../galleryChat/GalleryChat'
import './message.scss';

const Message = ({ message, currentUser }) => {
  const ref = useRef();
  const [userName ,setUserName] = React.useState()
  const [profilePicture , setProfilePicture] = React.useState()

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  useEffect(() => {
    const db = getFirestore();
    const userChatsRef = collection(db, 'userChats');

    const fetchData = async () => {
      try {
        const snapshot = await getDocs(userChatsRef);
        const val = snapshot.docs.map(doc => doc.data());
        const filteredVal = val.filter((item) => Object.keys(item).length > 0); 
        const displayName = filteredVal.map((item) => item[Object.keys(item)[0]].userInfo).find((userInfo) => userInfo.uid === currentUser.uid)?.displayName;
        const profile = filteredVal.map((item) => item[Object.keys(item)[0]].userInfo).find((userInfo) => userInfo.uid !== currentUser.uid)?.photoURL;
        setProfilePicture(profile)
        setUserName(displayName)
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  const formatDate = (date) => {
    if (!date || !date.seconds) return "Unknown";
    return new Date(date.seconds * 1000).toLocaleTimeString();
  };

  return (
    <>
      <div className="chat-text">
        <div
          ref={ref}
          className={`mb-2 d-flex justify-content-end message ${
            message.senderId === currentUser.uid ? "owner" : ""
          }`}
        >
          <div className="messageInfo cht-body-txt text-right">
            <div className="cht-body-txt text-right">
            <div className="messageContent chat-list">
              <div className="chat-list-img">
              {/* {message.img && <img src={message.img} alt="" />} */}
              {message.img &&  <GalleryChat event={message}/>}
                <span>{message.text}</span>
              </div>
            </div>
            <p className="cht-time">{formatDate(message.date)}</p>
          </div>
          </div>
          <div className="ad-profile">
          <img
              src={
                message.senderId === currentUser.uid
                  ? currentUser.photoURL
                  : profilePicture || ""
              }
              alt=""
            />
          </div>

        </div>
      </div>

  
    </>
  );
};

export default Message;
