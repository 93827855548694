import React from "react";
import img1 from "../../../assets/image/tab-img1.png";
import img2 from "../../../assets/image/tab-img2.png";
import img3 from "../../../assets/image/tab-img3.png";
import img4 from "../../../assets/image/tab-img4.png";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import  '../myWishlists/wishlistEvent.scss'
function WishlistEvents() {
  return (
    <div className="row mt-4">
      <div className="col-md-3">
        <div className="position-relative">
          <div className="tab-img">
            <img src={img1} alt="image" />
          </div>
          <div className="d-flex trash-icon">
            <a type="button">
               <IoClose />
            </a>
            <a type="button" data-bs-toggle="modal" data-bs-target="#delete">
               <RiDeleteBinLine />
            </a>
          </div>

          <div className="evnt-ratng-sec d-flex justify-content-center align-items-center">
            <p className="online-evnt">5 Events</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="position-relative">
          <div className="tab-img">
            <img src={img2} alt="image" />
          </div>
          <div className="d-flex trash-icon">
            <a type="button">
               <IoClose />
            </a>
            <a type="button" data-bs-toggle="modal" data-bs-target="#delete">
               <RiDeleteBinLine />
            </a>
          </div>

          <div className="evnt-ratng-sec d-flex justify-content-center align-items-center">
            <p className="online-evnt">10 Events</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="position-relative">
          <div className="tab-img">
            <img src={img3} alt="image" />
          </div>
          <div className="d-flex trash-icon">
            <a type="button">
               <IoClose />
            </a>
            <a type="button" data-bs-toggle="modal" data-bs-target="#delete">
               <RiDeleteBinLine />
            </a>
          </div>

          <div className="evnt-ratng-sec d-flex justify-content-center align-items-center">
            <p className="online-evnt">12 Events</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="position-relative">
          <div className="tab-img">
            <img src={img4} alt="image" />
          </div>
          <div className="d-flex trash-icon">
            <a type="button">
               <IoClose />
            </a>
            <a type="button" data-bs-toggle="modal" data-bs-target="#delete">
               <RiDeleteBinLine />
            </a>
          </div>

          <div className="evnt-ratng-sec d-flex justify-content-center align-items-center">
            <p className="online-evnt">9 Events</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WishlistEvents;
