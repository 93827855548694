import React from 'react'
import img1 from "../../assets/image/becmehost8-img.png";
import { Link} from "react-router-dom";
import '../host/host.scss'

function HostStatus() {
  return (
    <div className="host-wrapper">
            <div className="become-host-sec becomehost-8">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6 col-md-12 my-auto">
                            <div className="becomehost-img">
                                <img src={img1} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="becomehost-txt">
                                <div>
                                    <div>
                                        <h2>Congratulations! Your application to become an meet@table host has been
                                            sent!</h2>
                                        <p>Our Community Team will review your application and keep you updated via
                                            email.</p>
                                    </div>
                                    <h4>What's next?</h4>
                                    <div className="bcm-list position-relative">
                                        <div>
                                            <ul>
                                                <li>
                                                    <div className="form-check">
                                                        <input className="form-check-input checkbox-round" type="checkbox"
                                                            value="" id="flexCheckDefault" checked />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                           Plan Selected
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input className="form-check-input checkbox-round" type="checkbox"
                                                            value="" id="flexCheckDefault" checked />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                          Payment Completed
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input className="form-check-input checkbox-round" type="checkbox"
                                                            value="" id="flexCheckDefault" />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Wait for Approval
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check ">
                                                        <input className="form-check-input checkbox-round" type="checkbox"
                                                            value="" id="flexCheckDefault" />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Completed
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="st-btn text-center">
                                        <Link to="/"><button type='button' >Go to my profile</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default HostStatus