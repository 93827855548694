import React, { useEffect, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import EditMenuModal from "../editMenuModal/EditMenuModal";
import DeleteEventModel from "./modal/DeleteModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup"; 
import Toaster from '../../../common/Toaster';
import CreateMenuModal from "./modal/CreateMenuModal";
import { useParams } from 'react-router-dom';
import { getMenuEvent,getAllFacilities, createEventFacility, getFacilityEvent,createHostEventById } from "../../../services/api/events-auth.api";

function FoodInfoTab({event,refreshDetails,inActive}) {
  const [modalAdd, setModalAdd] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [menuList,setMenuList] = useState([]);
  const [menuDeleteId,setMenuDeleteId] = useState(null);
  const [facilityList,setFacilityList] = useState([]);
  const [selectedFacility,setSelectedFacility] = useState([]);
  const {eventId} = useParams();

  const schema = yup.object().shape({
      menuReason: yup.string().nullable().when('menuVisibility', {
          is: false,
          then: yup.string().required("Reason is required").typeError('Reason is required'),
          otherwise: yup.string().nullable(),
        }),
      menuVisibility: yup.boolean()
    });
  
  const { register, handleSubmit, setValue, watch,control, clearErrors, trigger, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
          menuVisibility: event?.menuVisibility || false,
          menuReason: null
      },
  });
  const [optionsList] = useState([
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ]) 
  const nextSubmit = async(formData) => {
      const stepId = 5;
      if(menuVisibility && menuList.length === 0){
          Toaster("Please add atleast one menu item for this event", "warning");
          return;
      }else{
          try{
              if(selectedFacility.length > 0){
                  await createEventFacility(selectedFacility,event?.id);
              }
              await updateEvent(formData,stepId);
          }catch (error){
          }finally{
            Toaster('Updated successfully', 'success');
          }
      }
    }
  const updateEvent = async(formData,stepId) => {
    const transformedData = transformFormValues(formData)
    try{
        if(event?.id){
        const res = await createHostEventById(transformedData,event?.id,stepId);
        refreshDetails();
        }
        }
    catch(error){
    }
  }
  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
        operationType: "Add",
        path: `/${key}`,
        op: "replace",
        value: formValues[key],
  }));
}
  const getMenus = async() =>{
      try{
          const menus = await getMenuEvent(event?.id);
          setMenuList(menus);
      }catch (error){
      }
  }
  const deleteMenus = async(id) =>{
      await setModalDelete(true);
      await setMenuDeleteId(id)
  }
  const closePopup = () =>{
      setModalAdd(false)
      setModalEdit(false)
      setModalDelete(false)
      getMenus();
  }
  const getFacilities = async()=>{
      try {
        const facility = await getFacilityEvent(event?.id);
        if(facility){
         const res =  facility.map((item)=> item.id)
          setSelectedFacility(res);
        }
      }catch (error){
      }
  }
    const handleCheckboxChange = (e) => {
      const { value, checked } = e.target;
      const numericValue = Number(value); 
      if (checked) {
          setSelectedFacility((prev) => [...prev, numericValue]);
      } else {
          setSelectedFacility((prev) => prev.filter((id) => id !== numericValue));
      }
  };
  const getData = async() => {
      try{
          const res = await getAllFacilities();
          setFacilityList(res);
          await getFacilities();
      }catch (error){
      }
  }
  useEffect(()=>{
      getMenus();
      getData();
      if(event?.menuReason !== null && event?.menuVisibility !== null){
        setValue('menuReason',event?.menuReason)
        setValue('menuVisibility',event?.menuVisibility)
      }
  },[event])
  const menuVisibility = watch("menuVisibility");
  return (
    <div>
        <form onSubmit={handleSubmit(nextSubmit)}>
            <div className="row create-event-tab">
                    <div className="col-md-12 mx-auto">
                        <div className="check-facility mb-3">
                            <p className="text-dark event-facility-title">Event Preferences</p>
                            <div className="row g-3">
                                {
                                    facilityList.length > 0 
                                    ? facilityList.map((item)=> (
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input className="form-check-input" 
                                                    type="checkbox" 
                                                    disabled={inActive}
                                                    value={item.id}
                                                    checked={selectedFacility.includes(item.id)}
                                                    onChange={handleCheckboxChange}
                                                    id="flexCheckChecked" />
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                {item.name}
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                    : (
                                        <p className='not-found'>Facilities not found</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                            <div className="mb-3">
                                <label className="form-label input-label">Would you like to display the menu options ?</label>
                                <div className="radio-btn">
                                    {
                                        optionsList.map((item,index)=> (
                                            <div className="form-check">
                                                <input className="form-check-input" 
                                                    type="radio"
                                                    disabled={inActive}
                                                    name="menuVisibility"
                                                    {...register("menuVisibility")} 
                                                    checked={watch("menuVisibility") === item.value} 
                                                    onChange={() => setValue("menuVisibility", item.value)}
                                                    value={item.value}
                                                    id={`flexRadio${index}`} />
                                                <label className="form-check-label"
                                                    htmlFor={`flexRadio${index}`}>
                                                    {item.label}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                    </div>
                    {
                        menuVisibility ? (
                            <div className={`col-md-12 mx-auto ${inActive ? "disabled-state" : ""}`}>
                                <div>
                                <p className="text-dark event-facility-title">Event Menus</p>
                                    <div className="row">
                                    <div className="col-12">
                                            <div className="text-end">
                                                <button className="btn add-btn" onClick={() => setModalAdd(true)}>Add</button>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                        {
                                            menuList.length > 0 
                                            ? (
                                                <div className="table-item">
                                                    <table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Item Name</th>
                                                                <th scope="col">Item Description</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                menuList.length > 0 
                                                                && menuList.map((item)=> (
                                                                    <tr key={item.id}>
                                                                        <td className="item-name">
                                                                            <p>{item.itemName}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p>{item.itemDescription}</p>
                                                                        </td>
                                                                        <td>
                                                                            <span className="trash-icon-ch">
                                                                                <button type="button" onClick={() =>deleteMenus(item.id)}>
                                                                                        <RiDeleteBinLine/>
                                                                                </button>
                                                                            </span>
                                                                        </td>
                                                                </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                            :
                                            (
                                                <p className='not-found'>Food menus not found</p>
                                            )
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        : (
                            <div className="col-lg-12">
                            <div className="mb-3">
                            <label className="form-label input-label">
                                Please Provide A Reason For Not Displaying The Menu Options:
                                <sup className="req">*</sup>
                            </label>
                            <textarea
                                className="form-control textarea-ht"
                                {...register('menuReason')}
                                placeholder='Enter here....'
                                id="floatingTextarea2"
                                readOnly={inActive}
                            ></textarea>
                            {errors?.menuReason && (
                                <span className="form-error">
                                {errors.menuReason.message}
                                </span>
                            )}
                            </div>
                        </div>
                        )
                    }
            </div>
            <div className="row">
                <div className="col-md-12 mx-auto">
                    <div className="submit-btn next-btn justify-content-end">
                    <div className="submit-btn next-btn text-end">
                        <div>
                        <button className="btn" type="submit" onClick={nextSubmit} disabled={inActive}>Save</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </form>
        <CreateMenuModal show={modalAdd} onHide={closePopup} eventId={event?.id}/>
        <EditMenuModal show={modalEdit} onHide={closePopup} />
        <DeleteEventModel show={modalDelete} onHide={()=> setModalDelete(false)} menuId={menuDeleteId} onConfirm={closePopup}/>
    </div>
  )
}

export default FoodInfoTab