import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { login, getHostDetails } from "../../services/api/user-auth.api";
import { Link, useNavigate } from "react-router-dom";
import { saveAccessToken, saveHostId, addEmail } from "../../util/auth";
import { googleLogout, useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import img1 from "../../assets/image/sign-in-bg.png";
import img2 from "../../assets/image/logo.png";
import img3 from "../../assets/image/facebook1.png";
import img4 from "../../assets/image/google.png";
import img5 from "../../assets/image/instagram-icon.png";
import logo from "../../assets/image/logo-dark.svg";
import { PiEyeClosedLight } from "react-icons/pi";
import { PiEyeThin } from "react-icons/pi";
import Loading from "../../common/Loading";
import Toaster from "../../common/Toaster";
import { AiOutlineHome } from "react-icons/ai";
import "../login/login.scss";
import { useDispatch } from "react-redux";
import {setEmail} from '../../redux/slice/userMail'

function Login() {
  const navigate = useNavigate();
  const [showPswd, setShowPswd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const loginSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const data = await login(formData);
      await addEmail(data?.email)
      dispatch(setEmail(data?.email));
      reset();
      await saveAccessToken(data.token);
      await getHost();
      Toaster("Login successfully", "success");
      navigate("/");
    } catch (error) {
      Toaster(error?.response?.data, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const getHost = async () => {
    try {
      const hostData = await getHostDetails();
      if (hostData) {
        await saveHostId(hostData.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="login-wrapper">
        <form onSubmit={handleSubmit(loginSubmit)}>
          <div className="container-fluid sign-in">
            <div className="sign-in-bg">
              <img src={img1} alt="image" />
            </div>
            <div className="container h-100">
              <div className="row sign-in-row h-100">
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-lg-9 me-auto">
                      <div>
                        <div>
                          <div className="logo-image text-center">
                          <img src={logo} alt="image" />
                          </div>
                          <p className="sign-in-logo-txt text-center">
                            Login using social media to get quick access
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-8 mx-auto">
                            <div className="fb">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img3} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with facebook
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img4} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with google
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img5} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with instagram
                                  </p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="sign-in-form">
                        <div className="my-3 form-title d-flex align-items-center justify-content-between">
                          <p className="fw-bold  login-title">Login</p>
                          <Link to="/">
                            <AiOutlineHome className="home-icon" />
                          </Link>
                        </div>
                        <div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Your email or username{" "}
                              <sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email / username"
                              {...register("email")}
                            />
                            {errors?.email && (
                              <span className="form-error">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Password <sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={showPswd ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                {...register("password")}
                              />
                              {errors?.password && (
                                <span className="form-error">
                                  {errors.password.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setShowPswd(!showPswd)}
                              >
                                {!showPswd && <PiEyeClosedLight />}
                                {showPswd && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between my-3 align-items-center flex-wrap">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Remember Me
                            </label>
                          </div>
                          <div className="forgot-txt">
                            <p className="mb-0">
                              <Link
                                to="/forgot-password"
                                className="forgot-link"
                              >
                                Forgot your password?
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div>
                          <button className="btn sign-in-btn" type="submit">
                            Login
                          </button>
                        </div>
                        <div className="d-flex click-signup align-items-center justify-content-center">
                          <p className="mb-0">Don't have an account?</p>
                          <p className="mb-0">
                            <Link to="/register">Sign up now!</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default Login;
