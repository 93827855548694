import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../events.scss";
import { IoClose } from "react-icons/io5";

function MenuModal(props) {
  return (
    <div>
      {/* <WishlistEvents/> */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className="events-wrapper-main view-menu-modal"
      >
        <Modal.Body>{}
          <div className="view-menu-modal-body">
 	          <div className="d-flex justify-content-between view-menu">
              <h5 className="modal-title">Menu</h5>
               <button onClick={props.onHide} className="close-icon"><IoClose/></button>
            </div>
            <div className="view-menu-txt">
              {
                props.menus.length > 0 
                ? props.menus.map((item) => (
                  <div className="mb-2" key={item.id}>
                    <h4>{item.itemName}</h4>
                    <p>{item.itemDescription}</p>
                  </div>
                ))
                : (
                  <p className="text-center">No menus found</p>
                )
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MenuModal;
