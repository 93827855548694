import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import { db } from "../../../firebase";
import default_profile from '../../../assets/image/default_profile.jpg'

const Chats = () => {
  const [chats, setChats] = useState({});

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  const [active, setActive] = useState();

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        console.log(doc.data())
        setChats(doc?.data() || {});
      });

      return () => {
        unsub();
      };
    };

    currentUser?.uid && getChats();
  }, [currentUser?.uid]);

  const handleSelect = (u) => {
    setActive(u);
    dispatch({ type: "CHANGE_USER", payload: u });
  };
const handlingErrorImage = (e) =>{
  e.target.src = default_profile;
}
  return (
    <div className="chat-bx-profile">
      {Object.entries(chats)
        ?.sort((a, b) => b[1].date - a[1].date)
        .map((chat) => (
          <div 
            key={chat[0]}
            className={`cht-bx ${active && active?.uid === chat[1].userInfo.uid ? 'active' : ''}`}
            onClick={() => handleSelect(chat[1].userInfo)}
          >
            <div
              className="ad-profile online"
            >
              <img src={chat[1].userInfo.photoURL} alt="" onError={handlingErrorImage} />
            </div>
            <div className="userChatInfo">
              <p>{chat[1].userInfo.displayName}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Chats;
