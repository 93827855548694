import authRoute from "./router/auth/auth";
import hostRoutes from "./router/hostRoute";
import infoRoutes from "./router/infoRoute";
import eventRoutes from "./router/eventRoute";
import userRoutes from "./router/userRoute";

const allRoute = [
    ...authRoute,
    ...infoRoutes,
    ...hostRoutes,
    ...userRoutes,
    ...eventRoutes
]
export default allRoute;