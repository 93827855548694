import axios from 'axios';
import { getAccessToken } from "./util/auth";

const axiosInstance = axios.create({
  baseURL: 'https://meet-at-table-api.scopethinkers.com', 
  headers: {
    'Content-Type': 'application/json',
  }
});

//request
axiosInstance.interceptors.request.use(config => {
  const token = getAccessToken();
  if (token) {
    config.headers['authorization'] = 'Bearer ' + token;
  }
  if (config.data instanceof FormData) {
    delete config.headers['Content-Type'];
  }
  return config
}, error => {
  return Promise.reject(error);
});

// const setupAxiosInterceptors = (navigate) => {
//   // Setting a variable to track the interceptor
//   const interceptor = axiosInstance.interceptors.response.use(
//     (response) => response, // Return the response if it's successful
//     (error) => {
//       if (error.response && error.response.status === 401) {
//         // Redirect to home page if the response status is 401
//         navigate('/');
//       }
//       return Promise.reject(error); // Return the error for further handling
//     }
//   );

//   // Return a function to eject the interceptor
//   return () => {
//     axiosInstance.interceptors.response.eject(interceptor);
//   };
// };

// response
// axiosInstance.interceptors.response.use(response => {
//   return response;
// }, error => {
//   if (error.response && error.response.status === 500) {
//       // Redirect to login page on 500 Internal Server Error
//       window.location.href = '/login'; // Adjust the path to your login route
//   }
//   return Promise.reject(error);
// });
export  {axiosInstance};
