import React from "react";
import { registerForm } from "../../services/api/user-auth.api";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/image/sign-in-bg.png";
import img3 from "../../assets/image/facebook1.png";
import img4 from "../../assets/image/google.png";
import { useState } from "react";
import { PiEyeClosedLight } from "react-icons/pi";
import { PiEyeThin } from "react-icons/pi";
import Loading from "../../common/Loading";
import Toaster from "../../common/Toaster";
import img5 from "../../assets/image/instagram-icon.png";
import { AiOutlineHome } from "react-icons/ai";
import "../register/register.scss";
import { doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db } from "../../firebase";
import profile from "../../assets/image/profile.png";
import profile1 from "../../assets/image/profile1.jpg";
import profile2 from "../../assets/image/profile2.jpg";
import profile3 from "../../assets/image/profile3.png";
import logo from "../../assets/image/logo-dark.svg";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

function Register() {
  const navigate = useNavigate();
  const [showPswd, setShowPswd] = useState(false);
  const [confirmPswd, setConfirmPswd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const profiles = [{ image: profile }, { image: profile1 },{ image: profile2 },{ image: profile3 }];
  let schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    username: yup
      .string()
      .matches(/^[a-zA-Z0-9\s.,!?_-]*$/, "Special characters are not allowed")
      .required("User name is required"),
    email: yup.string().required("Email is required").email("Invalid email"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
      confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("password"), null], "Password must match"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const regSubmit = async (formData) => {
    setIsLoading(true);
    const displayName = formData?.username;
    const {email} = formData;
    const updatedProfile = Math.floor(Math.random() * profiles.length)
    try {
      const data = await registerForm(formData);
      const res = await createUserWithEmailAndPassword(auth, email, email);
      const response = await fetch(profiles[updatedProfile].image);
      if (!response.ok) {
          throw new Error('Failed to fetch image');
      }
      const blob = await response.blob();

      const storage = getStorage();
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);
      await uploadBytes(storageRef, blob);

      const downloadURL = await getDownloadURL(storageRef);

      await updateProfile(res.user, {
          displayName,
          photoURL: downloadURL,
      });

      await setDoc(doc(db, "users", res.user?.uid), {
          uid: res.user?.uid,
          displayName,
          email,
          photoURL: downloadURL
      });

      await setDoc(doc(db, "userChats", res.user?.uid), {});

      reset();
      setIsLoading(false);
      Toaster('Register successfully', 'success');
      navigate('/login');
    } catch (error) {
      setIsLoading(false);
      Toaster("Registeration failed", "error");
      console.error("Error during register:", error);
    }
  };
  return (
    <>
      <div className="register-wrapper">
        <form onSubmit={handleSubmit(regSubmit)}>
          <div className="container-fluid sign-in register">
            <div className="sign-in-bg">
              <img src={img1} alt="image" />
            </div>
            <div className="container h-100">
              <div className="row sign-in-row h-100">
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-lg-9 me-auto">
                      <div>
                        <div>
                          <div className="logo-image text-center">
                          <img src={logo} alt="image" />
                          </div>
                          <p className="sign-in-logo-txt text-center">
                            Login using social media to get quick access
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-8 mx-auto">
                            <div className="fb">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img3} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with facebook
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img4} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with google
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="gg">
                              <a href="#">
                                <div className="d-flex align-items-center">
                                  <div className="fb-gg-img">
                                    <img src={img5} />
                                  </div>
                                  <p className="mb-0 ps-2">
                                    Sign in with instagram
                                  </p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-auto">
                  <div className="row">
                    <div className="col-md-12 mx-auto">
                      <div className="sign-in-form">
                        <div className="my-3 form-title d-flex align-items-center justify-content-between">
                          <p className="fw-bold  login-title">
                            Create your account
                          </p>
                          <Link to="/">
                            <AiOutlineHome className="home-icon" />
                          </Link>
                        </div>
                        <div className="forgot-txt-msg">
                          <p>
                            Start booking your favorite experiences and get free
                            Meet@table credit for refering friends
                          </p>
                        </div>
                        <div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              First name <sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First name"
                              {...register("firstName")}
                            />
                            {errors?.firstName && (
                              <span className="form-error">
                                {errors.firstName.message}
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Last name <sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last name"
                              {...register("lastName")}
                            />
                            {errors?.lastName && (
                              <span className="form-error">
                                {errors.lastName.message}
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              User name <sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="User name"
                              {...register("username")}
                            />
                            {errors?.username && (
                              <span className="form-error">
                                {errors.username.message}
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Email address <sup className="req">*</sup>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email address"
                              {...register("email")}
                            />
                            {errors?.email && (
                              <span className="form-error">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Password <sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={showPswd ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                {...register("password")}
                              />
                              {errors?.password && (
                                <span className="form-error">
                                  {errors.password.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setShowPswd(!showPswd)}
                              >
                                {!showPswd && <PiEyeClosedLight />}
                                {showPswd && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Confirm Password <sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={confirmPswd ? "text" : "password"}
                                className="form-control"
                                placeholder="Confirm Password"
                                {...register("confirmPassword")}
                              />
                              {errors?.confirmPassword && (
                                <span className="form-error">
                                  {errors.confirmPassword.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setConfirmPswd(!confirmPswd)}
                              >
                                {!confirmPswd && <PiEyeClosedLight />}
                                {confirmPswd && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="my-3 checkbox-terms">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Yes! I want to receive exclusive Meet@table
                              offers, travel inspo and al the food in my inbox
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              By signing up, I agree to{" "}
                              <span>
                                <a href="#">Terms & Conditions</a>
                              </span>
                              ,{" "}
                              <span>
                                <a href="#">Trust</a>
                              </span>{" "}
                              and{" "}
                              <span>
                                <a href="#">Privacy Policy</a>
                              </span>
                              .
                            </label>
                          </div>
                        </div>
                        <div>
                          <button className="btn sign-in-btn" type="submit">
                            Let's go
                          </button>
                        </div>
                        <div className="d-flex click-signup align-items-center justify-content-center">
                          <p className="mb-0">Already have an account?</p>
                          <p className="mb-0">
                            <Link to="/login">Sign in now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default Register;
