import React, { useEffect, useState, useRef } from "react";
import img2 from "../../assets/image/location.png";
import img3 from "../../assets/image/calender.png";
import img4 from "../../assets/image/guest.png";
import img5 from "../../assets/image/breadcrumb-image.png";
import img9 from "../../assets/image/filter.png";
import noPhoto from "../../assets/image/noPhoto.png";
import blank from "../../assets/image/blank-image.webp";
import "../handPickedEvents/handpicked-events.scss";
import { transformImageUrl } from "../../util/dataTransform";
import { getFilterEvents, getSearchEvents } from "../../services/api/events-auth.api";
import ApplyFilterModal from "./modal/ApplyFilterModal";
import { MdOutlineStar } from "react-icons/md";
import Select from "react-select";
import { EVENTS } from "../../const/route-url.const";
import { useNavigate } from "react-router-dom";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Loading from "../../common/Loading";
import { useSelector } from 'react-redux';
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { RiCloseLine } from "react-icons/ri";
import Pagination from '../../common/Pagination'
import { format } from 'date-fns';
import { FiSearch } from "react-icons/fi";
const mapContainerStyle = {
  height: "760px",
  width: "100%",
};

const center = {
  lat: 37.7749, 
  lng: -122.4194,
};

function HandPickedEvents() {
  const [activeButtons, setActiveButtons] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);

  const searchData = useSelector((search)=>search?.searchHandle?.value);
  const searchEvent = useSelector((search)=>search?.searchHandle?.eventValues);

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker);
  };

  const handleCloseClick = () => {
    setActiveMarker(null);
  };

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(center);
  const inputRef = useRef(null);

useEffect(()=>{
  if(searchEvent?.length > 0){
    setSelectedPlace(searchEvent[0])
  }
},[searchEvent])


  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        // setMarkerPosition({
        //   lat: place.geometry.location.lat(),
        //   lng: place.geometry.location.lng()
        // });
        const fullAddress = place.formatted_address.replace(/, /g, ',');
        setSelectedPlace(place);
        setSearchEvents((prev) => ({
          ...prev,
        Address:fullAddress
        }));
      }
    }
  };
  const locations = [
    { lat: 37.7749, lng: -122.4194 }, // San Francisco
    { lat: 34.0522, lng: -118.2437 }, // Los Angeles
    { lat: 36.1699, lng: -115.1398 }, // Las Vegas
    // Add more locations as needed
  ];
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]); 
  const [eventCategory, setEventCategory] = useState([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [guests, setGuests] = useState(1);

  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const navigate = useNavigate();
  const [sortList] = useState([
    { label: "Relevance", value: null },
    { label: "Price (lowest first)", value: "lowestfirst" },
    { label: "Price (highest first)", value: "highestfirst" },
  ]);
  const [searchParams, setSearchParams] = useState({
    Sort: null,
    PageId: 1,
    PageSize: 10,
  });
  useEffect(() => {
    if (searchEvent[0]?.FromDate && searchEvent[0]?.ToDate) {
      const fromDate = new Date(searchEvent[0]?.FromDate);
      const toDate = new Date(searchEvent[0]?.ToDate);
      setValues([fromDate, toDate]);
      setSearchEvents(searchEvent[0]?.NoOfGuest);
    }
    else{
      setValues([new DateObject(), new DateObject()]);
    }
  }, [searchEvent]);
  const incrementGuests = () => {
    setGuests(prevGuests => prevGuests + 1);
  };
  const decrementGuests = () => {
    setGuests(prevGuests => (prevGuests > 1 ? prevGuests - 1 : 1));
  };

  const [searchEvents,setSearchEvents]=useState({
    Address:null,
    PageId: 1,
    PageSize: 10,
  })
  const handleSelect = async (event) => {
    setIsLoading(true);
    setSearchParams((prev) => ({
      ...prev,
      Sort: event.value,
    }));
    setIsLoading(false);
  };
  
  const selectDate = (dates) => {
    const [start, end] = dates; 
    const formattedStart = start ? format(new Date(start), 'MM/dd/yyyy') : 'Not selected';
    const formattedEnd = end ? format(new Date(end), 'MM/dd/yyyy') : 'Not selected';
    setSearchEvents((prev) => ({
      ...prev,
    FromDate:formattedStart,
    ToDate:formattedEnd,
    }));
  }
  useEffect(() => {
    if (searchEvent?.length > 0) {
      setGuests(searchEvent[0].NoOfGuest); 
    }
  },[searchEvent])
   
  const handleSearch = async () => {
    if(searchEvents.Address===null) return
    const payload = { ...searchEvents };
    if (guests >= 1) {
      setSearchEvents((prev) => ({
        ...prev,
        NoOfGuest:guests
      })); 
    } else {
      delete payload.NoOfGuest; 
    }
    setIsLoading(true);
    try {
      const data = await getSearchEvents(payload);
      setFilteredEvents(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

 
  const groupBy = (data, key) => {
    return data.reduce((acc, item) => {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
  };

  const getAllEvents = async () => {
    setIsLoading(true);
    try {
      if (searchData.length === 0) {
        console.log('hi')
        const data = await getFilterEvents(searchParams);
        setAllEvents(data);
        setFilteredEvents(data);
        setIsLoading(false);

      } else {
        console.log(searchData,'hi')
        const data = await getSearchEvents(searchData[0]);
        setFilteredEvents(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setIsLoading(false);
    }
  };

  
  const filterEventsByCategory = (selectedCategories) => {
    const categoryIds = selectedCategories.map(category => category.id); 
    const filtered = allEvents.filter(event => 
      categoryIds.includes(parseInt(event.eventCategoryId)) 
    );
    setFilteredEvents(filtered); 
  };
  
  const selectEventCategory = (categoryId, index) => {
    if (activeButtons.includes(index)) {
      setActiveButtons(activeButtons.filter(i => i !== index));
      setData(prevData => prevData.filter(id => id !== categoryId));
    } else {
      setActiveButtons([...activeButtons, index]);
      setData(prevData => [...prevData, categoryId]);
    }
  };

  const reset = async (reset) => {
    setActiveButtons([]); 
    setData([]);  
    setSearchParams({
      Sort: null, 
      PageId: 1,  
      PageSize: 10,
      EventCategory: null,
    });
    try {
      const data = await getFilterEvents({
        Sort: null, 
        PageId: 1,  
        PageSize: 10,
        EventCategory: null,
      });
      const filteredEventData = groupBy(data, "eventCategory");
      if (Object.keys(filteredEventData).length) {
        const updatedCategoryNamesCount = Object.keys(filteredEventData).map(
          (category) => {
            const firstEventInCategory = filteredEventData[category][0];
            return {
              id: firstEventInCategory.eventCategoryId,
              name: firstEventInCategory.eventCategory,
              count: filteredEventData[category].length,  
            };
          }
        );
        setEventCategory(updatedCategoryNamesCount);
      }
    } catch (error) {
      console.error('Error fetching events after reset:', error);
    }
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFilterEvents(searchParams);
        const filteredEventData = groupBy(data, "eventCategory");

        if (Object.keys(filteredEventData).length) {
          const categoryNamesCount = Object.keys(filteredEventData).map(
            (category) => {
              const firstEventInCategory = filteredEventData[category][0];
              return {
                id: firstEventInCategory.eventCategoryId,
                name: firstEventInCategory.eventCategory,
                count: filteredEventData[category].length,
              };
            }
          );
          setEventCategory(categoryNamesCount);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);
  
  const errorImageHandler = (e) =>{
    e.target.src = noPhoto;
  }
  useEffect(() => {
    if (data.length > 0) {
      filterEventsByCategory(data);
    } else {
      setFilteredEvents(allEvents);
    }
  }, [data]);

  const eventImageSetup = (arrDoc) => {
    if (arrDoc[0]?.eventDocumentPath) {
      const docUrl = transformImageUrl(arrDoc[0]?.eventDocumentPath);
      return docUrl;
    }
  };
  const redirectToEvent = (event) => {
    navigate(`/${EVENTS}/${event.id}`);
  };

  const getIconFacility = (item) => {
    const svgImage = item?.toLowerCase().replace(/\s+/g, "");
    let imagePath;
    try {
      imagePath = require(`../../assets/image/${svgImage}.png`);
    } catch (error) {}
    return imagePath;
  };

  useEffect(() => {
    getAllEvents();
  }, [searchParams]);
  
  useEffect(() => {
    setSearchEvents((prev) => ({
      ...prev,
      NoOfGuest: guests,
    }));
  }, [guests]);

  
  return (
    <>
      <div className="handpicked-main-block">
        <div className="position-relative">
          <div className="breadcrumb-image hand-pick-breadcrumb">
            <img src={img5} alt="image" />
            <div className="breadcrumb-overlay"></div>
          </div>

          <div className="banner-text-sec">
            <div className="container">
              <div className="row">
                <div className=" col-md-12">
                  <div className="breadcrumb-header text-center">
                    <h4>
                      Hand-Picked Selections <br />
                      Of Experiences!
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="handpic-brdcrumb-search">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="ad-sec">
                    <div className="row">
                      <div className="col ad-col my-auto">
                        <div className="d-flex align-items-center">
                          <div className="ad-icon">
                            <img src={img2} alt="image" />
                          </div>
                          <div className="autocomplete-search">
                            <LoadScript
                              googleMapsApiKey="AIzaSyBZw1zL4TH3Kzt0eEbt5YQ1wLY0THVk4pc"
                              libraries={["places"]}
                            >
                              <div>
                                <Autocomplete
                                  onLoad={onLoad}
                                  onPlaceChanged={onPlaceChanged}
                                >
                                  <input
                                    ref={inputRef}
                                    type="text"
                                    placeholder="Enter a location"
                                    style={{
                                      boxSizing: `border-box`,
                                      border: `1px solid transparent`,
                                      width: `100%`,
                                      height: `32px`,
                                      padding: `0px`,
                                      borderRadius: `3px`,
                                      boxShadow: `none`,
                                      fontSize: `14px`,
                                      outline: `none`,
                                      textOverflow: `ellipses`,
                                    }}
                                  />
                                </Autocomplete>
                                {selectedPlace && (
                                  <div
                                    style={{
                                      marginTop: "0px",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <h2>
                                     
                                      Selected Place: {selectedPlace && selectedPlace.name ? selectedPlace.name : ''}
                                    </h2>
                                    <p>
                                      Address: {selectedPlace && selectedPlace.formatted_address ? selectedPlace.formatted_address : selectedPlace.Address}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </LoadScript>
                          </div>
                        </div>
                      </div>
                      <div className="col ad-col my-auto">
                        <div className="d-flex align-items-center">
                          <div className="ad-icon">
                            <img src={img3} alt="image" />
                          </div>
                          <div className="rmdp-container-main">
                            <DatePicker
                            value={values}
                              selected={startDate}
                              onChange={selectDate} 
                              startDate={startDate}
                              endDate={endDate}
                              range 
                              inline 
                              minDate={new Date()} 
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col my-auto">
                        <div className="d-flex align-items-center guest-count-block-main">
                          <div className="ad-icon">
                            <img src={img4} alt="image" />
                          </div>
                          <div className='guest-count-block'>
                      <ul>
                        <li>
                          <div id="guestCount" className="d-flex align-items-center">
                            <span className='me-3'>{guests}</span>
                            <button className='btn me-2' onClick={decrementGuests} disabled={guests === 1}>
                              -
                            </button>
                            <button className='btn btn-minus' onClick={incrementGuests}>+</button>
                          </div>
                          <span className="hgt">Guest</span>
                        </li>
                      </ul>
                          </div>    
                        </div>
                      </div>
                      <div className="w-auto my-auto">
                        <div className="text-end d-flex align-items-center justify-content-end">
                          <button
                            className="btn"
                            onClick={() => handleSearch()}
                          >
                            <FiSearch/>
                          </button>
                          <a
                            className="filter btn"
                            onClick={() => setModalShow(true)}
                          >
                            <div className="filter-img">
                              <img src={img9} alt="image" />
                            </div>
                          </a>
                        </div>
                      </div>
 {/* <Search onSearch={getSearch} /> Pass the handleSearch function */}
                     
                    </div>
                  </div>
                </div>
                 <div className="col-12">
                        <div className="ad-sec-handpick">
                          {activeButtons.length > 0 ? (
                            <span className="reset-btn">
                              {activeButtons.length > 0 ? (
                                <RiCloseLine
                                  className="close-icon"
                                  onClick={() => reset(reset)}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                          {eventCategory?.map((event, index) => (
                            <>
                              <div
                                key={event.id}
                                className={
                                  activeButtons.includes(index)
                                    ? "hand-pick-breadcrumb-img-sec active"
                                    : "hand-pick-breadcrumb-img-sec"
                                }
                                onClick={() =>
                                  selectEventCategory(event, index)
                                }
                              >
                                <div className="hand-pick-breadcrumb-img">
                                  <img
                                    src={getIconFacility(event.name)}
                                    alt="image"
                                  />
                                </div>
                                <div>
                                  <p>{event?.name}</p>
                                </div>
                                <div>
                                  <span>{event?.count}</span>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="en position-relative handpicked-bg">
          <div className="handpicked-bg-overlay"></div>
          <div className="handpicked-bg-content">
          <div className="shape-1"></div>
            <div className="px-3">
              <div className="row mt-3 gy-3">
                <div className="col-lg-5 col-md-12">
                  <div className="see-menu-sec-btn hand-pick-loc h-100">
                    <LoadScript
                      googleMapsApiKey="AIzaSyBZw1zL4TH3Kzt0eEbt5YQ1wLY0THVk4pc"
                      libraries={["places"]}
                    >
                      <div>
                        <GoogleMap
                          mapContainerStyle={mapContainerStyle}
                          center={markerPosition}
                          zoom={5}
                        >
                          {locations.map((location, index) => (
                            <MarkerF
                              key={index}
                              position={{
                                lat: location.lat,
                                lng: location.lng,
                              }}
                              icon={{
                                url: "https://meet-at-table-admin.scopethinkers.com/HTML/image/map-marker.png",
                              }}
                              onClick={() => handleMarkerClick(location)}
                            /> 
                          ))}
                          <div></div>
                          {activeMarker && (
                            <InfoWindowF
                              position={{
                                lat: activeMarker.lat,
                                lng: activeMarker.lng,
                              }}
                              onCloseClick={handleCloseClick} // Close the InfoWindow
                            >
                              <div className="infowindow-block">
                                <h3>Marker Info</h3>
                                <p>Latitude: {activeMarker.lat}</p>
                                <p>Longitude: {activeMarker.lng}</p>
                                <p>Additional information can go here.</p>
                              </div>
                            </InfoWindowF>
                          )}
                        </GoogleMap>
                      </div>
                    </LoadScript>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="menu-tab hand-pick-menu-tab h-100">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <span>{filteredEvents.length} Experiences</span>
                      </li>

                      <div className="d-flex ms-auto align-items-center me-2 tab-loc-sec">
                        <div className="mb-0">
                          <div className="selectbox-block">
                            <Select
                              placeholder="Sort By"
                              options={sortList}
                              onChange={handleSelect}
                            />
                          </div>
                        </div>
                      </div>
                    </ul>
                    <div className="tab-content p-0 ">
                      <div id="all" className="tab-pane active">
                        <div className="row g-3 tab-content-ht">
                          {filteredEvents.length > 0 ? (
                            filteredEvents.map((event) => (
                              <>
                                <div
                                  className="col-lg-4 col-md-6 cursor-pointer"
                                  onClick={() => redirectToEvent(event)}
                                  key={event.id}
                                >
                                  <div className="card h-100">
                                    <div className="position-relative event-slider">
                                      <div className="tab-img p-0 shadow-none">
                                      {event.eventDocuments.length > 0 ? (
                                          <img
                                            src={eventImageSetup(
                                              event?.eventDocuments
                                            )}
                                            className="card-img-top"
                                            alt="images"
                                            onError={errorImageHandler}
                                          />
                                        ) : (
                                          <img
                                            src={noPhoto}
                                            className="card-img-top"
                                            alt="noPhoto"
                                          />
                                        )}
                                      </div>
                                      <div className="evnt-ratng-sec d-flex justify-content-between align-items-center">
                                        <p className="evnt-ratng">
                                          <MdOutlineStar /> 5(90)
                                        </p>
                                      </div>
                                    </div>
                                    <div className="card-body p-0">
                                      <div className="d-flex align-items-center carousel-img-body">
                                        <div className="carousel-small">
                                        {event.eventDocuments.length > 0 ? (
                                          <img
                                            src={eventImageSetup(
                                              event?.eventDocuments
                                            )}
                                            className="card-img-top"
                                            alt="images"
                                            onError={errorImageHandler}
                                          />
                                        ) : (
                                          <img
                                            src={noPhoto}
                                            className="card-img-top"
                                            alt="noPhoto"
                                          />
                                        )}
                                        </div>
                                        <div className="tab-evnt-des">
                                          <p className="mb-0">
                                            {event.eventDescription}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="tab-evnt-dlr d-flex justify-content-between flex-wrap">
                                        <p className="mb-0">
                                          {event.eventCategory}
                                        </p>
                                        <p className="mb-0">
                                          ${event.gustPerCost}
                                          <span className="hgt hgt-small">
                                            /Guest
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            <div className="card h-100 shadow border-0">
                              <div className="position-relative">
                                <div className="tab-img shadow-none border-0">
                                  <img
                                    src={blank}
                                    className="card-img-top"
                                    alt="images"
                                  />
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="blank-text"></div>
                                <div className="blank-text-2"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
            </div>
   
          </div>
        </section>
        {/* <Pagination
        className="pagination-bar"
        currentPage={searchParams.currentPage} // Use dynamic state for currentPage
        totalCount={filteredEvents.length}
        pageSize={searchParams.PageSize} // Ensure you're using the correct state key (case-sensitive)
        onPageChange={handlePageChange} // Use the function defined above
        /> */}
        <ApplyFilterModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default HandPickedEvents;
