import React, { useEffect, useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import seeMenu from "../../assets/image/see-menu.png";
import TheHost from "./theHost/TheHost";
import AllExperiences from "./allExperiences/AllExperiences";
import MenuModal from "./modal/MenuModal"
import { useParams } from "react-router-dom";
import { getEventById, getMenuEvent, getFacilityEvent } from '../../services/api/events-auth.api';
import Select from 'react-select';
import '../events/events.scss';
import Datetime from 'react-datetime';
import Loading from '../../common/Loading';
import RelatedEvents from "./relatedEvents/RelatedEvents";
import Reviews from "./reviews/Reviews";
import Gallery from "./gallery/Gallery";
import Map from '../map/Map';
import { LoadScript } from '@react-google-maps/api';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import guestIcon from '../../assets/image/guest-icon.png';
import guestDateIcon from '../../assets/image/guest-date-icon.png';
import money from '../../assets/image/money.png';
import userInfo from '../../assets/image/user-info1.png'

function Events() {
  const [modalShow, setModalShow] = React.useState(false);
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);
  const [guestCounts, setGuestCounts] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [concatAddress, setConcatAddress] = useState('');
  const getFacilities = async () => {
    try {
      const facility = await getFacilityEvent(id);
      if (facility) {
        setFacilityData(facility);
      }
    } catch (error) {
    }
  }
  const getMenu = async () => {
    try {
      const menu = await getMenuEvent(id);
      if (menu) {
        setMenuData(menu)
      }
    } catch (error) {
    }
  }
  const getIconFacility = (item) => {
    const svgImage = item?.toLowerCase().replace(/\s+/g, '');
    let imagePath;
    try {
      imagePath = require(`../../assets/image/facilityImages/${svgImage}.png`);
    } catch (error) {
    }
    return imagePath;
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await getEventById(id);
        if (res) {
          setEventData(res);
          console.log(res)
          const fullAddress = `${res?.primaryAddress}, ${res?.secondaryAddress}, ${res?.city},${res?.country}`;
          console.log(typeof fullAddress, '1')
          console.log(fullAddress, '2')
          setConcatAddress(fullAddress);
          const guestOptions = Array.from({ length: res.numberOfGuests }, (_, i) => i + 1);
          const data = guestOptions.map(option => ({
            label: option,
            value: option
          }));
          setGuestCounts(data);
          await getMenu();
          await getFacilities();
        }
      } catch (error) {

      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [])
  return (
    <>
      <div className="events-wrapper-main events-bg">
        <div className="events-bg-overlay"></div>
        <div className="events-bg-content">
          {/* section */}
          <div className="bkng-con">
           <div className="container-fluid">
           <div className="row">
              <div className="col-xxl-7 col-xl-9 col-lg-12 col-md-12 mx-auto">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-5 col-12 my-auto">
                    <div className="nav-btn no-guest-icon-nav">
                     
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1" className="no-guest-icon">
                        <img src={userInfo} alt="image" />
                        </InputGroup.Text>
                        <Select placeholder="Select Number of Guests" className="guest-no-select border-0"
                                options={guestCounts}
                            />
                      </InputGroup>                 
                    </div>

                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 my-auto">
                    <div className="nav-btn guest-date-select">
                     
                     <div className="date-guest-icon">
                     <img src={guestDateIcon} alt="image" />
                     </div>
                      
                      <p>09/10/2024</p>
                      
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4 col-8 my-auto">
                    <div className="bkng-con-guest-dlr guest-date-select">
                    
                      <div className="date-guest-icon">
                      <img src={money} alt="image" />
                     </div>
                      
                     <p className="mb-0">
                        ${eventData?.gustPerCost} <span>/Guest</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-12 col-4 my-auto">
                    <div className="btn bkng-con-btn">
                      <button className="btn">Book</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           </div>
          </div>
          {/* <!--gallery--> */}
          <div className="container mt-3">
            <Gallery event={eventData} />
          </div>
          {/* // <!--main section--> */}
          <main>
            <div className="p-s1">
              {/* <!--section--> */}
              <section>
                <div className="evnt-con position-relative overflow-hidden">
                  <div className="shape-1"></div>
                  <div className="container">
                    <div className="row">
                      <div className="evnt-title">
                        <h1>
                          {eventData?.eventTitle}
                        </h1>
                        <p>
                          Hosted by <b className="text-dark">{eventData?.firstName} {eventData?.lastName}</b> in{" "}
                          <b className="text-dark">{eventData?.city}</b>
                        </p>
                      </div>
                    </div>
                    {/* <!--section--> */}
                    <div className="row g-3 mb-3">
                      <div className="col-md-12">
                        <div className="menu-tab evnt-tab">
                          {/* <!--tab--> */}
                          <Tabs
                            defaultActiveKey="All Experiences"
                            id="controlled-tab-example"
                            className="nav nav-tabs justify-content-center"
                          >
                            {/* <!--tab content--> */}
                            <Tab
                              eventKey="All Experiences"
                              className="nav-item "
                              title="All Experiences"
                            >
                              <AllExperiences event={eventData} />
                            </Tab>
                            <Tab
                              eventKey="The Host"
                              className="nav-item "
                              title="The Host"
                            >
                              <TheHost event={eventData} />
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <!--section--> */}
              <section>
                <div className="position-relative bg2-img-con overflow-hidden">
                  <div className="shape-3"></div>
                  <div className="container">
                    <div className="row gy-3 mb-5">
                      <div className="col-lg-7">
                        <div className="row g-3">
                          <div className="col-12">
                            <div className="see-menu-sec">
                              <div className="row g-3">
                                <div className="col-md-2">
                                  <div className="see-menu-img">
                                    <img src={seeMenu} alt="image" />
                                  </div>
                                </div>
                                <div className="col-md-5 mt-auto">
                                  <div className="see-menu-p-txt">
                                    <h4>See the menu</h4>
                                    {
                                      eventData?.menuVisibility ? (
                                        <p className="mb-0">
                                          The menu is a list of food and beverages
                                          offered to customers and the prices.
                                        </p>
                                      )
                                        : (
                                          <p className="mb-0">
                                            {eventData?.menuReason}
                                          </p>
                                        )
                                    }
                                  </div>
                                </div>
                                <div className="col-md-4 mt-auto">
                                  <div className="see-menu-sec-btn d-flex justify-content-end mt-2">
                                    <button
                                      className="btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() => setModalShow(true)}
                                    >
                                      Click to view the menu
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="see-menu-sec">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <div>
                                    <h4>The Place</h4>
                                    <p>
                                      The full address will be provided once the
                                      booking is confirmed
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="see-menu-sec-btn">
                                    {/* {
                                          concatAddress !== '' && (
                                              <Map zoom={14} address={concatAddress}/>
                                          )
                                        } */}
                                    <iframe
                                      src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120823.34398460794!2d72.825833!3d19.076090!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b64f57e04d09%3A0x0!2s${eventData?.city}!5e0!3m2!1sen!2sin!4v1722588346659!5m2!1sen!2sin`}
                                      width="100%"
                                      height="600"
                                      style={{ border: "0" }}
                                      allowFullScreen=""
                                      loading="lazy"
                                      referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <RelatedEvents eventDetails={eventData} />
                      </div>
                      <div className="col-md-12">
                        <div className="useful-info see-menu-sec ">
                          <div>
                            <h4>Useful Information</h4>
                          </div>
                          <div className="row g-3">
                            {
                              facilityData.length > 0
                                ? facilityData.map((item, index) => (
                                  <div className="col-lg-2 col-md-6" key={item.id}>
                                    <div className="evnt-feat d-flex">
                                      <div className="evnt-feat-img">
                                        <img src={getIconFacility(item.name)} alt="image" />
                                      </div>
                                      <div>
                                        <p className="mb-0">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                                : (
                                  <p className="not-found">No facility found</p>
                                )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shape-2"></div>
                </div>
              </section>

              {/* <!--section--> */}
              <section>
                <Reviews />
              </section>
            </div>
          </main>
        </div>
        {/* <!--popup--> */}
        <MenuModal show={modalShow} onHide={() => setModalShow(false)} menus={menuData} />
      </div>
      {
        isLoading && <Loading />
      }
    </>
  );
}

export default Events;
