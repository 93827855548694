import { createSlice } from '@reduxjs/toolkit';

const searchData = createSlice({
    name: 'search',
    initialState: {
      value:[], 
      eventValues : []
    },
    reducers: {
      sendEvents: (state, action) => {
        state.value.push(action.payload)
      },
      sendEventValues : (state , action) =>{
        state.eventValues.push(action.payload)
      }
    },
  });
  
  
  export const { sendEvents } = searchData.actions;
  export const {sendEventValues} = searchData.actions;
  
  export default searchData.reducer;
  