import { createSlice } from '@reduxjs/toolkit';


const createMailSlice = createSlice({
    'name' : 'hostEmail',
    initialState: {
        hostEmail : ''
    },
    reducers :{
        hostEmailGet : (state , action ) =>{
            state.hostEmail = action.payload
        }
    }
})

export const {hostEmailGet} = createMailSlice.actions;
export default createMailSlice.reducer;