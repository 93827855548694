import React, { useEffect, useState } from 'react';
import { getBookingEvents,approveUserByHost,rejectUserByHost } from '../../../services/api/events-auth.api';
import { BsCheckSquare } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import Toaster from '../../../common/Toaster'
import Loading from '../../../common/Loading';

function BookingDetailsTab({ event }) {
    const [tableData, setTableData] = useState([]);
    const [isApprove,setIsApprove] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    useEffect(() => {
        if (event) {
            fetchData();
        }
    }, [event]);
    const fetchData = async () => {
        try{
            const res = await getBookingEvents(event?.id);
            setTableData(res);
        }catch (err){
        } 
    };
    const handleAccept = async(bookingId) => {
        setIsLoading(true);
        try{
            const response = await approveUserByHost(bookingId);
            Toaster(`Booking with ID ${bookingId} has been accepted!`, 'success');
            fetchData();
        }catch (err){

        } finally{
            setIsLoading(false);
        }
    };
    const handleReject = async(bookingId) => {
        setIsLoading(true);
        try{
            const response = await rejectUserByHost(bookingId);
            Toaster(`Booking with ID ${bookingId} has been rejected!`, 'success');
            fetchData();
        }catch (err){

        } finally{
            setIsLoading(false);
        }
    };

    return (
        <div className="col-md-12 mx-auto">
            <div className="create-event-tab edit-booking-tab">
                <div className="row">
                    <div className="col-12">
                        <div className="table-item">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Email Id</th>
                                        <th scope="col">Number of seats</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Transaction Id</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData?.length > 0 ? (
                                        tableData.map((item, index) => (
                                            <tr key={index}>
                                                <td className="item-name">
                                                    <p>{item.firstName}</p>
                                                </td>
                                                <td>
                                                    <p>{item.lastName}</p>
                                                </td>
                                                <td>
                                                    <p>{item.email}</p>
                                                </td>
                                                <td>
                                                    <p>{item.noOfBookingSheets}</p>
                                                </td>
                                                <td>
                                                    <p>{item.amount ? item.amount : '-'}</p>
                                                </td>
                                                <td>
                                                    <p>{item.transactionId}</p>
                                                </td>
                                                <td>
                                                    {
                                                      item.status === 1 && (
                                                        <span className='app-text'>Approved</span>
                                                      )
                                                    }
                                                    {
                                                      item.status === 2 && (
                                                        <span className='app-text'>Rejected</span>
                                                      )
                                                    }
                                                    {
                                                      item.status === 0 && (
                                                        <>
                                                        <div onClick={()=> handleAccept(item.id)}>
                                                        <BsCheckSquare className='check-icon'/>
                                                        </div>
                                                         <div onClick={()=> handleReject(item.id)}>
                                                         <BsCheckSquare className='check-icon'/>
                                                         </div>
                                                        </>
                                                      )
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">
                                                <p>Not found</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading && <Loading />
            }
        </div>
        
    );
}

export default BookingDetailsTab;
