import React, { useEffect,useState } from "react";
import '../howItWorks/HowItWorks.scss';
import banner from "../../../assets/image/new-banner-video.mp4";
import img3 from "../../../assets/image/login-pattern2.png";
import { getHowItsWork } from "../../../services/api/site-info.api";
import Accordion from "react-bootstrap/Accordion";

function HowItWorks() {
    const [content,setContent] = useState('');
    const [guestContent,setGuestContent]= useState('');
    const [hostContent,setHostContent]= useState('');

    const getData = async()=>{
      try{
        const res = await getHowItsWork();
        setContent(res);
      }catch (error){}
    }
    useEffect(()=> {
      getData();
    },[])
    useEffect(()=>{
        const parser = new DOMParser();
        const doc = parser.parseFromString(content.text, 'text/html');
        const element = doc.querySelector('.guest'); 
        const element1 = doc.querySelector('.host'); 
        if (element) {
            setGuestContent(element.outerHTML);
        }
        if (element1) {
            setHostContent(element1.outerHTML);
        }
    },[content])
  return (
    <>
    <div className="home-howitworks-wrapper">
        <div class="en-center text-center"><h3>How It Works</h3></div>
        <div dangerouslySetInnerHTML={{ __html:  content?.subTitle}} className="text-center"></div>
        <div className="breadcrumb-image d-none">
                {/* <img src={img2} alt="image"/> */}
                <video autoPlay muted loop>
                <source src={banner} type="video/mp4" />
            </video>
            <div className="breadcrumb-overlay"></div>
            <div className="breadcrumb-title">
            <div className="breadcrumb-header">
                <h4>How It Works1</h4>
            </div>
            <div>
            
            </div>
            </div>
        </div>
        <div className="pattern-row d-none">
            <div className="col-12">
            <div className="pattern-2">
                <img src={img3} alt="image" />
            </div>
            </div>
        </div>

        <div className="position-relative overflow-hidden p-s">
            <div className="shape-1"></div>
            <div className="container">
            <div className="row mt-4">
            <Accordion className="howitworks-accordion accordion">
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>As a Guest</Accordion.Header>
                    <Accordion.Body className="accordion-body">
                        <div className="col-12 guest-show">
                            <div dangerouslySetInnerHTML={{ __html:  guestContent}} className="mb-0"></div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header>As a Host</Accordion.Header>
                    <Accordion.Body className="accordion-body">
                        <div className="col-12 host-show">
                             <div dangerouslySetInnerHTML={{ __html:  hostContent}} className="mb-0"></div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </div>
            </div>
            <div className="shapefaq"></div>
        </div>
    </div>
    </>
  );
}

export default HowItWorks;
