import React, { useEffect, useState } from 'react'
import { getAccessToken } from "../../util/auth";
import { getCountries, getLanguage } from '../../services/api/site-info.api'
import BecomeHost from './BecomeHost';
import CreateHost from './CreateHost';
import Loading from '../../common/Loading';
import '../host/host.scss'

function HostInfo({ nextHook, prevHook, InputEventHook }) {
 const [token, setToken] = useState('');
 const [lang, setLang] = useState([]);
 const [isLoading,setIsLoading] = useState(false);
 const [countryList, setCountryList] = useState([]);
 const prev = () => {
    prevHook();
 };
 const next = () => {
    nextHook();
 };
  const getLangDatas = async() =>{
    try{
        const data = await getLanguage();
        const langList = data.map(option => ({
            label: option.languageName,
            value: option.id
        }));
        setLang(langList)
    }catch (err){}
  }
  const getCountryData = async () => {
    try{
        const res = await getCountries();
        const list = res.map(option => ({
            label: option.name,
            value: option.name
        }));
        setCountryList(list);
    }catch (err){}
  }
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
        const token_val = getAccessToken();
        if(token_val){
            setToken(token_val);
        }
        try {
            await Promise.all([getLangDatas(), getCountryData()]);
        } catch (err) {
            console.error("Error fetching data:", err);
        } finally {
          setIsLoading(false); 
        }
    };
    fetchData();
  }, []);


 
  return (
    <>
     {isLoading ? (
           <Loading />
        ) : (
            token 
            ?  <BecomeHost langList={lang} countryData={countryList} prevHookType={prev} nextHookType={next}/>
            :  <CreateHost langList={lang} countryData={countryList} prevHookType={prev} nextHookType={next}/>
        )}
    </>
  )
}

export default HostInfo