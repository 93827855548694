import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from '../slice/tokenSlice';
import userMailSlice from '../slice/userMail'
import searchSlice from '../slice/searchSlice';
import createMailReducer  from '../slice/hostMailSlice'

export const store = configureStore({
  reducer: {
    tokenRefresh: tokenReducer,
    userEmail: userMailSlice,
    searchHandle : searchSlice,
    HostMailReducer: createMailReducer
  },
});
 