import React, { useEffect, useState } from 'react'
import img1 from "../../assets/image/becomehost-pattern.png";
import img2 from "../../assets/image/badge.png";
import img3 from "../../assets/image/silver.png";
import img4 from "../../assets/image/tick.png";
import { FaArrowLeft } from "react-icons/fa";
import {getAllPlans} from '../../services/api/user-auth.api';
import HostPayment from './HostPayment';
import '../host/host.scss'

function HostPlan({ nextHook, prevHook }) {
    const [plans,setPlans] = useState([]);
    const [planDetail,setPlanDetail] = useState(null);
    const [modalShow, setModalShow] = React.useState(false);  
    const next = () => {
        nextHook();
    };
    const prev = () => {
        prevHook();
    };
    const handlePlan = async (plan) => {
          setPlanDetail(plan);
          setModalShow(true);
    }
    useEffect(()=>{
        const getPlans = async () =>{
            try {
                const res = await getAllPlans();
                setPlans(res);
            }catch (error){
            }
        }
        getPlans();
    },[])
    return (
        <div className="host-wrapper">
            <div className="become-host-sec">
                <div className="container">
                    <div className="row gy-5 mt-3">
                        {
                            plans.length > 0 && plans.map((plan) => (
                                <div className="col-lg-4 col-md-6" key={plan.id}>
                                    <div className="becomehost-txt">
                                        <div className="badge">
                                            <div className="badge-img">
                                                <img src={img2} alt="image" />
                                                <div className="badge-dlr">
                                                    <p>${plan.price}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column h-100">
                                            <div className="plan-image">
                                                <img src={img3} alt="image" className="mw-100 mh-100" />
                                            </div>
                                            <div className="d-flex flex-column h-100">
                                                <h3>{plan.name}</h3>
                                                <div className="bcm-list">
                                                    <ul>
                                                        <li>
                                                            <div>
                                                                <div className="tick-img">
                                                                    <img src={img4} alt="image" />
                                                                </div>
                                                                <div className="chk-txt">
                                                                    <p>Valid for one year</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <div className="tick-img">
                                                                    <img src={img4} alt="image" />
                                                                </div>
                                                                <div className="chk-txt">
                                                                    <p>{plan.maxNumberOfGuest} Members allowed for events</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="submit-btn next-btn text-center">
                                                    <div><button className="btn" type="submit" onClick={() => handlePlan(plan)}>Select</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="row h-100 align-items-center">
                        <div className="col-md-12">
                            <div className="submit-btn next-btn">
                                <div className='next-btn1' onClick={prev}><FaArrowLeft /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HostPayment show={modalShow} onHide={() => setModalShow(false)} selectedPlan={planDetail} nextPage={next}/>
        </div>
    )
}

export default HostPlan