import React, { useState } from "react";
import { transformImageUrl } from "../../../util/dataTransform";
import noPhoto from "../../../assets/image/noPhoto.png";
import { EVENTS } from "../../../const/route-url.const";
import img3 from '../../../assets/image/tom.png';
import DeleteEventModel from "../deleteEventModel/DeleteEventModel";
import { useNavigate } from "react-router-dom";
import { MdOutlineStar } from 'react-icons/md';
import { SlLocationPin } from 'react-icons/sl';
import { Link } from "react-router-dom";
import {EDIT_EVENT} from "../../../const/route-url.const";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaEye } from "react-icons/fa";

function EventContent({eventList,refreshHook,tab}) {
  const navigate = useNavigate();
    const [eventId,setEventId] = useState('');
    const [modalShow, setModalShow] = React.useState(false);

    const eventImageSetup = (arrDoc) => {
    if (arrDoc[0]?.eventDocumentPath) {
        const docUrl = transformImageUrl(arrDoc[0]?.eventDocumentPath);
        return docUrl;
    }else {
        return noPhoto;
    }
    };
    const redirectToEvent = (event) => {
        navigate(`/${EVENTS}/${event.id}`);
    };
    const openPopup = async(eventId) =>{
        await setEventId(eventId);
        await setModalShow(true);
    }
    const closePopup = async() =>{
        await setModalShow(false);
        refreshHook();
    }
  return (
    <div>
         <div className="tab-content p-0">
            <div id="all" className="tab-pane active">
               <div className="row event-main-block">
                    {eventList.length > 0 ? 
                    eventList.map((event) => (
                    <div
                        className="col-lg-3 col-md-6 p-3"
                        key={event.id}
                        onClick={() => redirectToEvent(event)}
                    >
                        <div className="card h-100">
                        <div className="position-relative">
                            <div className="tab-img p-0 shadow-none">
                            <img
                                src={eventImageSetup(event.eventDocuments)}
                                className="card-img-top"
                                alt="image"
                            />
                            </div>
                            {
                                tab === 'pending' && (
                                    <div className="evnt-bk-mrk" >
                                        <Link to={`${EDIT_EVENT}/${event.id}`} onClick={(e) => e.stopPropagation()}>
                                            <FiEdit />
                                        </Link>
                                        <a  onClick={(e) => {
                                                    e.stopPropagation();
                                                    openPopup(event.id);
                                                }}>
                                        <RiDeleteBinLine />
                                        </a>
                                    </div>
                                )
                            }
                            {
                                tab === 'approved' && (
                                    <div className="evnt-bk-mrk" >
                                        <Link to={{
                                            pathname:`${EDIT_EVENT}/${event.id}`,
                                            search: `?upcoming=true` 
                                            }} onClick={(e) => e.stopPropagation()}>
                                            <FaEye />
                                        </Link>
                                    </div>
                                )
                            }
                            <div className="evnt-ratng-sec d-flex justify-content-between align-items-center">
                            <p className="evnt-ratng">
                                <MdOutlineStar /> 5(90)
                            </p>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="tom-txt">
                            <p className="d-flex align-items-center">
                                <span className="hgt hgt-small">By</span>{' '}
                                <span className="tom-img">
                                <img src={img3} alt="image" />
                                </span>{' '}
                                {event?.firstName} {event?.lastName}
                            </p>
                            </div>
                            <div className="tab-evnt-dlr d-flex justify-content-between flex-wrap">
                            <p>{event.eventCategory}</p>
                            <p>
                                ${event.gustPerCost}
                                <span className="hgt hgt-small">/Guest</span>
                            </p>
                            </div>
                            <div className="d-flex align-itemt-start mb-3">
                            <div className="location-icon">
                                <SlLocationPin />
                            </div>
                            <div className="tab-evnt-des">
                                <span>{event.city}</span>
                                <p className="text-truncate mb-0">{event.eventTitle}</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    ))
                    : (
                        <p className="not-found mt-3">Upcoming Events are not available</p>
                    )
                }
                    </div>
            </div>
        </div>
        <DeleteEventModel show={modalShow} onHide={() => setModalShow(false)} eventDelete={eventId} onConfirm={closePopup}/>
    </div>
    
  )
}

export default EventContent