import React,{useRef,useState,useEffect} from 'react'
import { useNavigate} from "react-router-dom";
import img3 from "../../../assets/image/photo.png";
import { v4 as uuidv4 } from 'uuid';
import { FaRegTrashCan } from "react-icons/fa6";
import Toaster from '../../../common/Toaster';
import { uploadEventDoc,deleteEventDoc,getEventById } from "../../../services/api/events-auth.api";
import {transformImageUrl} from '../../../util/dataTransform';

function PhotosTab({event,inActive}) {
  const docUploadRef = useRef(null);
  const [eventDocs, setEventDocs] = useState([]);
  const [storeDocs, setStoreDocs] = useState([]);

  const navigate = useNavigate();
  const handleDocUpload = (event) => {
    event.preventDefault();
    docUploadRef.current.click();
   }
  const uploadDocDisplay = async () => {
    const files = Array.from(docUploadRef.current.files); 
    const catchedFiles = files.map((file) =>({
        file : file,
        id: uuidv4()
    }));
    const cachedURLs = catchedFiles.map((data) => ({
          url: URL.createObjectURL(data.file),
          type: 'preview',
          id: data.id
      })); 
      await setEventDocs(prevState => [...prevState, ...cachedURLs]);
      await setStoreDocs(prevState => [...prevState, ...catchedFiles]);
    };
    const handleUpload = async () => {
      if(eventDocs.length === 0 ){
          Toaster('Please upload at least one document before proceeding.', 'warning');
          return ;
      }
      if(storeDocs.length > 0){
          const formData = new FormData();
          storeDocs.forEach((data) => {
              formData.append(`eventDocuments`, data.file); 
          });
          try {
              await uploadEventDoc(formData,event?.id);
              Toaster('Photos uploaded successfully', 'success');
          } catch (error) {
              Toaster('Creation failed', 'error');
          }
      }
  };
  const removeDocument = async (doc) => {
    if (doc.type === 'preview') {
        setEventDocs(prev => prev.filter((item) =>  item.id !== doc.id));
        setStoreDocs(prev => prev.filter((item) => item.id !== doc.id));
    } else if (doc.type === 'existing') {
        deleteDocs(doc.id);
    }
  };

  const deleteDocs = async (id) => {
    try {
        await deleteEventDoc(id);
        setEventDocs(prev => prev.filter((item) =>  item.id !== id));
        Toaster('Photo deleted successfully', 'success');
    }catch (error) {
        Toaster('failed', 'error');
    }

  }
  const getEventDetails = async() =>{
    try{
      const details = await getEventById(event?.id);
      if(details){
        const docUrl = details.eventDocuments.map((file)=> ({
          url : transformImageUrl(file.eventDocumentPath),
          type: 'existing',
          id: file.eventDocumentId
       }));
       setEventDocs(docUrl);
      }
    }catch(error){

    }
  }
  useEffect(()=>{
    getEventDetails();
  },[event])
  return (
    <>
    <div className="row">
      <div className="col-md-12 mx-auto">
          <div className={`create-event-tab check-facility ${inActive ? "disabled-state" : ""}`}>
              <p className="text-dark event-facility-title">Event documents</p>
              <button className="drag-image text-center" onClick={handleDocUpload} type='button'>
                  <div className="blk-img">
                      <img src={img3} alt="image"/>
                  </div>
                  <div>
                      <p>
                          click to upload or drag and drop a new file
                      </p>
                  </div>
              </button>
              <input  type="file" 
                      ref={docUploadRef}
                      onChange={uploadDocDisplay}
                      hidden
                      multiple 
                      id="event-photo-input" 
                      name="event_documents"
                      accept="image/png, image/jpeg"
                  />
              <div className="row g-3 mt-3">
              {eventDocs.map((doc, index) => (
                    <div className="col-md-3 position-relative" key={index}>
                        <div className="uploaded-img">
                            <img src={doc.url} alt={`Uploaded ${index}`} />
                        </div>
                        <div className='trash-icon' onClick={()=>removeDocument(doc)}>
                        <FaRegTrashCan />
                        </div>
                    </div>
                ))}
              </div>
          </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 mx-auto">
          <div className="submit-btn next-btn justify-content-end">
          <div className="submit-btn next-btn text-end">
              <div>
              <button className="btn" type="submit" onClick={handleUpload} disabled={inActive}>Save</button>
              </div>
          </div>
          </div>
      </div>
    </div>
    </>
    
  )
}

export default PhotosTab